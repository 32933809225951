import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Dialog } from '@material-ui/core'
import Layout from '../components/Layout'
import AddIcon from '@material-ui/icons/Add'
import { getAllPolicies } from '../store/actions/policyActions'
import PolicyList from '../components/policy/PolicyList'
import AddPolicy from '../components/policy/AddPolicy'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(3),
    },
}))

const Policies = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const policyState = useSelector((state) => state.policy)
    const { policies } = policyState

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        dispatch(getAllPolicies())
    }, [dispatch])
    return (
        <Layout>
            <Container>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Policy
                    </Button>
                </Box>
                {policies &&
                    policies.map((policy) => (
                        <PolicyList policy={policy} key={policy.id} />
                    ))}
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddPolicy handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default Policies
