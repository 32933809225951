import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Grid,
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DELETE_CONFIRM, ITEM_HEIGHT } from '../../constant/default'
import EditQnAItem from './EditQnAItem'
import {
    deleteQnAItem,
    getOneQnAItem,
} from '../../store/actions/qnaItemActions'

const useStyles = makeStyles((theme) => ({
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
}))

const QnAItemList = ({ qnaItem, qnaCategoryId }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = (id) => {
        dispatch(getOneQnAItem(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography variant="subtitle2" gutterBottom>
                        {qnaItem.question}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                    >
                        {qnaItem.answer}
                    </Typography>
                    <Divider className={classes.divider} />
                </Grid>

                <Grid
                    item
                    xs={2}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <>
                        <IconButton
                            aria-label="more"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={() => handleEditOpen(qnaItem.id)}
                            >
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    if (window.confirm(DELETE_CONFIRM)) {
                                        dispatch(deleteQnAItem(qnaItem.id))
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteForeverIcon
                                        className={classes.deleteIcon}
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Delete"
                                    className={classes.deleteIcon}
                                />
                            </MenuItem>
                        </Menu>
                    </>
                </Grid>
            </Grid>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditQnAItem
                    handleDialogClose={handleEditClose}
                    qnaCategoryId={qnaCategoryId}
                />
            </Dialog>
        </>
    )
}

export default QnAItemList
