import { Grid } from '@material-ui/core'
import ProductColorList from '../productColors/ProductColorList'
import StyleColorList from '../productColors/StyleColorList'

const ProductColors = ({ product }) => {
    const porductColorList = product?.productcolors?.map(
        (color) => color.stylecolor.id
    )
    return (
        <>
            <Grid>
                <div className="color-container">
                    {product?.style?.stylecolors &&
                        product?.style?.stylecolors.map((color) => (
                            <StyleColorList
                                color={color}
                                porductColorList={porductColorList}
                                product={product}
                                key={color.id}
                            />
                        ))}
                </div>
            </Grid>
            {product.productcolors &&
                product.productcolors.length > 0 &&
                product.productcolors.map((productColor) => (
                    <ProductColorList
                        key={productColor.id}
                        productColor={productColor}
                        productId={product.id}
                    />
                ))}
        </>
    )
}

export default ProductColors
