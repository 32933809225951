import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import SizeForm from './SizeForm'

const EditSize = ({ handleDialogClose }) => {
    const sizeState = useSelector((state) => state.size)
    const { size } = sizeState

    return size ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Size</DialogTitle>
            <SizeForm
                handleDialogClose={handleDialogClose}
                initialValues={size}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditSize
