import {
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Typography,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { displayAmount } from '../common/common'
import Layout from '../components/Layout'
import { PROMO_CATEGORY } from '../constant/default'
import history from '../history'
import { getOnePromoCode } from '../store/actions/promoCodeActions'
import moment from 'moment'
import OrderTable from '../components/promoCode/OrderTable'

const PromoCode = ({ match }) => {
    const dispatch = useDispatch()
    const promoCodeState = useSelector((state) => state.promoCode)
    const { promoCode } = promoCodeState
    const id = match.params.id

    useEffect(() => {
        dispatch(getOnePromoCode(id))
    }, [dispatch, id])
    return (
        <Layout>
            {promoCode ? (
                <>
                    <Container>
                        <Grid>
                            <Button
                                variant="contained"
                                onClick={() => history.goBack()}
                                style={{ margin: '20px 0' }}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Card style={{ margin: '20px 0' }}>
                            <CardHeader title={promoCode.promoName} />
                            <CardContent>
                                <Grid container>
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            style={{ whiteSpace: 'pre-line' }}
                                        >
                                            {promoCode.promoDescription}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card style={{ margin: '20px 0' }}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            Promo Code
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {promoCode.promoCode}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            Promo Category
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {
                                                PROMO_CATEGORY[
                                                    promoCode.promoCategory
                                                ]
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            Promo Type
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {displayAmount(
                                                promoCode.promoType,
                                                promoCode.typeMount
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            Start Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {moment(promoCode.startDate).format(
                                                'lll'
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            End Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {moment(promoCode.endDate).format(
                                                'lll'
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {promoCode?.orders?.length > 0 && (
                            <Card style={{ margin: '20px 0' }}>
                                <CardContent>
                                    <Grid container>
                                        <OrderTable orders={promoCode.orders} />
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                    </Container>
                </>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default PromoCode
