import { GET_ALL_TEAMMEMBERS, GET_ONE_TEAMMEMBER } from '../types'

const initialState = {
    teamMembers: null,
    teamMember: null,
}

export const teamMemberReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_TEAMMEMBERS:
            return {
                ...state,
                teamMembers: payload,
            }
        case GET_ONE_TEAMMEMBER:
            return {
                ...state,
                teamMember: payload,
            }
        default:
            return state
    }
}

export default teamMemberReducer
