import { errorsReturn } from '../errorsReturn'
import { GET_ALL_HOMEPAGES, GET_ONE_HOMEPAGE } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { logout, renewUserToken } from './userActions'
import {
    HOMEPAGE_ADD_SUCCESSFULLY,
    HOMEPAGE_UPDATE_SUCCESSFULLY,
    HOMEPAGE_DELETE_SUCCESSFULLY,
} from '../../constant/default'

export const getAllHomePages = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`homepage/listall`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ALL_HOMEPAGES, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOneHomePage = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`homepage/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_HOMEPAGE, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const createHomePage = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.post(`homepage/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(HOMEPAGE_ADD_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllHomePages())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateHomePage = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.put(`homepage/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(HOMEPAGE_UPDATE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllHomePages())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteHomePage = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.delete(`homepage/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(HOMEPAGE_DELETE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllHomePages())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
