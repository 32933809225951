import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import QnACategoryForm from './QnACategoryForm'

const EditQnACategory = ({ handleDialogClose }) => {
    const qnaCategoryState = useSelector((state) => state.qnaCategory)
    const { qnaCategory } = qnaCategoryState

    return qnaCategory ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Q&A Category</DialogTitle>
            <QnACategoryForm
                handleDialogClose={handleDialogClose}
                initialValues={qnaCategory}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditQnACategory
