import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Typography } from '@material-ui/core'
import Layout from '../components/Layout'
import { makeStyles } from '@material-ui/core/styles'
import history from '../history'
import AddIcon from '@material-ui/icons/Add'
import { getAllProducts } from '../store/actions/productActions'
import SearchProduct from '../components/product/SearchProduct'
import ReduxPagnation from '../components/ReduxPagnation'
import ProductList from '../components/product/ProductList'
import { CHANGE_PRODUCTS_PAGE } from '../store/types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tableHeaderCell: {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}))

const Products = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const productState = useSelector((state) => state.product)
    const { products, pages, page, count, keyword } = productState
    const pageNumber = page || 1

    useEffect(() => {
        dispatch(getAllProducts(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_PRODUCTS_PAGE, payload: page })
        },
        [dispatch]
    )

    return (
        <Layout>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<AddIcon />}
                    onClick={() => history.push('/addproduct')}
                >
                    Add New Product
                </Button>
            </Box>
            <SearchProduct displayKeyword={keyword} />
            <Typography variant="body2" gutterBottom>
                Count: {count}
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>
                                Category
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Style
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Sku
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Name
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeaderCell}
                            ></TableCell>

                            <TableCell
                                className={classes.tableHeaderCell}
                                style={{ width: '50px' }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products &&
                            products.map((product) => (
                                <ProductList
                                    product={product}
                                    key={product.id}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ReduxPagnation page={pageNumber} pages={pages} func={changePage} />
        </Layout>
    )
}

export default Products
