import { GET_ALL_PICKUPLOCATIONS, GET_ONE_PICKUPLOCATION } from '../types'

const initialState = {
    pickUpLocations: [],
    pickUpLocation: {},
}

export const pickUpLocationReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PICKUPLOCATIONS:
            return {
                ...state,
                pickUpLocations: payload,
            }
        case GET_ONE_PICKUPLOCATION:
            return {
                ...state,
                pickUpLocation: payload,
            }
        default:
            return state
    }
}

export default pickUpLocationReducer
