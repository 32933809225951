import {
    Button,
    Container,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Card,
    CardHeader,
    CardContent,
    TableBody,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import {
    designerSubmitConfirm,
    getOneRequestDesign,
} from '../store/actions/clientRequestDesignActions'
import history from '../history'
import '../components/requestDesign/requestDesignInfo.css'
import RequestDesignDetailForm from '../components/requestDesign/RequestDesignDetailForm'
import RequestDesignDetailInfo from '../components/requestDesign/RequestDesignDetailInfo'
import RequestDesignDesigners from '../components/requestDesign/RequestDesignDesigners'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { green } from '@material-ui/core/colors'

const ClientRequestDesignInfo = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)
    const clientRequestDesignState = useSelector(
        (state) => state.clientRequestDesign
    )
    const { requestDesign } = clientRequestDesignState

    useEffect(() => {
        dispatch(getOneRequestDesign(id))
    }, [dispatch, id])

    useEffect(() => {
        setEdit(false)
        window.scrollTo(0, 0)
    }, [requestDesign])

    const sizeMeasure = requestDesign?.sizeMeasure === 1 ? 'ft' : 'm'
    return (
        <Layout>
            {requestDesign ? (
                <Container>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                            style={{ margin: '20px 0' }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <h3>User Info</h3>
                    <hr />
                    <div className="request-design-userinfo">
                        <div className="request-design-userinfo-item">
                            <div className="request-design-userinfo-left">
                                First Name:
                            </div>
                            <div className="request-design-userinfo-right">
                                {requestDesign.firstName}
                            </div>
                        </div>
                        <div className="request-design-userinfo-item">
                            <div className="request-design-userinfo-left">
                                Last Name:
                            </div>
                            <div className="request-design-userinfo-right">
                                {requestDesign.lastName}
                            </div>
                        </div>
                        <div className="request-design-userinfo-item">
                            <div className="request-design-userinfo-left">
                                Email:
                            </div>
                            <div className="request-design-userinfo-right">
                                {requestDesign.email}
                            </div>
                        </div>
                        <div className="request-design-userinfo-item">
                            <div className="request-design-userinfo-left">
                                Phone:
                            </div>
                            <div className="request-design-userinfo-right">
                                {requestDesign.phone}
                            </div>
                        </div>
                    </div>
                    <h3>Room Size</h3>
                    <hr />
                    <div className="request-design-room">
                        <div className="request-design-room-item">
                            Width: {requestDesign.width} {sizeMeasure}
                        </div>
                        <div className="request-design-room-item">
                            Length: {requestDesign.length} {sizeMeasure}
                        </div>
                        <div className="request-design-room-item">
                            Height: {requestDesign.height} {sizeMeasure}
                        </div>
                    </div>
                    <h3>Kitchen images</h3>
                    <hr />
                    <div className="request-design-kitchen-images-box">
                        <div className="request-design-kitchen-images-left">
                            <img
                                src="https://res.cloudinary.com/home-space/image/upload/v1640031570/Request_Design/Screen_Shot_2021-12-20_at_3.19.25_PM_juufqf.png"
                                className="request-design-kitchen-size-pic"
                                alt="size pic"
                            />
                        </div>
                        <div className="request-design-kitchen-images-right">
                            {requestDesign.photo1 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 1 Photo</h3>
                                    <img
                                        src={requestDesign.photo1}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                            {requestDesign.photo2 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 2 Photo</h3>
                                    <img
                                        src={requestDesign.photo2}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                            {requestDesign.photo3 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 3 Photo</h3>
                                    <img
                                        src={requestDesign.photo3}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                            {requestDesign.photo4 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 4 Photo</h3>
                                    <img
                                        src={requestDesign.photo4}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {requestDesign.requestdesigndetail && (
                            <RequestDesignDesigners
                                designers={requestDesign.designers}
                                requestDesignerId={requestDesign.id}
                            />
                        )}
                    </div>
                    <div>
                        {requestDesign.designersubmits &&
                            requestDesign.designersubmits.length > 0 && (
                                <Card style={{ margin: '20px 0' }}>
                                    <CardHeader title="Designer Submit" />
                                    <CardContent>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Designer
                                                    </TableCell>
                                                    <TableCell>Pic</TableCell>
                                                    <TableCell>Link</TableCell>
                                                    <TableCell>
                                                        Confirm
                                                    </TableCell>
                                                    <TableCell>
                                                        Customer Selected
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {requestDesign.designersubmits.map(
                                                    (submit) => (
                                                        <TableRow
                                                            key={submit.id}
                                                            sx={{
                                                                '&:last-child td, &:last-child th':
                                                                    {
                                                                        border: 0,
                                                                    },
                                                            }}
                                                        >
                                                            <TableCell>
                                                                {
                                                                    submit
                                                                        .designer
                                                                        .firstName
                                                                }{' '}
                                                                {
                                                                    submit
                                                                        .designer
                                                                        .lastName
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <img
                                                                    src={
                                                                        submit.picLink
                                                                    }
                                                                    style={{
                                                                        width: '100px',
                                                                        height: '100px',
                                                                        objectFit:
                                                                            'contain',
                                                                    }}
                                                                    alt="piclink"
                                                                    onError={(
                                                                        event
                                                                    ) => {
                                                                        event.target.src =
                                                                            'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                                                        event.onerror =
                                                                            null
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <a
                                                                    href={
                                                                        submit.link
                                                                    }
                                                                    target="_black"
                                                                >
                                                                    Link
                                                                </a>
                                                            </TableCell>
                                                            <TableCell>
                                                                {!submit.selected ? (
                                                                    submit.confirm ? (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            size="small"
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    designerSubmitConfirm(
                                                                                        requestDesign.id,
                                                                                        {
                                                                                            id: submit.id,
                                                                                            confirm: false,
                                                                                        }
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                            size="small"
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    designerSubmitConfirm(
                                                                                        requestDesign.id,
                                                                                        {
                                                                                            id: submit.id,
                                                                                            confirm: true,
                                                                                        }
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            Confirm
                                                                        </Button>
                                                                    )
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {submit.selected && (
                                                                    <CheckCircleOutlineIcon
                                                                        style={{
                                                                            color: green[500],
                                                                        }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                </Card>
                            )}
                    </div>
                    <div style={{ padding: '20px 0' }}>
                        {requestDesign.requestdesigndetail ? (
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <h3>Detail</h3>{' '}
                                    <div style={{ marginLeft: '20px' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setEdit(!edit)}
                                        >
                                            {edit ? 'Close ' : ''}Edit
                                        </Button>
                                    </div>
                                </div>
                                {!edit && (
                                    <RequestDesignDetailInfo
                                        requestDesign={requestDesign}
                                    />
                                )}
                            </div>
                        ) : (
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={() => setCreate(!create)}
                                >
                                    {create ? 'Close' : 'Add'} Detail
                                </Button>
                            </div>
                        )}
                    </div>
                    {create && !requestDesign.requestdesigndetail && (
                        <RequestDesignDetailForm
                            requestDesign={requestDesign}
                            editType="create"
                        />
                    )}
                    {edit && requestDesign.requestdesigndetail && (
                        <RequestDesignDetailForm
                            requestDesign={requestDesign}
                            editType="edit"
                        />
                    )}
                </Container>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default ClientRequestDesignInfo
