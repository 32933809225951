import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import PromoCodeForm from './PromoCodeForm'

const EditPromoCode = ({ handleDialogClose }) => {
    const promoCodeState = useSelector((state) => state.promoCode)
    const { promoCode } = promoCodeState

    return promoCode ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Promo Code</DialogTitle>
            <PromoCodeForm
                handleDialogClose={handleDialogClose}
                initialValues={promoCode}
                formType={FORM_TYPE_EDIT}
                storeStartDate={promoCode.startDate}
                storeEndDate={promoCode.endDate}
            />
        </>
    ) : null
}

export default EditPromoCode
