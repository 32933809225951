import { DialogTitle } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import { getAllProductStyles } from '../../store/actions/styleActions'
import PicturePointItemForm from './PicturePointItemForm'

const EditPicturePointItem = ({ handleDialogClose, picturePointId }) => {
    const dispatch = useDispatch()
    const picturePointItemState = useSelector((state) => state.picturePointItem)
    const { picturePointItem } = picturePointItemState
    useEffect(() => {
        dispatch(getAllProductStyles())
    }, [dispatch])
    const values = { ...picturePointItem, styleId: picturePointItem?.style?.id }
    return picturePointItem ? (
        <>
            <DialogTitle id="form-dialog-title">
                Edit Picture Point Item
            </DialogTitle>
            <PicturePointItemForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_EDIT}
                picturePointId={picturePointId}
                initialValues={values}
            />
        </>
    ) : null
}

export default EditPicturePointItem
