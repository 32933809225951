import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Grid,
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HomeIcon from '@material-ui/icons/Home'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditProductPicture from './EditProductPicture'
import {
    deleteProductPicture,
    getOneProductPicture,
    setMainProductPicture,
} from '../../store/actions/productPictureAcitons'
import { DELETE_CONFIRM, ITEM_HEIGHT } from '../../constant/default'
import { orange } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
        marginBottom: 12,
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
}))

const ProductPictureList = ({ picture, productId, productColorOnline }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = (id) => {
        dispatch(getOneProductPicture(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Grid container alignItems="center">
                <Grid
                    item
                    xs={2}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    {picture.mainPage ? (
                        <HomeIcon style={{ color: orange[500] }} />
                    ) : null}
                </Grid>
                <Grid item xs={6}>
                    <img src={picture.picLink} alt="product pic" width="100%" />
                </Grid>
                <Grid
                    item
                    xs={4}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    {productColorOnline === false && (
                        <>
                            <IconButton
                                aria-label="more"
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() => handleEditOpen(picture.id)}
                                >
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                </MenuItem>

                                {picture.mainPage === false && (
                                    <MenuItem
                                        onClick={() =>
                                            dispatch(
                                                setMainProductPicture(
                                                    picture.id,
                                                    productId
                                                )
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <HomeIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Set Main" />
                                    </MenuItem>
                                )}

                                <MenuItem
                                    onClick={() => {
                                        if (window.confirm(DELETE_CONFIRM)) {
                                            dispatch(
                                                deleteProductPicture(
                                                    picture.id,
                                                    productId
                                                )
                                            )
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteForeverIcon
                                            className={classes.deleteIcon}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Delete"
                                        className={classes.deleteIcon}
                                    />
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Grid>
            </Grid>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditProductPicture
                    handleDialogClose={handleEditClose}
                    productId={productId}
                />
            </Dialog>
        </>
    )
}

export default ProductPictureList
