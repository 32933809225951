import { errorsReturn } from '../errorsReturn'
import { GET_ALL_PRODUCTTYPES, GET_ONE_PRODUCTTYPE } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { logout, renewUserToken } from './userActions'
import {
    PRODUCTTYPE_ADD_SUCCESSFULLY,
    PRODUCTTYPE_DELETE_SUCCESSFULLY,
    PRODUCTTYPE_UPDATE_SUCCESSFULLY,
} from '../../constant/default'

export const getAllProductTypes = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`producttype/listall`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ALL_PRODUCTTYPES, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOneProductType = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`producttype/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_PRODUCTTYPE, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const createProductType = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.post(`producttype/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PRODUCTTYPE_ADD_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllProductTypes())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateProductType = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.put(`producttype/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PRODUCTTYPE_UPDATE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllProductTypes())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteProductType = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.delete(`producttype/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PRODUCTTYPE_DELETE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllProductTypes())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
