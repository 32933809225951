import { Button, TableCell, TableRow } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import history from '../../history'

const DesignerList = ({ designer }) => {
    return (
        <>
            <TableRow>
                <TableCell>{designer.email}</TableCell>
                <TableCell>
                    {designer.firstName} {designer.lastName}
                </TableCell>
                <TableCell>{designer.phone}</TableCell>
                <TableCell align="center">
                    {designer.emailVerify ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell align="center">{designer.status}</TableCell>
                <TableCell align="center">
                    {designer.isActive ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell align="center">
                    {designer.blockUser ? (
                        <BlockIcon style={{ color: red[500] }} />
                    ) : (
                        ''
                    )}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => history.push(`/designer/${designer.id}`)}
                    >
                        Info
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default DesignerList
