import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Typography } from '@material-ui/core'
import Layout from '../components/Layout'
import ReduxPagnation from '../components/ReduxPagnation'
import { CHANGE_DESIGNERS_PAGE } from '../store/types'
import { getAllDesigners } from '../store/actions/designerActions'
import DesignerSearch from '../components/designer/DesignerSearch'
import DesignerInfos from '../components/designer/DesignerInfos'

const Designers = () => {
    const dispatch = useDispatch()
    const designerState = useSelector((state) => state.designer)
    const { designers, pages, page, count, keyword } = designerState

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllDesigners(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_DESIGNERS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <h2 style={{ textAlign: 'center' }}>Designer List</h2>
                <DesignerSearch displayKeyword={keyword} />
                <Typography variant="body2" gutterBottom>
                    Count: {count}
                </Typography>
                {designers && <DesignerInfos designers={designers} />}
                <ReduxPagnation
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Designers
