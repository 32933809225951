import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Fab,
    Grid,
    Tooltip,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Dialog,
    Container,
    Paper,
    Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
    DELETE_CONFIRM,
    EMPTY_CHILDREN,
    ITEM_HEIGHT,
} from '../../constant/default'
import {
    deleteQnACategory,
    getOneQnACategory,
} from '../../store/actions/qnaCatogoryActions'
import EditQnACategory from './EditQnACategory'
import AddQnAItem from '../qnaItem/AddQnAItem'
import QnAItemList from '../qnaItem/QnAItemList'

const useStyles = makeStyles((theme) => ({
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    paper: {
        padding: '20px',
        margin: '10px 0',
    },
}))

const QnACategoryList = ({ qnaCategory }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [itemOpen, setItemOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const handleItemOpen = () => {
        setItemOpen(true)
    }

    const handleItemClose = () => {
        setItemOpen(false)
    }

    const handleEditOpen = () => {
        dispatch(getOneQnACategory(qnaCategory.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Container>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div
                            style={{
                                display: 'flex',

                                alignItems: 'center',
                            }}
                        >
                            <div>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="customized-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    <MenuItem onClick={handleEditOpen}>
                                        <ListItemIcon>
                                            <EditIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Edit" />
                                    </MenuItem>
                                    {qnaCategory.qnaitems &&
                                        qnaCategory.qnaitems.length ===
                                            EMPTY_CHILDREN && (
                                            <MenuItem
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            DELETE_CONFIRM
                                                        )
                                                    ) {
                                                        dispatch(
                                                            deleteQnACategory(
                                                                qnaCategory.id
                                                            )
                                                        )
                                                    }
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <DeleteForeverIcon
                                                        className={
                                                            classes.deleteIcon
                                                        }
                                                        fontSize="small"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Delete"
                                                    className={
                                                        classes.deleteIcon
                                                    }
                                                />
                                            </MenuItem>
                                        )}
                                </Menu>
                            </div>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {qnaCategory.name}
                            </Typography>
                            <div style={{ marginRight: '10px' }}>
                                <Tooltip title="Add Item" aria-label="add">
                                    <Fab
                                        color="primary"
                                        size="small"
                                        className={classes.fab}
                                        onClick={handleItemOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            {qnaCategory.qnaitems &&
                                qnaCategory.qnaitems.length > 0 &&
                                qnaCategory.qnaitems.map((qnaItem) => (
                                    <QnAItemList
                                        key={qnaItem.id}
                                        qnaItem={qnaItem}
                                        qnaCategoryId={qnaCategory.id}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    open={itemOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-style"
                >
                    <AddQnAItem
                        handleDialogClose={handleItemClose}
                        qnaCategoryId={qnaCategory.id}
                    />
                </Dialog>
                <Dialog
                    open={editOpen}
                    // onClose={handleEditClose}
                    aria-labelledby="form-dialog-edit"
                >
                    <EditQnACategory handleDialogClose={handleEditClose} />
                </Dialog>
            </Paper>
        </Container>
    )
}

export default QnACategoryList
