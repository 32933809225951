import React from 'react'
import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import RequestDesignDetailForm from '../components/requestDesign/RequestDesignDetailForm'

const ClientRequestDesignDetail = () => {
    const clientRequestDesignState = useSelector(
        (state) => state.clientRequestDesign
    )
    const { requestDesign } = clientRequestDesignState

    return (
        <Layout>
            {requestDesign && (
                <RequestDesignDetailForm requestDesign={requestDesign} />
            )}
        </Layout>
    )
}

export default ClientRequestDesignDetail
