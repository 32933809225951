import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_DESIGNERS_NAME,
    GET_ALL_REQUEST_DESIGNS,
    GET_ONE_REQUEST_DESIGN,
} from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { DESIGN_ACTIVE, DESIGN_FINISHED } from '../../constant/default'

export const getAllRequestDesigns =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.get(
                `client/requestdesign/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_REQUEST_DESIGNS,
                payload: {
                    requestDesigns: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const getOneRequestDesign = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`client/requestdesign/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_REQUEST_DESIGN, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const designerSubmitConfirm =
    (id, values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.put(
                `client/requestdesign/submitconfirm/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneRequestDesign(id))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const requestDesignDetailCreate =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.post(
                `client/requestdesign/createdetial`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneRequestDesign(values.requestDesignId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const requestDesignDetailUpdate =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.put(
                `client/requestdesign/updatedetail/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneRequestDesign(values.requestDesignId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const editRequestDesignDesigners =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.put(
                `client/requestdesign/updatedeginser/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneRequestDesign(values.id))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const getDesignerName = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`designer/designeruser/designerlist`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        let newData = res.data.data
        if (res.data.data && res.data.data.length > 0) {
            newData = res.data.data.map((item) => {
                const designs = item.requestdesigns

                return {
                    ...item,
                    active:
                        designs.filter((list) => list.status === DESIGN_ACTIVE)
                            .length || 0,
                    finished:
                        designs.filter(
                            (list) => list.status === DESIGN_FINISHED
                        ).length || 0,
                }
            })
        }
        dispatch({ type: GET_ALL_DESIGNERS_NAME, payload: newData })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
