import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { reduxForm, Field } from 'redux-form'
import {
    checkboxGroup,
    renderCheckbox,
    renderSelectField,
    renderTextField,
} from '../ReduxFormInputs'

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import { FormControl, FormGroup, FormLabel } from '@material-ui/core'
import './product.css'
import { getErrors } from '../../store/actions/errorActions'
import {
    createProduct,
    updateProduct,
} from '../../store/actions/productActions'
import history from '../../history'
import { FORM_TYPE_EDIT } from '../../constant/default'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    back: {
        margin: theme.spacing(3, 3, 2),
    },
}))

const ProductTypeList = ({ pType, setTypeId, typeId }) => {
    const selectedCss = typeId === pType.id ? 'product-type-box-selected' : null
    return (
        <Grid
            item
            xs={6}
            sm={3}
            className={`product-type-box ${selectedCss}`}
            onClick={() => setTypeId(pType.id)}
        >
            <div>
                <img src={pType.picLink} alt="type" width="50px" />
            </div>
            <div>{pType.name}</div>
        </Grid>
    )
}
const Products = (props) => {
    const dispatch = useDispatch()
    const {
        handleSubmit,
        formType,
        preProductType,
        preProductFunctions,
        preTypeId,
        preStyle,
        sizesList,
        preProductOption,
    } = props
    const classes = useStyles()

    const [selectStyle, setSelectStyle] = useState('')
    const [productType, setProductType] = useState(false)
    const [typeId, setTypeId] = useState('')
    const [productFunction, setProductFunction] = useState(false)
    const [productOption, setProductOption] = useState(false)
    const [sizes, setSizes] = useState([])

    const styleState = useSelector((state) => state.style)
    const { productStyles } = styleState

    const productTypeState = useSelector((state) => state.productType)
    const { productTypes } = productTypeState

    const productFunctionState = useSelector((state) => state.productFunction)
    const { productFunctions } = productFunctionState
    useEffect(() => {
        if (formType === FORM_TYPE_EDIT) {
            setProductType(preProductType)
            setProductFunction(preProductFunctions)
            setTypeId(preTypeId)
            setSelectStyle(preStyle)
            setSizes(sizesList)
            setProductOption(preProductOption)
        }
    }, [])

    const submitForm = (formValues) => {
        if (!selectStyle) {
            return dispatch(getErrors('Please select Style'))
        }
        formValues['styleId'] = selectStyle
        if (productType) {
            if (!typeId) {
                return dispatch(getErrors('Please select Type'))
            }
            formValues['producttype'] = typeId
        } else {
            formValues['producttype'] = ''
        }

        if (!productFunction) {
            formValues['productfunctions'] = []
        }

        // console.log(formValues)
        formType === FORM_TYPE_EDIT
            ? dispatch(updateProduct(formValues))
            : dispatch(createProduct(formValues))
    }

    const handleStyleChange = (e) => {
        const styleId = e.target.value
        if (styleId) {
            setSelectStyle(styleId)
            const styleValue = productStyles.filter(
                (style) => style.id === styleId
            )[0]
            setSizes(styleValue.category.sizes)
            setProductFunction(styleValue.category.functionOption)
            setProductOption(styleValue.category.productOption)
            setProductType(styleValue.category.typeOption)
        } else {
            setSelectStyle('')
            setSizes([])
            setProductFunction(false)
            setProductOption(false)
            setProductType(false)
        }
    }

    const functionsList = productFunctions?.map((func) => {
        return { id: func.id, name: func.functionName }
    })
    return (
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <PlaylistAddIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {formType === FORM_TYPE_EDIT ? 'Edit' : 'Add New'} Product
            </Typography>
            <form
                onSubmit={handleSubmit(submitForm)}
                className={classes.form}
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field
                            id="styleId"
                            required
                            label="Style"
                            name="styleId"
                            value={selectStyle}
                            component={renderSelectField}
                            onChange={(e) => handleStyleChange(e)}
                        >
                            <option aria-label="None" />
                            {productStyles &&
                                productStyles.map((style) => (
                                    <option value={style.id} key={style.id}>
                                        {style.category.name} - {style.name}
                                    </option>
                                ))}
                        </Field>
                    </Grid>
                    {productFunction && (
                        <Grid item xs={12}>
                            <FormControl
                                component="fieldset"
                                className={classes.formControl}
                            >
                                <FormLabel component="legend">
                                    Functions
                                </FormLabel>
                                <FormGroup row>
                                    <Field
                                        name="productfunctions"
                                        component={checkboxGroup}
                                        options={functionsList}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    )}
                    {productType && (
                        <Grid item xs={12}>
                            <FormControl
                                component="fieldset"
                                className={classes.formControl}
                            >
                                <FormLabel
                                    component="legend"
                                    style={{ marginBottom: '15px' }}
                                >
                                    Types
                                </FormLabel>

                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                >
                                    {productTypes.map((pType) => (
                                        <ProductTypeList
                                            key={pType.id}
                                            pType={pType}
                                            setTypeId={setTypeId}
                                            typeId={typeId}
                                        />
                                    ))}
                                </Grid>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Field
                            id="sizeId"
                            required
                            label="Size"
                            name="sizeId"
                            component={renderSelectField}
                        >
                            <option aria-label="None" />
                            {sizes?.map((size) => (
                                <option value={size.id} key={size.id}>
                                    {size.sizeValue}
                                </option>
                            ))}
                        </Field>
                    </Grid>

                    <Grid item xs={12}>
                        <Field
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="sku"
                            label="SKU"
                            name="sku"
                            inputProps={{ maxLength: 30 }}
                            component={renderTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            id="name"
                            label="Name"
                            name="name"
                            inputProps={{ maxLength: 200 }}
                            component={renderTextField}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            className={classes.formControl}
                        >
                            <FormLabel component="legend">
                                Product Size
                            </FormLabel>

                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                spacing={2}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="productWidth"
                                        label="Width"
                                        name="productWidth"
                                        type="number"
                                        inputProps={{ maxLength: 10 }}
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="productHeight"
                                        label="Height"
                                        name="productHeight"
                                        type="number"
                                        inputProps={{ maxLength: 10 }}
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="productDepth"
                                        label="Depth"
                                        name="productDepth"
                                        type="number"
                                        inputProps={{ maxLength: 10 }}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            className={classes.formControl}
                        >
                            <FormLabel component="legend">
                                Package Size
                            </FormLabel>

                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                spacing={2}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="packageWidth"
                                        label="Width"
                                        type="number"
                                        name="packageWidth"
                                        inputProps={{ maxLength: 10 }}
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="packageHeight"
                                        label="Height"
                                        name="packageHeight"
                                        type="number"
                                        inputProps={{ maxLength: 10 }}
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="packageDepth"
                                        label="Depth"
                                        name="packageDepth"
                                        type="number"
                                        inputProps={{ maxLength: 10 }}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    {productOption && (
                        <Grid item xs={12}>
                            <Field
                                name="doorDirection"
                                component={renderCheckbox}
                                label="Door Direction"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Field
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                            multiline
                            rows={4}
                            component={renderTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="materialsAndCare"
                            label="Materials And Care"
                            name="materialsAndCare"
                            multiline
                            rows={4}
                            component={renderTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="warranty"
                            label="Warranty"
                            name="warranty"
                            multiline
                            rows={4}
                            component={renderTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="whatInPackage"
                            label="What In Package"
                            name="whatInPackage"
                            multiline
                            rows={4}
                            component={renderTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="pdfLink"
                            label="PDF Link"
                            name="pdfLink"
                            inputProps={{ maxLength: 200 }}
                            component={renderTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="sample"
                            component={renderCheckbox}
                            label="Sample"
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.back}
                        onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </Grid>
            </form>
        </div>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'sku',
        'name',
        'productWidth',
        'productHeight',
        'productDepth',
        'packageWidth',
        'packageHeight',
        'packageDepth',
        'sizeId',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'productForm',
    validate,
    enableReinitialize: true,
})(Products)
