import { DialogTitle } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDesignerName } from '../../store/actions/clientRequestDesignActions'
import { GET_ALL_DESIGNERS_NAME } from '../../store/types'
import EditDesignerForm from './EditDesignerForm'

const EditDesigner = ({
    handleDialogClose,
    designerList,
    requestDesignerId,
}) => {
    const dispatch = useDispatch()
    const requestDesignState = useSelector((state) => state.clientRequestDesign)
    const { designers } = requestDesignState
    useEffect(() => {
        dispatch(getDesignerName())

        return () => {
            dispatch({ type: GET_ALL_DESIGNERS_NAME, payload: null })
        }
    }, [dispatch])
    const values = { designerLists: designerList.map((list) => list.id) }
    return (
        <>
            {designers ? (
                <>
                    <DialogTitle id="form-dialog-title" width="400px">
                        Edit Designers
                    </DialogTitle>
                    <EditDesignerForm
                        handleDialogClose={handleDialogClose}
                        initialValues={values}
                        requestDesignerId={requestDesignerId}
                        designers={designers}
                    />
                </>
            ) : (
                ''
            )}
        </>
    )
}

export default EditDesigner
