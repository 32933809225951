import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import PromoCodeForm from './PromoCodeForm'

const AddPromoCode = ({ handleDialogClose }) => {
    const values = { active: true }
    return (
        <>
            <DialogTitle id="form-dialog-title">Add New Promo Code</DialogTitle>
            <PromoCodeForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
                initialValues={values}
            />
        </>
    )
}

export default AddPromoCode
