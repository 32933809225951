import {
    GET_ALL_PRODUCTS,
    SEARCH_PRODUCTS,
    CHANGE_PRODUCTS_PAGE,
    GET_ONE_PRODUCT,
} from '../types'

const initialState = {
    products: [],
    product: {},
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

const productReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                products: payload.products,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                product: null,
            }
        case SEARCH_PRODUCTS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_PRODUCTS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_PRODUCT:
            return {
                ...state,
                product: payload,
            }
        default:
            return state
    }
}

export default productReducer
