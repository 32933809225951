import { Button, DialogTitle, Grid, TextField } from '@material-ui/core'
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import request from '../../config/request'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { getErrors } from '../../store/actions/errorActions'
import { controlLoading } from '../../store/actions/loadingActions'
import { logout, renewUserToken } from '../../store/actions/userActions'
import { newToken } from '../../admin/newtoken'
import { errorsReturn } from '../../store/errorsReturn'
import { getOneOrder } from '../../store/actions/orderActions'
import { createMessage } from '../../store/actions/messageActions'
import { doors } from '../../constant/default'

const EditItem = ({ handleDialogClose, item, orderId }) => {
    const dispatch = useDispatch()
    const [qty, setQty] = useState(() => item.quantity)
    const [price, setPrice] = useState(() => item.price)
    const [door, setDoor] = useState(null)
    const [error, setError] = useState(false)
    const errorCss = error ? 'product-option-door-select-error' : null

    const { userToken } = useSelector((state) => state.user)

    const quantityMax = useMemo(() => {
        return Math.max(item.productcolor.stock, item.quantity)
    }, [item])

    useEffect(() => {
        if (item.doorDirection) {
            if (item.doorDirection === 'Left') {
                setDoor(1)
            }
            if (item.doorDirection === 'Right') {
                setDoor(2)
            }
        }
    }, [item])

    const handleSubmit = async () => {
        if (!qty) {
            dispatch(getErrors('Please select quantity'))
            return
        }
        if (item.doorDirection && !door) {
            dispatch(getErrors('Please select door direction'))
            setError(true)
            return
        }

        let doorDirection = ''
        if (door) {
            door === 1 ? (doorDirection = 'Left') : (doorDirection = 'Right')
        }
        const values = {
            quantity: qty,
            price: price,
            doorDirection,
            orderId,
        }

        try {
            const res = await request.put(
                `/order/updateorderitem/${item.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            setQty('')
            setDoor(null)
            setError(false)
            dispatch(getOneOrder(orderId))
            dispatch(createMessage('Order Item edit successful'))
            handleDialogClose(false)
        } catch (e) {
            console.log(e)
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

    return (
        <div
            style={{
                width: '600px',
                minHeight: '400px',
                padding: '20px',
                position: 'relative',
            }}
        >
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
                Edit Item
            </DialogTitle>
            <div
                style={{ position: 'absolute', right: '10px', top: '10px' }}
                onClick={() => handleDialogClose(false)}
            >
                <HighlightOffIcon
                    style={{ fontSize: '30px', cursor: 'pointer' }}
                />
            </div>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '20px',
                    }}
                >
                    <img
                        src={
                            item?.picLink ||
                            'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                        }
                        alt="pic"
                        style={{
                            width: '50%',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {item.name}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={price}
                        type="number"
                        onChange={(e) => setPrice(e.target.value)}
                        id="Price"
                        label="Price"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Quantity
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                            label="Quantity"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {Array.from(Array(quantityMax), (e, i) => (
                                <MenuItem key={i} value={i + 1}>
                                    {i + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {item.doorDirection && (
                    <div className="product-option-door-box">
                        <div className="product-option-door-title">
                            Door Hinge Location{' '}
                            <span className="product-option-door-star">
                                * {error && 'This is a required field.'}
                            </span>
                        </div>
                        <div
                            className={`product-option-door-select ${errorCss}`}
                        >
                            {doors.map((d) => {
                                const selectedCss =
                                    door === d.id
                                        ? 'product-option-door-selected'
                                        : null
                                return (
                                    <div
                                        key={d.direction}
                                        className={`product-option-door-item ${selectedCss}`}
                                        onClick={() => setDoor(d.id)}
                                    >
                                        <img
                                            src={d.icon}
                                            className="product-option-door-icon"
                                            alt="icon"
                                        />
                                        <div>{d.direction}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default EditItem
