import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Layout from '../components/Layout'
import {
    changeOnlineStatus,
    getALLProductColors,
    updateStocks,
} from '../store/actions/productColorActions'
import { Button, TextField } from '@material-ui/core'
import history from '../history'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { green, red, yellow } from '@material-ui/core/colors'
import SearchIcon from '@material-ui/icons/Search'
import { SEARCH_PRODUCTCOLORS } from '../store/types'
import { PRODUCT_COLOR_FORWARD } from '../constant/default'
import TransformIcon from '@material-ui/icons/Transform'
import StarIcon from '@material-ui/icons/Star'

function createData(
    id,
    name,
    online,
    skuNo,
    apiStock,
    color,
    colorName,
    price,
    stock,
    category,
    style,
    productId,
    recommend
) {
    return {
        id,
        name,
        online,
        skuNo,
        apiStock,
        color,
        colorName,
        price,
        stock,
        category,
        style,
        productId,
        recommend,
    }
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    { id: 'online', numeric: false, disablePadding: false, label: 'Online' },
    { id: 'skuNo', numeric: false, disablePadding: false, label: 'Sku No' },
    { id: 'color', numeric: false, disablePadding: false, label: 'Color' },
    { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
    {
        id: 'stock',
        numeric: true,
        disablePadding: false,
        label: 'Stock',
    },
    {
        id: 'recommend',
        numeric: false,
        disablePadding: false,
        label: 'Recommend',
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Category',
    },
    { id: 'style', numeric: false, disablePadding: false, label: 'Style' },
    { id: 'productId', numeric: false, disablePadding: false, label: '' },
]

function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.primary.main,
                  backgroundColor: lighten(theme.palette.primary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}))

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles()
    const { numSelected, selected, displayKeyword, dispatch, setSelected } =
        props

    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        setKeyword(displayKeyword ?? '')
    }, [displayKeyword])

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_PRODUCTCOLORS,
            payload: {
                keyword,
            },
        })
    }

    const handleOnline = (status) => {
        const values = {
            productColorIds: selected,
            onlineStatus: status,
        }
        dispatch(changeOnlineStatus(values))
        setSelected([])
    }
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Products Colors
                    </Typography>
                </>
            )}

            {numSelected > 0 ? (
                <div
                    className="product-color-container"
                    style={{ margin: '15px 0', width: '300px' }}
                >
                    <Tooltip title="Online">
                        <IconButton
                            aria-label="Online"
                            onClick={() => handleOnline(true)}
                        >
                            <CheckCircleOutlineIcon
                                style={{
                                    color: green[500],
                                    fontSize: '30px',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Offline">
                        <IconButton
                            aria-label="OffLine"
                            onClick={() => handleOnline(false)}
                        >
                            <BlockIcon
                                style={{
                                    color: red[500],
                                    fontSize: '30px',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                    style={{ width: '100%' }}
                >
                    <div
                        className="product-color-container"
                        style={{ margin: '15px 0' }}
                    >
                        <TextField
                            fullWidth
                            id="keyword"
                            label="Search..."
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <IconButton aria-label="filter list" type="submit">
                            <SearchIcon />
                        </IconButton>
                    </div>
                </form>
            )}
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableAlignItem: {
        display: 'flex',
        alignItems: 'center',
    },
    tableCenterItem: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

export default function ProductSelect() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const productColorState = useSelector((state) => state.productColor)
    const { productColors, keyword } = productColorState

    useEffect(() => {
        dispatch(getALLProductColors(keyword))
    }, [dispatch, keyword])

    const rows = productColors.map((productColor) => {
        return createData(
            productColor.id,
            productColor?.product?.name,
            productColor.online,
            productColor.skuNo,
            productColor.apiStock,
            productColor.stylecolor.colorNumber,
            productColor.stylecolor.colorName,
            productColor.price,
            productColor.stock,
            productColor?.product?.style?.category?.name,
            productColor?.product?.style?.name,
            productColor?.product?.id,
            productColor.recommend
        )
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

    return (
        <Layout>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        selected={selected}
                        displayKeyword={keyword}
                        dispatch={dispatch}
                        setSelected={setSelected}
                    />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id
                                        )
                                        const labelId = `enhanced-table-checkbox-${index}`

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) =>
                                                    handleClick(event, row.id)
                                                }
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby':
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.online ? (
                                                        <CheckCircleOutlineIcon
                                                            style={{
                                                                color: green[500],
                                                            }}
                                                        />
                                                    ) : (
                                                        <BlockIcon
                                                            style={{
                                                                color: red[500],
                                                            }}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {row.skuNo}
                                                </TableCell>
                                                <TableCell>
                                                    <div className="product-color-list">
                                                        <div
                                                            className="color-icon"
                                                            style={{
                                                                backgroundColor:
                                                                    row.color,
                                                            }}
                                                        ></div>
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {row.colorName}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    ${row.price}
                                                </TableCell>
                                                <TableCell>
                                                    <div
                                                        className={
                                                            classes.tableAlignItem
                                                        }
                                                    >
                                                        {row.stock}{' '}
                                                        {row.apiStock ? (
                                                            <TransformIcon
                                                                style={{
                                                                    color: green[500],
                                                                    fontSize:
                                                                        '20px',
                                                                    marginLeft:
                                                                        '5px',
                                                                }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {row.recommend ? (
                                                        <StarIcon
                                                            style={{
                                                                color: yellow[700],
                                                                fontSize:
                                                                    '24px',
                                                            }}
                                                        />
                                                    ) : null}
                                                </TableCell>
                                                <TableCell>
                                                    {row.category}
                                                </TableCell>
                                                <TableCell>
                                                    {row.style}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: `/product/${row.productId}`,
                                                                state: {
                                                                    productColorForward:
                                                                        PRODUCT_COLOR_FORWARD,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        Info
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                    }}
                >
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => dispatch(updateStocks())}
                    >
                        Check Stock API
                    </Button>
                </div>
            </div>
        </Layout>
    )
}
