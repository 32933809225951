import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import ProductColorForm from './ProductColorForm'
import { getAllSkuNos } from '../../store/actions/productColorActions'

const AddProductColor = ({ handleDialogClose, productId, styleColorId }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllSkuNos())
    }, [dispatch])
    return (
        <>
            <DialogTitle id="form-dialog-title">
                Add New Product Color
            </DialogTitle>
            <ProductColorForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
                productId={productId}
                styleColorId={styleColorId}
            />
        </>
    )
}

export default AddProductColor
