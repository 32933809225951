import { Button, TableCell, TableRow } from '@material-ui/core'
import { green, red, yellow } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import history from '../../history'
import StarIcon from '@material-ui/icons/Star'

const ProductList = ({ product }) => {
    return (
        <>
            <TableRow>
                <TableCell>{product?.style?.category?.name}</TableCell>
                <TableCell>{product?.style?.name}</TableCell>
                <TableCell>{product.sku}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>
                    {product.productcolors.length > 0
                        ? product.productcolors.map((color) => (
                              <div
                                  key={color.id}
                                  style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                  }}
                              >
                                  <div
                                      className="color-icon"
                                      style={{
                                          backgroundColor:
                                              color?.stylecolor?.colorNumber,
                                          margin: '2px 10px',
                                      }}
                                  ></div>
                                  <div>
                                      {color.skuNo} ${color.price} (
                                      {color.stock})
                                  </div>
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginLeft: '10px',
                                      }}
                                  >
                                      {color.online ? (
                                          <CheckCircleOutlineIcon
                                              style={{ color: green[500] }}
                                          />
                                      ) : (
                                          <BlockIcon
                                              style={{ color: red[500] }}
                                          />
                                      )}
                                      {color.recommend && (
                                          <>
                                              {' '}
                                              <StarIcon
                                                  style={{ color: yellow[700] }}
                                              />
                                          </>
                                      )}
                                  </div>
                              </div>
                          ))
                        : null}
                </TableCell>

                <TableCell>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={() => history.push(`/product/${product.id}`)}
                    >
                        info
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default ProductList
