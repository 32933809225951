import { useDispatch } from 'react-redux'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../ReduxFormInputs'
import { userUpdateProfile } from '../../store/actions/userActions'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))
const ProfileForm = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit } = props
    const classes = useStyles()

    const submitForm = (formValues) => {
        // console.log(formValues)
        dispatch(userUpdateProfile(formValues))
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <EditIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    User Profile
                </Typography>
                <form
                    onSubmit={handleSubmit(submitForm)}
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Field
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                autoComplete="fname"
                                inputProps={{ maxLength: 50 }}
                                component={renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                inputProps={{ maxLength: 50 }}
                                component={renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputProps={{ maxLength: 100 }}
                                component={renderTextField}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="phone"
                                label="Phone"
                                name="phone"
                                autoComplete="phone"
                                inputProps={{ maxLength: 30 }}
                                component={renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="address"
                                label="Address"
                                name="address"
                                autoComplete="address"
                                inputProps={{ maxLength: 200 }}
                                component={renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="newpassword"
                                label="Password"
                                name="newpassword"
                                type="password"
                                inputProps={{ maxLength: 100 }}
                                autoComplete="current-newpassword"
                                component={renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="rpassword"
                                label="Confirm Password"
                                name="rpassword"
                                type="password"
                                inputProps={{ maxLength: 100 }}
                                autoComplete="current-rpassword"
                                component={renderTextField}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Update
                    </Button>
                </form>
            </div>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['firstName', 'lastName', 'email']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.newpassword !== values.rpassword) {
        errors.rpassword = 'Not match'
        errors.newpassword = 'Not match'
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

export default reduxForm({
    form: 'userForm',
    validate,
    enableReinitialize: true,
})(ProfileForm)
