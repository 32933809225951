import { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Container,
    Grid,
    Typography,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { updateOneClient } from '../../store/actions/clientActions'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonStyle: {
        margin: '20px 0',
    },
    gridStyle: {
        margin: '10px 0',
    },
}))

const ClientInfo = ({ client, handleClose }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [blockUser, setBlockUser] = useState(false)

    useEffect(() => {
        setBlockUser(client.blockUser)
    }, [client])

    const handleConfirm = (e) => {
        setBlockUser(!blockUser)
    }

    const handleSubmit = () => {
        const values = { ...client, blockUser }
        dispatch(updateOneClient(values))
        handleClose(false)
    }

    return (
        <Container>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Email
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {client.email}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        First Name
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {client.firstName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Last Name
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {client.lastName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Phone
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {client.phone}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Address
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {client.address}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        City
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {client.city}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Province
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {client.province}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Register Date
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {moment(client.createdAt).format('lll')}
                    </Typography>
                </Grid>
            </Grid>
            <form>
                <Grid
                    container
                    alignItems="center"
                    className={classes.gridStyle}
                >
                    <Grid item xs={4}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            color="primary"
                        >
                            Block
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Checkbox
                            defaultChecked={client.blockUser}
                            onChange={handleConfirm}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Grid>
                </Grid>

                <Grid className={classes.buttonStyle}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleSubmit()}
                        style={{ marginRight: '10px' }}
                    >
                        Update
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleClose(false)}
                    >
                        Close
                    </Button>
                </Grid>
            </form>
        </Container>
    )
}

export default ClientInfo
