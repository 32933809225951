import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import Layout from '../components/Layout'
import { makeStyles } from '@material-ui/core/styles'
import ReduxPagnation from '../components/ReduxPagnation'
import { CHANGE_REQUEST_DESIGNS_PAGE } from '../store/types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { getAllRequestDesigns } from '../store/actions/clientRequestDesignActions'
import SearchRequestDesign from '../components/requestDesign/SearchRequestDesign'
import RequestDesignList from '../components/requestDesign/RequestDesignList'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tableHeaderCell: {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}))

const ClientRequestDesigns = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const clientRequestDesignState = useSelector(
        (state) => state.clientRequestDesign
    )
    const { requestDesigns, pages, page, count, keyword } =
        clientRequestDesignState
    const pageNumber = page || 1

    useEffect(() => {
        dispatch(getAllRequestDesigns(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_REQUEST_DESIGNS_PAGE, payload: page })
        },
        [dispatch]
    )

    return (
        <Layout>
            <SearchRequestDesign displayKeyword={keyword} />
            <Typography variant="body2" gutterBottom>
                Count: {count}
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>
                                Status
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Project No
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Build Type
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Client Name
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Email
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Phone
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Register User
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Request Date
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Designer
                            </TableCell>
                            <TableCell
                                className={classes.tableHeaderCell}
                                style={{ width: '50px' }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requestDesigns &&
                            requestDesigns.map((requestDesign) => (
                                <RequestDesignList
                                    requestDesign={requestDesign}
                                    key={requestDesign.id}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ReduxPagnation page={pageNumber} pages={pages} func={changePage} />
        </Layout>
    )
}

export default ClientRequestDesigns
