import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import PicturePointItemForm from './PicturePointItemForm'
import { getAllProductStyles } from '../../store/actions/styleActions'

const AddPicturePointItem = ({ handleDialogClose, picturePointId, point }) => {
    const dispatch = useDispatch()
    const values = { xCoordinate: point.x, yCoordinate: point.y }
    useEffect(() => {
        dispatch(getAllProductStyles())
    }, [dispatch])
    return (
        <>
            <DialogTitle id="form-dialog-title">
                Add New Picture Point
            </DialogTitle>
            <PicturePointItemForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
                picturePointId={picturePointId}
                initialValues={values}
                preStyle=""
            />
        </>
    )
}

export default AddPicturePointItem
