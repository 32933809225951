import { useState } from 'react'
import { Button, Dialog } from '@material-ui/core'
import AddProductColor from '../productColors/AddProductColor'

const StyleColorList = ({ color, porductColorList, product }) => {
    const [colorOpen, setColorOpen] = useState(false)

    const handleColorOpen = () => {
        setColorOpen(true)
    }

    const handleColorClose = () => {
        setColorOpen(false)
    }
    return (
        <div key={color.id} className="color-box-d">
            <div>
                <h3>{color.colorName}</h3>
            </div>

            <div
                className="color-icon-big"
                style={{
                    backgroundColor: color.colorNumber,
                }}
            ></div>
            {porductColorList.indexOf(color.id) === -1 ? (
                product.online === false ? (
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            size="small"
                            onClick={handleColorOpen}
                            color="primary"
                            variant="outlined"
                        >
                            Add
                        </Button>
                    </div>
                ) : null
            ) : null}
            <div>
                <Dialog
                    open={colorOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-style"
                >
                    <AddProductColor
                        handleDialogClose={handleColorClose}
                        productId={product.id}
                        styleColorId={color.id}
                    />
                </Dialog>
            </div>
        </div>
    )
}

export default StyleColorList
