import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import QnAItemForm from './QnAItemForm'

const AddQnAItem = ({ handleDialogClose, qnaCategoryId }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Q&A item</DialogTitle>
        <QnAItemForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
            qnaCategoryId={qnaCategoryId}
        />
    </>
)

export default AddQnAItem
