import { useEffect } from 'react'
import { DialogTitle } from '@material-ui/core'
import CategoryForm from './CategoryForm'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSizes } from '../../store/actions/sizeActions'
import { FORM_TYPE_EDIT } from '../../constant/default'

const EditCategory = ({ handleDialogClose }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllSizes())
    }, [dispatch])

    const categoryState = useSelector((state) => state.category)
    const { category } = categoryState
    const categoryValues = {
        ...category,
        sizes: category?.sizes?.map((size) => {
            return size.id
        }),
    }
    return category ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Category</DialogTitle>
            <CategoryForm
                handleDialogClose={handleDialogClose}
                initialValues={categoryValues}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditCategory
