import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import StyleColorForm from './StyleColorForm'

const EditStyleColor = ({ handleDialogClose }) => {
    const styleColorState = useSelector((state) => state.styleColor)
    const { styleColor } = styleColorState

    return styleColor ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Style Color</DialogTitle>
            <StyleColorForm
                handleDialogClose={handleDialogClose}
                initialValues={styleColor}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditStyleColor
