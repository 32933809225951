import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import Layout from '../components/Layout'
import { makeStyles } from '@material-ui/core/styles'
import ReduxPagnation from '../components/ReduxPagnation'
import { CHANGE_PRICE_MATCH_PAGE } from '../store/types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PriceMatchSearch from '../components/priceMatch/PriceMatchSearch'
import { getAllPriceMatchs } from '../store/actions/priceMatchActions'
import PriceMatchList from '../components/priceMatch/PriceMatchList'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tableHeaderCell: {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}))

const PriceMatchs = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const priceMatchState = useSelector((state) => state.priceMatch)
    const { priceMatchs, pages, page, count, keyword } = priceMatchState
    const pageNumber = page || 1

    useEffect(() => {
        dispatch(getAllPriceMatchs(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_PRICE_MATCH_PAGE, payload: page })
        },
        [dispatch]
    )

    return (
        <Layout>
            <h2 style={{ textAlign: 'center' }}>Price Match Requests</h2>
            <PriceMatchSearch displayKeyword={keyword} />
            <Typography variant="body2" gutterBottom>
                Count: {count}
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>
                                Client Name
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                email
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Phone
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Quote
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Brand
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                                Date
                            </TableCell>
                            <TableCell
                                className={classes.tableHeaderCell}
                                style={{ width: '50px' }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {priceMatchs &&
                            priceMatchs.map((priceMatch) => (
                                <PriceMatchList
                                    priceMatch={priceMatch}
                                    key={priceMatch.id}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ReduxPagnation page={pageNumber} pages={pages} func={changePage} />
        </Layout>
    )
}

export default PriceMatchs
