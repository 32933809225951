import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
    Dialog,
    Fab,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddProductPicture from '../productPicture/AddProductPicture'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
    deleteProductColor,
    getOneProductColor,
} from '../../store/actions/productColorActions'
import {
    DELETE_CONFIRM,
    EMPTY_CHILDREN,
    ITEM_HEIGHT,
} from '../../constant/default'
import EditProductColor from './EditProductColor'
import ProductPictureList from '../productPicture/ProductPictureList'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { green, red, yellow } from '@material-ui/core/colors'
import TransformIcon from '@material-ui/icons/Transform'
import StarIcon from '@material-ui/icons/Star'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
        marginBottom: 12,
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
}))

const ProductColorList = ({ productColor, productId }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [pictureOpen, setPictureOpen] = useState(false)

    const handlePictureOpen = () => {
        setPictureOpen(true)
    }

    const handlePictureClose = () => {
        setPictureOpen(false)
    }

    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = (id) => {
        dispatch(getOneProductColor(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <div className="product-color-container">
                        <div
                            className="color-icon"
                            style={{
                                backgroundColor:
                                    productColor.stylecolor.colorNumber,
                            }}
                        ></div>{' '}
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                        >
                            {productColor.online ? (
                                <CheckCircleOutlineIcon
                                    style={{ color: green[500] }}
                                />
                            ) : (
                                <BlockIcon style={{ color: red[500] }} />
                            )}{' '}
                            {productColor.skuNo}{' '}
                            {productColor.apiStock ? (
                                <TransformIcon
                                    style={{
                                        color: green[500],
                                        fontSize: '20px',
                                        marginLeft: '5px',
                                    }}
                                />
                            ) : null}
                        </Typography>
                        <div>
                            {productColor.online === false && (
                                <>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() =>
                                                handleEditOpen(productColor.id)
                                            }
                                        >
                                            <ListItemIcon>
                                                <EditIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Edit" />
                                        </MenuItem>
                                        {productColor.productpictures &&
                                            productColor.productpictures
                                                .length === EMPTY_CHILDREN && (
                                                <MenuItem
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                DELETE_CONFIRM
                                                            )
                                                        ) {
                                                            dispatch(
                                                                deleteProductColor(
                                                                    productColor.id,
                                                                    productId
                                                                )
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <DeleteForeverIcon
                                                            className={
                                                                classes.deleteIcon
                                                            }
                                                            fontSize="small"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Delete"
                                                        className={
                                                            classes.deleteIcon
                                                        }
                                                    />
                                                </MenuItem>
                                            )}
                                    </Menu>
                                </>
                            )}
                        </div>
                    </div>

                    <Typography className={classes.pos} variant="subtitle2">
                        Price: ${productColor.price}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Stock: {productColor.stock}
                    </Typography>
                    {productColor.recommend && (
                        <Typography variant="body2" component="p">
                            Recommend:{' '}
                            <StarIcon
                                style={{ color: yellow[700], fontSize: '24px' }}
                            />
                        </Typography>
                    )}
                </CardContent>
                <CardContent>
                    <div className="product-color-box">
                        <div className="product-color-container">
                            <div>
                                <Typography variant="h6">Pictures</Typography>
                            </div>
                            <div>
                                {productColor.online === false && (
                                    <Tooltip
                                        title="Add Picture"
                                        aria-label="add"
                                    >
                                        <Fab
                                            color="primary"
                                            size="small"
                                            className={classes.fab}
                                            onClick={handlePictureOpen}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Tooltip>
                                )}
                            </div>
                        </div>

                        <div>
                            {productColor.productpictures &&
                                productColor.productpictures.length > 0 &&
                                productColor.productpictures.map((picture) => (
                                    <ProductPictureList
                                        picture={picture}
                                        key={picture.id}
                                        productId={productId}
                                        productColorOnline={productColor.online}
                                    />
                                ))}
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Dialog
                open={pictureOpen}
                // onClose={handleDialogClose}
                aria-labelledby="form-dialog-style"
            >
                <AddProductPicture
                    handleDialogClose={handlePictureClose}
                    productId={productId}
                    productColorId={productColor.id}
                />
            </Dialog>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditProductColor
                    handleDialogClose={handleEditClose}
                    productId={productId}
                />
            </Dialog>
        </>
    )
}

export default ProductColorList
