import { useState } from 'react'
import { Dialog, Fab, TableCell, TableRow, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import {
    deleteProductType,
    getOneProductType,
} from '../../store/actions/productTypeActions'
import { DELETE_CONFIRM } from '../../constant/default'
import EditProductType from './EditProductType'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        minWidth: '500px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    fab: {
        margin: theme.spacing(1),
    },
}))

const ProductTypeList = ({ productType }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [editOpen, setEditOpen] = useState(false)
    const handleEditOpen = () => {
        dispatch(getOneProductType(productType.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }
    return (
        <>
            <TableRow>
                <TableCell style={{ width: '100px' }}>
                    <img src={productType.picLink} alt="pic" width="100%" />
                </TableCell>
                <TableCell>{productType.name}</TableCell>
                <TableCell style={{ width: '30px' }}>
                    <Tooltip title="Edit" aria-label="Edit">
                        <Fab
                            color="primary"
                            size="small"
                            className={classes.fab}
                            onClick={handleEditOpen}
                        >
                            <EditIcon />
                        </Fab>
                    </Tooltip>
                </TableCell>
                <TableCell style={{ width: '30px' }}>
                    {productType.products && productType.products.length === 0 && (
                        <Tooltip title="Delete" aria-label="Delete">
                            <Fab
                                color="secondary"
                                size="small"
                                className={classes.fab}
                                onClick={() => {
                                    if (window.confirm(DELETE_CONFIRM)) {
                                        dispatch(
                                            deleteProductType(productType.id)
                                        )
                                    }
                                }}
                            >
                                <DeleteOutlineIcon />
                            </Fab>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
            <Dialog
                open={editOpen}
                onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditProductType handleDialogClose={handleEditClose} />
            </Dialog>
        </>
    )
}

export default ProductTypeList
