import { useEffect } from 'react'
import { Button, Grid, TextField } from '@material-ui/core'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { SEARCH_USERS } from '../../store/types'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },
}))

const SearchUser = ({ displayKeyword }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        setKeyword(displayKeyword ?? '')
    }, [displayKeyword])
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_USERS,
            payload: {
                keyword,
            },
        })
    }
    return (
        <div className={classes.margin}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            id="keyword"
                            label="Search..."
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.marginLeft}
                            type="submit"
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default SearchUser
