import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import {
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
    DELETE_CONFIRM,
    EMPTY_CHILDREN,
    ITEM_HEIGHT,
} from '../../constant/default'
import EditStyleColor from './EditStyleColor'
import {
    deleteStyleColor,
    getOneStyleColor,
} from '../../store/actions/styleColorActions'

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    styleHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    styleControl: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

export default function StyleColorList({ styleColor }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleEditOpen = () => {
        dispatch(getOneStyleColor(styleColor.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    return (
        <>
            <ListItem alignItems="flex-start" divider={true}>
                <img src={styleColor.colorIcon} alt="color icon" width="50px" />
                <div style={{ flex: 1, marginLeft: '1rem' }}>
                    <div>
                        <h5>{styleColor.colorName}</h5>
                    </div>
                    <div
                        className="color-icon"
                        style={{ backgroundColor: styleColor.colorNumber }}
                    ></div>
                </div>
                <div>
                    <IconButton
                        color="secondary"
                        aria-label="more"
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <SettingsEthernetIcon color="secondary" />
                    </IconButton>
                    <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem onClick={handleEditOpen}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </MenuItem>
                        {styleColor.productcolors &&
                            styleColor.productcolors.length ===
                                EMPTY_CHILDREN && (
                                <MenuItem
                                    onClick={() => {
                                        if (window.confirm(DELETE_CONFIRM)) {
                                            dispatch(
                                                deleteStyleColor(styleColor.id)
                                            )
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteForeverIcon
                                            className={classes.deleteIcon}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Delete"
                                        className={classes.deleteIcon}
                                    />
                                </MenuItem>
                            )}
                    </Menu>
                </div>
            </ListItem>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditStyleColor handleDialogClose={handleEditClose} />
            </Dialog>
        </>
    )
}
