export const measureInchToMm = (inch) => {
    return parseInt(inch * 2.54 * 10) + 'mm'
}

export const getSize = (x, y) => {
    let result = ''
    for (let i = 0; i < y.length; i++) {
        const rangeArr = y[i].sizeValue.match(/\d+/g)
        if (x >= parseInt(rangeArr[0]) && x <= parseInt(rangeArr[1])) {
            result = y[i]
            break
        }
    }
    return result
}

export const orderStatusList = [
    { id: 1, status: 'New', color: '#e6ff99' },
    { id: 2, status: 'Confirmed', color: '#ffcc99' },
    { id: 3, status: 'Shipped', color: '#ffffcc' },
    { id: 4, status: 'Ready to Pick Up', color: '#ffffcc' },
    { id: 5, status: 'Finished', color: '#99ccff' },
    { id: 8, status: 'Cancelled', color: '#ff99cc' },
    { id: 9, status: 'Refund', color: '#cccccc' },
]

export const getOrderStatus = (status) => {
    return orderStatusList.filter((list) => list.id === status)[0]
}
