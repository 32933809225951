import { errorsReturn } from '../errorsReturn'
import { GET_ALL_CLIENTS } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { USER_UPDATE_SUCCESSSFULLY } from '../../constant/default'
import { logout, renewUserToken } from './userActions'

export const getAllClients =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.get(
                `client/client/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_CLIENTS,
                payload: {
                    clients: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateOneClient = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken, page, keyword },
        } = getState()
        let res = await request.put(
            `client/client/update/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(createMessage(USER_UPDATE_SUCCESSSFULLY))
        dispatch(getAllClients(keyword, page))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
