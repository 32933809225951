import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Grid,
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AdjustIcon from '@material-ui/icons/Adjust'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DELETE_CONFIRM, ITEM_HEIGHT } from '../../constant/default'
import { orange } from '@material-ui/core/colors'
import {
    deletePicturePointItem,
    getOnePicturePointItem,
} from '../../store/actions/picturePointItemActions'
import EditPicturePointItem from './EditPicturePointItem'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
        marginBottom: 12,
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
}))

const PicturePointItemList = ({ picturePointItem, picturePointId }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = (id) => {
        dispatch(getOnePicturePointItem(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Grid container alignItems="center">
                <Grid
                    item
                    xs={2}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <AdjustIcon style={{ color: orange[500] }} />
                </Grid>
                <Grid item xs={6}>
                    {picturePointItem.pointName}
                </Grid>
                <Grid
                    item
                    xs={4}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <>
                        <IconButton
                            aria-label="more"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={() =>
                                    handleEditOpen(picturePointItem.id)
                                }
                            >
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    if (window.confirm(DELETE_CONFIRM)) {
                                        dispatch(
                                            deletePicturePointItem(
                                                picturePointItem.id,
                                                picturePointId
                                            )
                                        )
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteForeverIcon
                                        className={classes.deleteIcon}
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Delete"
                                    className={classes.deleteIcon}
                                />
                            </MenuItem>
                        </Menu>
                    </>
                </Grid>
            </Grid>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditPicturePointItem
                    handleDialogClose={handleEditClose}
                    picturePointId={picturePointId}
                />
            </Dialog>
        </>
    )
}

export default PicturePointItemList
