import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import CabinetOptionForm from './CabinetOptionForm'

const AddCabinetOption = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Cabinet Option</DialogTitle>
        <CabinetOptionForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddCabinetOption
