import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
    Dialog,
    Divider,
    Fab,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { deleteStyle, getOneStyle } from '../../store/actions/styleActions'
import EditStyle from './EditStyle'
import { EMPTY_CHILDREN } from '../../constant/default'
import AddStyleColor from '../categoryStyleColor/AddStyleColor'
import StyleColorList from '../categoryStyleColor/StyleColorList'
import SettingsIcon from '@material-ui/icons/Settings'

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    styleHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    styleControl: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

const ITEM_HEIGHT = 48

const StyleList = ({ style }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [styleColorOpen, setStyleColorOpen] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleEditOpen = () => {
        dispatch(getOneStyle(style.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleStyleColorOpen = () => {
        setStyleColorOpen(true)
    }

    const handleStyleColorClose = () => {
        setStyleColorOpen(false)
    }

    return (
        <>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div className={classes.styleHeader}>
                        <div>
                            <img
                                src={style.iconLink}
                                alt="icon"
                                style={{ width: '100px' }}
                            />
                        </div>
                        <div>
                            <h3>{style.name}</h3>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div className={classes.styleControl}>
                            <IconButton
                                color="primary"
                                aria-label="more"
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <SettingsIcon color="secondary" />
                            </IconButton>
                            <Menu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem onClick={handleEditOpen}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                </MenuItem>
                                {style.stylecolors &&
                                    style.stylecolors.length ===
                                        EMPTY_CHILDREN && (
                                        <MenuItem
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'are you sure?'
                                                    )
                                                ) {
                                                    dispatch(
                                                        deleteStyle(style.id)
                                                    )
                                                }
                                            }}
                                        >
                                            <ListItemIcon>
                                                <DeleteForeverIcon
                                                    className={
                                                        classes.deleteIcon
                                                    }
                                                    fontSize="small"
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Delete"
                                                className={classes.deleteIcon}
                                            />
                                        </MenuItem>
                                    )}
                            </Menu>
                        </div>
                        <div>
                            <img
                                src={style.picLink}
                                alt="stylePic"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div>
                            <p style={{ whiteSpace: 'pre-line' }}>
                                {style.description}
                            </p>
                        </div>
                        <Divider />
                        <div>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                <h4>Style Colors:</h4>
                                <Tooltip title="Add" aria-label="add">
                                    <Fab
                                        color="primary"
                                        size="small"
                                        className={classes.fab}
                                        onClick={handleStyleColorOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        </div>
                        <div>
                            {style.stylecolors &&
                                style.stylecolors.length > 0 &&
                                style.stylecolors.map((styleColor) => (
                                    <StyleColorList
                                        styleColor={styleColor}
                                        key={styleColor.id}
                                    />
                                ))}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditStyle handleDialogClose={handleEditClose} />
            </Dialog>
            <Dialog
                open={styleColorOpen}
                // onClose={handleDialogClose}
                aria-labelledby="form-dialog-style"
            >
                <AddStyleColor
                    handleDialogClose={handleStyleColorClose}
                    styleId={style.id}
                />
            </Dialog>
        </>
    )
}

export default StyleList
