import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import PickUpLocationForm from './PickUpLocationForm'

const EditPickUpLocation = ({ handleDialogClose }) => {
    const pickUpLocationState = useSelector((state) => state.pickUpLocation)
    const { pickUpLocation } = pickUpLocationState

    return pickUpLocation ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Location</DialogTitle>
            <PickUpLocationForm
                handleDialogClose={handleDialogClose}
                initialValues={pickUpLocation}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditPickUpLocation
