import { Button, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { clientDesignSaveType } from '../../constant/default'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonStyle: {
        margin: '20px 0',
    },
    gridStyle: {
        margin: '10px 0',
    },
}))

const ClientDesignSaveInfo = ({ clientDesignSave, handleClose }) => {
    const classes = useStyles()

    return (
        <Container>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Type
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {
                            clientDesignSaveType.filter(
                                (list) => list.id === clientDesignSave.type
                            )[0].name
                        }
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        ID
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.saveId}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Link
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        <a
                            href={clientDesignSave.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            URL
                        </a>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Name
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Email
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.email}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        First Name
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.firstName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Last Name
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.lastName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Phone
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.phone}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Address
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.address}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        City
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.city}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Province
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.state}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Post Code
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.postCode}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Kitchen Renovation
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.kitchenRenovation}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Appointment
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {clientDesignSave.appointment}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Comments
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {clientDesignSave.comments}
                        </div>
                    </Typography>
                </Grid>
            </Grid>
            {clientDesignSave.shareemail && (
                <Grid
                    container
                    alignItems="center"
                    className={classes.gridStyle}
                >
                    <Grid item xs={4}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            color="primary"
                        >
                            Share email
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" gutterBottom>
                            {clientDesignSave.shareemail}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {clientDesignSave.from && (
                <Grid
                    container
                    alignItems="center"
                    className={classes.gridStyle}
                >
                    <Grid item xs={4}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            color="primary"
                        >
                            From
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" gutterBottom>
                            {clientDesignSave.from}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {clientDesignSave.firstnameLabel && (
                <Grid
                    container
                    alignItems="center"
                    className={classes.gridStyle}
                >
                    <Grid item xs={4}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            color="primary"
                        >
                            First name label
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" gutterBottom>
                            {clientDesignSave.firstnameLabel}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid className={classes.buttonStyle}>
                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleClose(false)}
                >
                    Close
                </Button>
            </Grid>
        </Container>
    )
}

export default ClientDesignSaveInfo
