import { GET_ALL_CATEGORIES, GET_ONE_CATEGORY } from '../types'

const initialState = {
    categories: [],
    category: {},
}

export const categoryReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_CATEGORIES:
            return {
                ...state,
                categories: payload,
            }
        case GET_ONE_CATEGORY:
            return {
                ...state,
                category: payload,
            }
        default:
            return state
    }
}

export default categoryReducer
