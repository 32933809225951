import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Button,
    Container,
    Dialog,
    Paper,
    Table,
    TableBody,
    TableContainer,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Layout from '../components/Layout'
import { getAllPicturePositions } from '../store/actions/picturePositionActions'
import PicturePositionList from '../components/picturePosition/PicturePositionList'
import AddPicturePosition from '../components/picturePosition/AddPicturePosition'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 200,
    },
    tableHeaderCell: {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}))

const PicturePositions = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const picturePositionState = useSelector((state) => state.picturePosition)
    const { picturePositions } = picturePositionState

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        dispatch(getAllPicturePositions())
    }, [dispatch])

    return (
        <Layout>
            <Container component="main" maxWidth="xs">
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Position
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            {picturePositions &&
                                picturePositions.map((picturePosition) => (
                                    <PicturePositionList
                                        picturePosition={picturePosition}
                                        key={picturePosition.id}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddPicturePosition handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default PicturePositions
