import { DialogTitle } from '@material-ui/core'
import moment from 'moment'
import { FORM_TYPE_EDIT } from '../../constant/default'
import PaymentForm from './PaymentForm'

const EditPayment = ({
    handleDialogClose,
    orderPayment,
    orderId,
    invoiceId,
}) => {
    orderPayment.paymentDate = moment(orderPayment.paymentDate).format(
        'YYYY-MM-DD'
    )
    return orderPayment ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Payment</DialogTitle>
            <PaymentForm
                handleDialogClose={handleDialogClose}
                initialValues={orderPayment}
                formType={FORM_TYPE_EDIT}
                orderId={orderId}
                invoiceId={invoiceId}
            />
        </>
    ) : null
}

export default EditPayment
