import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import ProductFunctionForm from './ProductFunctionForm'

const EditProductFunction = ({ handleDialogClose }) => {
    const productFunctionState = useSelector((state) => state.productFunction)
    const { productFunction } = productFunctionState

    return productFunction ? (
        <>
            <DialogTitle id="form-dialog-title">
                Edit Product Function
            </DialogTitle>
            <ProductFunctionForm
                handleDialogClose={handleDialogClose}
                initialValues={productFunction}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditProductFunction
