import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Dialog } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Layout from '../components/Layout'
import { getAllCategories } from '../store/actions/categoryActions'
import CategoryList from '../components/category/CategoryList'
import AddCategory from '../components/category/AddCategory'
import Masonry from 'react-masonry-css'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}))

const Categories = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const categoryState = useSelector((state) => state.category)
    const { categories } = categoryState

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        dispatch(getAllCategories())
    }, [dispatch])

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1,
        500: 1,
    }

    return (
        <Layout>
            <Container>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Category
                    </Button>
                </Box>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {/* array of JSX items */}
                    {categories &&
                        categories.map((category) => (
                            <div key={category.id}>
                                <CategoryList category={category} />
                            </div>
                        ))}
                </Masonry>
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddCategory handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default Categories
