import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { fildInput } from '../../module/inputForm'
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core'
import {
    requestDesignDetailCreate,
    requestDesignDetailUpdate,
} from '../../store/actions/clientRequestDesignActions'

const validateForm = (values) => {
    const errors = {}

    if (!values.ceilingHeight) {
        errors.ceilingHeight = 'Required'
    }

    if (values.bulkhead === 'Yes') {
        if (!values.bulkheadDepth) {
            errors.bulkheadDepth = 'Required'
        }
        if (!values.bulkheadHeight) {
            errors.bulkheadHeight = 'Required'
        }
    }

    if (values.rangeSize === 'Other') {
        if (!values.rangeSizeOther) {
            errors.rangeSizeOther = 'Required'
        }
    }

    if (values.rangeHood === 'Other') {
        if (!values.rangeHoodOther) {
            errors.rangeHoodOther = 'Required'
        }
    }
    if (values.dishwasherSize === 'Other') {
        if (!values.dishwasherSizeOther) {
            errors.dishwasherSizeOther = 'Required'
        }
    }
    if (values.dishwasherLocation === 'Other') {
        if (!values.dishwasherLocationOther) {
            errors.dishwasherLocationOther = 'Required'
        }
    }
    if (values.sink === 'Other') {
        if (!values.sinkOther) {
            errors.sinkOther = 'Required'
        }
    }
    if (values.upperWallCabinetHeight === 'Other') {
        if (!values.upperWallCabinetHeightOther) {
            errors.upperWallCabinetHeightOther = 'Required'
        }
    }
    if (values.wallCabinet === 'Other') {
        if (!values.wallCabinetOther) {
            errors.wallCabinetOther = 'Required'
        }
    }
    return errors
}

const RequestDesignDetailForm = ({ requestDesign, editType }) => {
    let editValues =
        editType === 'edit'
            ? {
                  ...requestDesign.requestdesigndetail,
                  street: requestDesign.street,
                  suit: requestDesign.suit,
                  city: requestDesign.city,
                  province: requestDesign.province,
              }
            : null

    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues:
            editType === 'edit'
                ? editValues
                : {
                      street: '',
                      suit: '',
                      city: '',
                      province: '',
                      ceilingHeight: '',
                      bulkhead: '',
                      bulkheadDepth: '',
                      bulkheadHeight: '',
                      range: '',
                      rangeSize: '',
                      rangeSizeOther: '',
                      rangeType: '',
                      rangeHood: '',
                      rangeHoodOther: '',
                      refrigeratorWidth: '',
                      refrigeratorDepth: '',
                      refrigeratorHeight: '',
                      microwave: '',
                      microwaveWidth: '',
                      microwaveDepth: '',
                      microwaveHeight: '',
                      dishwasherSize: '',
                      dishwasherSizeOther: '',
                      dishwasherLocation: '',
                      dishwasherLocationOther: '',
                      sinkWidth: '',
                      sinkDepth: '',
                      sink: '',
                      sinkOther: '',
                      handles: '',
                      cabinetStyle: '',
                      upperWallCabinetHeight: '',
                      upperWallCabinetHeightOther: '',
                      cabinetsStorage: '',
                      baseStorage: '',
                      wallCabinet: '',
                      wallCabinetOther: '',
                      trim: '',
                      note: '',
                  },
        validate: validateForm,
        onSubmit: (values) => {
            values['requestDesignId'] = requestDesign.id
            if (values.bulkhead !== 'Yes') {
                values['bulkheadDepth'] = ''
                values['bulkheadHeight'] = ''
            }
            if (values.rangeSize !== 'Other') {
                values['rangeSizeOther'] = ''
            }
            if (values.rangeHood !== 'Other') {
                values['rangeHoodOther'] = ''
            }
            if (values.dishwasherSize !== 'Other') {
                values['dishwasherSizeOther'] = ''
            }
            if (values.dishwasherLocation !== 'Other') {
                values['dishwasherLocationOther'] = ''
            }
            if (values.sink !== 'Other') {
                values['sinkOther'] = ''
            }
            if (values.upperWallCabinetHeight !== 'Other') {
                values['upperWallCabinetHeightOther'] = ''
            }
            if (values.wallCabinet !== 'Other') {
                values['wallCabinetOther'] = ''
            }

            if (editType === 'create') {
                dispatch(requestDesignDetailCreate(values))
            } else if (editType === 'edit') {
                values['id'] = editValues.id
                dispatch(requestDesignDetailUpdate(values))
            }
        },
    })
    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Project Address" />
                    <CardContent>
                        {fildInput('street', 'Street', 'text', formik)}
                        {fildInput('suit', 'Suit', 'text', formik)}
                        {fildInput('city', 'City', 'text', formik)}
                        {fildInput('province', 'Province', 'text', formik)}
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Existing Construction" />
                    <CardContent>
                        {fildInput(
                            'ceilingHeight',
                            'Ceiling Height',
                            'text',
                            formik
                        )}
                        <div style={{ padding: '10px' }}></div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Bulkhead Height
                            </FormLabel>
                            <RadioGroup
                                name="bulkhead"
                                value={formik.values.bulkhead}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'bulkhead',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                />
                                {formik.values.bulkhead === 'Yes' && (
                                    <div>
                                        {fildInput(
                                            'bulkheadDepth',
                                            'Bulkhead Depth',
                                            'text',
                                            formik
                                        )}
                                        {fildInput(
                                            'bulkheadHeight',
                                            'Bulkhead Height',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                                <FormControlLabel
                                    value="None"
                                    control={<Radio />}
                                    label="None"
                                />
                                <FormControlLabel
                                    value="To be removed"
                                    control={<Radio />}
                                    label="To be removed"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>

                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Range / Oven" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="range"
                                value={formik.values.range}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'range',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Electric"
                                    control={<Radio />}
                                    label="Electric"
                                />

                                <FormControlLabel
                                    value="Gase"
                                    control={<Radio />}
                                    label="Gase"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="rangeSize"
                                value={formik.values.rangeSize}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'rangeSize',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="30inch Standard"
                                    control={<Radio />}
                                    label="30inch Standard"
                                />

                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    label="Other"
                                />
                                {formik.values.rangeSize === 'Other' && (
                                    <div>
                                        {fildInput(
                                            'rangeSizeOther',
                                            'Range Size Other',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="rangeType"
                                value={formik.values.rangeType}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'rangeType',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Freestanding"
                                    control={<Radio />}
                                    label="Freestanding"
                                />

                                <FormControlLabel
                                    value="Slide-In"
                                    control={<Radio />}
                                    label="Slide-In"
                                />
                                <FormControlLabel
                                    value="Cooktop / Wall Oven"
                                    control={<Radio />}
                                    label="Cooktop / Wall Oven"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Range Hood / Ventilation" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="rangeHood"
                                value={formik.values.rangeHood}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'rangeHood',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Under Cabinet"
                                    control={<Radio />}
                                    label="Under Cabinet"
                                />
                                <FormControlLabel
                                    value="Chimney Hood"
                                    control={<Radio />}
                                    label="Chimney Hood"
                                />
                                <FormControlLabel
                                    value="OTR Micromave"
                                    control={<Radio />}
                                    label="OTR Micromave"
                                />
                                <FormControlLabel
                                    value="Wood Hood"
                                    control={<Radio />}
                                    label="Wood Hood"
                                />
                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    label="Other"
                                />
                                {formik.values.rangeHood === 'Other' && (
                                    <div>
                                        {fildInput(
                                            'rangeHoodOther',
                                            'Range Hood Other',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Refrigerator" />
                    <CardContent>
                        {fildInput(
                            'refrigeratorWidth',
                            'Width',
                            'text',
                            formik
                        )}
                        {fildInput(
                            'refrigeratorDepth',
                            'Depth',
                            'text',
                            formik
                        )}
                        {fildInput(
                            'refrigeratorHeight',
                            'Height',
                            'text',
                            formik
                        )}
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Microwave" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="microwave"
                                value={formik.values.microwave}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'microwave',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="On Countertop"
                                    control={<Radio />}
                                    label="On Countertop"
                                />
                                <FormControlLabel
                                    value="OTR Micromave"
                                    control={<Radio />}
                                    label="OTR Micromave"
                                />
                                <FormControlLabel
                                    value="Inside a base cabinet"
                                    control={<Radio />}
                                    label="Inside a base cabinet"
                                />
                                <FormControlLabel
                                    value="Inside a wall cabinet"
                                    control={<Radio />}
                                    label="Inside a wall cabinet"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                    <CardContent>
                        {fildInput('microwaveWidth', 'Width', 'text', formik)}
                        {fildInput('microwaveDepth', 'Depth', 'text', formik)}
                        {fildInput('microwaveHeight', 'Height', 'text', formik)}
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Dishwasher" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="dishwasherSize"
                                value={formik.values.dishwasherSize}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'dishwasherSize',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="30inch Standard"
                                    control={<Radio />}
                                    label="30inch Standard"
                                />

                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    label="Other"
                                />
                                {formik.values.dishwasherSize === 'Other' && (
                                    <div>
                                        {fildInput(
                                            'dishwasherSizeOther',
                                            'Other Size',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="dishwasherLocation"
                                value={formik.values.dishwasherLocation}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'dishwasherLocation',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Next to sink"
                                    control={<Radio />}
                                    label="Next to sink"
                                />

                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    label="Other"
                                />
                                {formik.values.dishwasherLocation ===
                                    'Other' && (
                                    <div>
                                        {fildInput(
                                            'dishwasherLocationOther',
                                            'Other Location',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Sink" />
                    <CardContent>
                        {fildInput('sinkWidth', 'Width', 'text', formik)}
                        {fildInput('sinkDepth', 'Depth', 'text', formik)}
                    </CardContent>
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="sink"
                                value={formik.values.sink}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'sink',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Equal Double"
                                    control={<Radio />}
                                    label="Equal Double"
                                />
                                <FormControlLabel
                                    value="Single Bowl"
                                    control={<Radio />}
                                    label="Single Bowl"
                                />
                                <FormControlLabel
                                    value="Apron / Farm Sink"
                                    control={<Radio />}
                                    label="Apron / Farm Sink"
                                />
                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    label="Other"
                                />
                                {formik.values.sink === 'Other' && (
                                    <div>
                                        {fildInput(
                                            'sinkOther',
                                            'Sink Other',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Handles / Knobs" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="handles"
                                value={formik.values.handles}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'handles',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Supplied by HSpace"
                                    control={<Radio />}
                                    label="Supplied by HSpace"
                                />

                                <FormControlLabel
                                    value="Supplied by Client"
                                    control={<Radio />}
                                    label="Supplied by Client"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Cabinet Style & Height" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="cabinetStyle"
                                value={formik.values.cabinetStyle}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'cabinetStyle',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Shaker White"
                                    control={<Radio />}
                                    label="Shaker White"
                                />

                                <FormControlLabel
                                    value="Shaker Grey"
                                    control={<Radio />}
                                    label="Shaker Grey"
                                />
                                <FormControlLabel
                                    value="Willow Grey"
                                    control={<Radio />}
                                    label="Willow Grey"
                                />
                                <FormControlLabel
                                    value="Style 4"
                                    control={<Radio />}
                                    label="Style 4"
                                />
                                <FormControlLabel
                                    value="Style 5"
                                    control={<Radio />}
                                    label="Style 5"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                    <CardContent>
                        <FormControl component="fieldset">
                            <FormLabel id="upperWallCabinetHeight">
                                Upper Wall Cabinet Height
                            </FormLabel>
                            <RadioGroup
                                name="upperWallCabinetHeight"
                                value={formik.values.upperWallCabinetHeight}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'upperWallCabinetHeight',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="30inch"
                                    control={<Radio />}
                                    label="30inch"
                                />
                                <FormControlLabel
                                    value="36inch"
                                    control={<Radio />}
                                    label="36inch"
                                />
                                <FormControlLabel
                                    value="42inch"
                                    control={<Radio />}
                                    label="42inch"
                                />
                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    label="Other"
                                />
                                {formik.values.upperWallCabinetHeight ===
                                    'Other' && (
                                    <div>
                                        {fildInput(
                                            'upperWallCabinetHeightOther',
                                            'Other',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Cabinets & Storage Options" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="cabinetsStorage"
                                value={formik.values.cabinetsStorage}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'cabinetsStorage',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Spice Pull"
                                    control={<Radio />}
                                    label="Spice Pull"
                                />

                                <FormControlLabel
                                    value="Pantry"
                                    control={<Radio />}
                                    label="Pantry"
                                />
                                <FormControlLabel
                                    value="Wine Rack"
                                    control={<Radio />}
                                    label="Wine Rack"
                                />
                                <FormControlLabel
                                    value="Waste Bin"
                                    control={<Radio />}
                                    label="Waste Bin"
                                />
                                <FormControlLabel
                                    value="Open Shelves"
                                    control={<Radio />}
                                    label="Open Shelves"
                                />
                                <FormControlLabel
                                    value="Lazy Susan Base"
                                    control={<Radio />}
                                    label="Lazy Susan Base"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                    <CardContent>
                        <FormControl component="fieldset">
                            <FormLabel id="upperWallCabinetHeight">
                                Preference for base storage
                            </FormLabel>
                            <RadioGroup
                                name="baseStorage"
                                value={formik.values.baseStorage}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'baseStorage',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Doors"
                                    control={<Radio />}
                                    label="Doors"
                                />
                                <FormControlLabel
                                    value="Drawers"
                                    control={<Radio />}
                                    label="Drawers"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                    <CardContent>
                        <FormControl component="fieldset">
                            <FormLabel id="upperWallCabinetHeight">
                                Preference for wall cabinet
                            </FormLabel>
                            <RadioGroup
                                name="wallCabinet"
                                value={formik.values.wallCabinet}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'wallCabinet',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Standard wood doors"
                                    control={<Radio />}
                                    label="Standard wood doors"
                                />
                                <FormControlLabel
                                    value="Glass Doors"
                                    control={<Radio />}
                                    label="Glass Doors"
                                />
                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    label="Other"
                                />
                                {formik.values.wallCabinet === 'Other' && (
                                    <div>
                                        {fildInput(
                                            'wallCabinetOther',
                                            'Other',
                                            'text',
                                            formik
                                        )}
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Trim & Moldings" />
                    <CardContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="trim"
                                value={formik.values.trim}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'trim',
                                        event.currentTarget.value
                                    )
                                }}
                            >
                                <FormControlLabel
                                    value="Crown to Ceiling"
                                    control={<Radio />}
                                    label="Crown to Ceiling"
                                />

                                <FormControlLabel
                                    value="Riser to Ceiling"
                                    control={<Radio />}
                                    label="Riser to Ceiling"
                                />
                                <FormControlLabel
                                    value="Open space above"
                                    control={<Radio />}
                                    label="Open space above"
                                />
                                <FormControlLabel
                                    value="Curved Valance"
                                    control={<Radio />}
                                    label="Curved Valance"
                                />
                                <FormControlLabel
                                    value="Light rail molding"
                                    control={<Radio />}
                                    label="Light rail molding"
                                />
                                <FormControlLabel
                                    value="Baseboard molding"
                                    control={<Radio />}
                                    label="Baseboard molding"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card style={{ margin: '20px 0' }}>
                    <CardHeader title="Extra Notes" />
                    <CardContent>
                        <TextField
                            fullWidth
                            id="note"
                            name="note"
                            label="Note"
                            variant="filled"
                            multiline
                            rows={4}
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            helperText={
                                formik.touched.note && formik.errors.note
                            }
                        />
                    </CardContent>
                </Card>
                <div style={{ padding: '20px 0' }}>
                    <Button type="submit" color="primary" variant="contained">
                        Continue
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default RequestDesignDetailForm
