import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../components/Copyright'
import { login } from '../store/actions/userActions'
import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../components/ReduxFormInputs'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

function SignIn(props) {
    const classes = useStyles()
    const { handleSubmit } = props
    const history = useHistory()

    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.user)

    const { userInfo } = userLogin

    useEffect(() => {
        if (userInfo) {
            history.push('/')
        }
    }, [history, userInfo])

    const submitForm = (formValues) => {
        // console.log(formValues)
        dispatch(login(formValues))
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                {/* <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar> */}
                <img
                    src="https://res.cloudinary.com/homespace/image/upload/v1622233552/logo/BLACK_YELLOW_LOGO_s1uomq.png"
                    height="80px"
                    alt="logo"
                    style={{ marginBottom: '20px' }}
                />
                {/* <Typography component="h1" variant="h5">
                    HS-Online.com Admin
                </Typography> */}
                <form
                    onSubmit={handleSubmit(submitForm)}
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                >
                    <Field
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        component={renderTextField}
                    />
                    <Field
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        component={renderTextField}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'password']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

export default reduxForm({
    form: 'loginForm',
    validate,
})(SignIn)
