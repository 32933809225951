import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_PRODUCTCOLORS,
    GET_ALL_SKUNO,
    GET_ONE_PRODUCTCOLOR,
} from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { logout, renewUserToken } from './userActions'
import {
    PRODUCTCOLOR_ADD_SUCCESSFULLY,
    PRODUCTCOLOR_UPDATE_SUCCESSFULLY,
    PRODUCTCOLOR_DELETE_SUCCESSFULLY,
    PRODUCTCOLOR_UPDATESTOCKAPI_SUCCESSFULLY,
} from '../../constant/default'
import { getOneProduct } from './productActions'

export const getALLProductColors =
    (keyword = '', searchOnline = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.get(
                `productcolor/listall?keyword=${keyword}&searchOnline=${searchOnline}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_PRODUCTCOLORS,
                payload: {
                    productColors: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                    searchOnline: searchOnline,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const getAllSkuNos = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`productcolor/getallskunos`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ALL_SKUNO,
            payload: {
                allSkuNos: res.data.data.allSkuNos,
                doneSkuNos: res.data.data.doneSkuNos,
            },
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateStocks = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`productcolor/updatestock`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PRODUCTCOLOR_UPDATESTOCKAPI_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getALLProductColors())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOneProductColor = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`productcolor/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_PRODUCTCOLOR, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const createProductColor = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.post(`productcolor/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PRODUCTCOLOR_ADD_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getOneProduct(values.productId))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateProductColor = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.put(
            `productcolor/update/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PRODUCTCOLOR_UPDATE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getOneProduct(values.productId))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

//

export const changeOnlineStatus = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
            productColor: { keyword },
        } = getState()
        let res = await request.put(`productcolor/changeonlinestatus`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PRODUCTCOLOR_UPDATE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getALLProductColors(keyword))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteProductColor =
    (id, productId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.delete(`productcolor/delete/${id}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(createMessage(PRODUCTCOLOR_DELETE_SUCCESSFULLY))
            dispatch(controlLoading(false))
            dispatch(getOneProduct(productId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
