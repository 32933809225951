import {
    CHANGE_PRICE_MATCH_PAGE,
    GET_ALL_PRICE_MATCH,
    GET_ONE_PRICE_MATCH,
    SEARCH_PRICE_MATCH,
} from '../types'

const initialState = {
    priceMatchs: [],
    priceMatch: {},
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

const priceMatchReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRICE_MATCH:
            return {
                ...state,
                priceMatchs: payload.priceMatchs,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
            }
        case SEARCH_PRICE_MATCH:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_PRICE_MATCH_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_PRICE_MATCH:
            return {
                ...state,
                priceMatch: payload,
            }
        default:
            return state
    }
}

export default priceMatchReducer
