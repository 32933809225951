import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Dialog } from '@material-ui/core'
import Layout from '../components/Layout'
import AddIcon from '@material-ui/icons/Add'
import HeadOfficeList from '../components/HeadOffice/HeadOfficeList'
import AddHeadOffice from '../components/HeadOffice/AddHeadOffice'
import { getAllHeadOffices } from '../store/actions/headOfficeActions'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(3),
    },
}))

const HeadOffices = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const headOfficeState = useSelector((state) => state.headOffice)
    const { headOffices } = headOfficeState

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        dispatch(getAllHeadOffices())
    }, [dispatch])
    return (
        <Layout>
            <Container>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Head Office
                    </Button>
                </Box>
                {headOffices &&
                    headOffices.map((headOffice) => (
                        <HeadOfficeList
                            headOffice={headOffice}
                            key={headOffice.id}
                        />
                    ))}
                <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
                    <AddHeadOffice handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default HeadOffices
