import {
    GET_ALL_CLIENTS,
    SEARCH_CLIENTS,
    CHANGE_CLIENTS_PAGE,
    GET_ONE_CLIENT,
} from '../types'

const initialState = {
    clients: [],
    client: '',
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export const clientReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_CLIENTS:
            return {
                ...state,
                clients: payload.clients,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                client: null,
            }
        case SEARCH_CLIENTS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_CLIENTS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_CLIENT:
            return {
                ...state,
                client: payload,
            }
        default:
            return state
    }
}
