import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import CabinetOptionForm from './CabinetOptionForm'

const EditCabinetOption = ({ handleDialogClose }) => {
    const cabinetOptionState = useSelector((state) => state.cabinetOption)
    const { cabinetOption } = cabinetOptionState

    return cabinetOption ? (
        <>
            <DialogTitle id="form-dialog-title">
                Edit Cabinet Option
            </DialogTitle>
            <CabinetOptionForm
                handleDialogClose={handleDialogClose}
                initialValues={cabinetOption}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditCabinetOption
