import { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Container,
    Grid,
    Typography,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { userRoleList } from '../../constant/default'
import { updateOneUser } from '../../store/actions/userActions'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonStyle: {
        margin: '20px 0',
    },
    gridStyle: {
        margin: '10px 0',
    },
}))

const ModalInfo = ({ user, handleClose }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isActive, setIsActive] = useState(false)
    const [newrole, setNewrole] = useState('')

    useEffect(() => {
        setIsActive(user.isActive)
        setNewrole(user.role)
    }, [user])

    const handleConfirm = (e) => {
        setIsActive(!isActive)
    }

    const handleSubmit = () => {
        const values = { ...user, role: newrole, isActive: isActive }
        dispatch(updateOneUser(values))
        handleClose(false)
    }

    return (
        <Container>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Email
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {user.email}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        First Name
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {user.firstName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Last Name
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {user.lastName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Phone
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {user.phone}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom color="primary">
                        Address
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                        {user.address}
                    </Typography>
                </Grid>
            </Grid>
            <form>
                <Grid
                    container
                    alignItems="center"
                    className={classes.gridStyle}
                >
                    <Grid item xs={4}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            color="primary"
                        >
                            Active
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Checkbox
                            defaultChecked={user.isActive}
                            onChange={handleConfirm}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.gridStyle}
                >
                    <Grid item xs={4}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            color="primary"
                        >
                            Role
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={newrole}
                                onChange={(e) => setNewrole(e.target.value)}
                            >
                                {userRoleList.map((role) => (
                                    <MenuItem
                                        key={role.value}
                                        value={role.value}
                                    >
                                        {role.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className={classes.buttonStyle}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleSubmit()}
                        style={{ marginRight: '10px' }}
                    >
                        Update
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleClose(false)}
                    >
                        Close
                    </Button>
                </Grid>
            </form>
        </Container>
    )
}

export default ModalInfo
