import { GET_ONE_PRODUCTMEASURE } from '../types'

const initialState = {
    productMeasure: {},
}

export const productMeasureReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ONE_PRODUCTMEASURE:
            return {
                ...state,
                productMeasure: payload,
            }
        default:
            return state
    }
}

export default productMeasureReducer
