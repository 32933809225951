const Test = () => {
    const y = [
        { sizeValue: '12 - 13', id: '11223xdrf' },
        { sizeValue: '14 - 20', id: '22323xdrf' },
        { sizeValue: '21 - 25', id: '33122xdrf' },
        { sizeValue: '26 - 30', id: '55623xdrf' },
    ]
    const x = 15
    const getId = (x, y) => {
        let result = ''
        for (let i = 0; i < y.length; i++) {
            const rangeArr = y[i].sizeValue.match(/\d+/g)
            if (x >= parseInt(rangeArr[0]) && x <= parseInt(rangeArr[1])) {
                result = y[i].id
                break
            }
        }
        return result
    }

    // y.forEach((range) => {
    //     const rangeArr = range.sizeValue.match(/\d+/g)
    //     console.log(rangeArr)
    //     if (x >= parseInt(rangeArr[0]) && x <= parseInt(rangeArr[1])) {
    //         result = range.id
    //         return
    //     }
    // })
    console.log(getId(x, y))
    return <div>this is test page</div>
}

export default Test
