import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import QnAItemForm from './QnAItemForm'

const EditQnAItem = ({ handleDialogClose, qnaCategoryId }) => {
    const qnaItemState = useSelector((state) => state.qnaItem)
    const { qnaItem } = qnaItemState

    return qnaItem ? (
        <>
            <DialogTitle id="form-dialog-title">
                Edit Self Assemble Process
            </DialogTitle>
            <QnAItemForm
                handleDialogClose={handleDialogClose}
                initialValues={qnaItem}
                formType={FORM_TYPE_EDIT}
                qnaCategoryId={qnaCategoryId}
            />
        </>
    ) : null
}

export default EditQnAItem
