import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { TextField } from '@material-ui/core'
import request from '../../config/request'
import { errorsReturn } from '../../store/errorsReturn'
import { controlLoading } from '../../store/actions/loadingActions'
import { getErrors } from '../../store/actions/errorActions'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from '../../store/actions/userActions'
import { getOneOrder } from '../../store/actions/orderActions'
import { createMessage } from '../../store/actions/messageActions'

const PromoCodeInput = ({ promoCodeName, orderId, orderStatus }) => {
    const dispatch = useDispatch()
    const [promoCodeValue, setPromoCodeValue] = useState('')
    const { userToken } = useSelector((state) => state.user)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!promoCodeValue) return false
        try {
            const values = { promoCode: promoCodeValue }
            const { data } = await request.post(
                `/client/clientpromocode/checkpromocode`,
                values
            )
            if (data.data.check) {
                const promoValues = { promoCode: promoCodeValue }
                const res = await request.put(
                    `/order/promocode/${orderId}`,
                    promoValues,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                newToken(res)
                dispatch(renewUserToken(res))
                dispatch(getOneOrder(orderId))
                dispatch(createMessage('Promo Code added successful'))
            }
            setPromoCodeValue('')
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

    const handleDelete = async () => {
        try {
            const values = { promoCode: null }

            const res = await request.put(
                `/order/promocode/${orderId}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(orderId))
            dispatch(createMessage('Promo Code deleted successful'))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
    return (
        <div className="carts-promo-code">
            <div className="carts-promo-code-left">Promo Code:</div>
            {orderStatus === 1 ? (
                <div className="carts-promo-code-right">
                    {promoCodeName ? (
                        <Chip
                            label={promoCodeName}
                            onDelete={handleDelete}
                            // color="primary"
                            variant="outlined"
                        />
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="promo-code-box">
                                <TextField
                                    placeholder="Promo Code"
                                    fullWidth
                                    className="search_bar_text"
                                    value={promoCodeValue}
                                    onChange={(e) =>
                                        setPromoCodeValue(e.target.value)
                                    }
                                />
                                <button className="promo-code-button">
                                    {'>'}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            ) : (
                <div>
                    {promoCodeName ? (
                        <Chip
                            label={promoCodeName}
                            color="primary"
                            variant="outlined"
                        />
                    ) : (
                        'NO'
                    )}
                </div>
            )}
        </div>
    )
}

export default PromoCodeInput
