import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import ProductTypeForm from './ProductTypeForm'

const AddProductType = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Product Type</DialogTitle>
        <ProductTypeForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddProductType
