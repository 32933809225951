import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Chip,
    Container,
    Divider,
    Grid,
    Dialog,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'
import Layout from '../components/Layout'
import { changeOrderStatus, getOneOrder } from '../store/actions/orderActions'
import history from '../history'
import '../components/order/orderInfo.css'
import { getOrderStatus } from '../constant/formula'
import OrderInfoItem from '../components/order/OrderInfoItem'
import { returnMethod } from '../constant/default'
import moment from 'moment'
import EditDeliveryDate from '../components/order/EditDeliveryDate'
import EditFreight from '../components/order/EditFreight'
import EditPickUpLocation from '../components/order/EditPickUpLocation'
import AddItem from '../components/order/AddItem'
import { modifyTypeName } from '../common/common'
import PromoCodeInput from '../components/order/PromoCodeInput'
import OrderInvoiceDisplay from '../components/order/OrderInvoice/OrderInvoiceDisplay'

const OrderInfo = ({ match }) => {
    const dispatch = useDispatch()
    const id = match.params.id

    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = () => {
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [pickUp, setPickUp] = useState(false)

    const handlePickUp = () => {
        setPickUp(true)
    }

    const handlePickUpClose = () => {
        setPickUp(false)
    }

    const [freightOpen, setFreightOpen] = useState(false)

    const handleFreightOpen = () => {
        setFreightOpen(true)
    }

    const handleFreightClose = () => {
        setFreightOpen(false)
    }

    const [addItemOpen, setAddItemOpen] = useState(false)

    const handleAddItemOpen = () => {
        setAddItemOpen(true)
    }

    const handleAddItemClose = () => {
        setAddItemOpen(false)
    }

    const orderState = useSelector((state) => state.order)
    const { order } = orderState

    useEffect(() => {
        dispatch(getOneOrder(id))
    }, [dispatch, id])

    const orderStatusDisplay = getOrderStatus(order?.orderStatus)
    const shippingAddress = order?.orderaddresses?.filter(
        (address) => address.addressType === 1
    )[0]
    const billingAddress = order?.orderaddresses?.filter(
        (address) => address.addressType === 2
    )[0]

    const subTotal = order?.orderitems.reduce(
        (a, b) => (a += b.quantity * b.price),
        0
    )
    const shippingFee =
        order?.ordershipping?.shippingType === 1
            ? order?.ordershipping?.price
            : 0

    const paymentAmount = useMemo(() => {
        if (order?.orderpayments?.length === 0) return 0
        return (
            order?.orderpayments?.reduce((a, b) => {
                if (b.successful) return (a += b.amount)
                return a
            }, 0) || 0
        )
    }, [order])

    const confirmButton = (
        <Grid item xs={4}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(changeOrderStatus(id, 2))}
                style={{ margin: '20px 0' }}
            >
                Confirm
            </Button>
        </Grid>
    )

    const cancelConfirmButton = (
        <Grid item xs={4}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(changeOrderStatus(id, 1))}
                style={{ margin: '20px 0' }}
            >
                Cancel Confirm
            </Button>
        </Grid>
    )

    const cancelButton = (
        <Grid item xs={4}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    if (window.confirm('are you sure?')) {
                        dispatch(changeOrderStatus(id, 8))
                    }
                }}
                style={{ margin: '20px 0' }}
            >
                Cancel
            </Button>
        </Grid>
    )

    const refundButton = (
        <Grid item xs={4}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => dispatch(changeOrderStatus(id, 9))}
                style={{ margin: '20px 0' }}
            >
                Refund
            </Button>
        </Grid>
    )

    const finishButton = (
        <Grid item xs={4}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(changeOrderStatus(id, 5))}
                style={{ margin: '20px 0' }}
            >
                Finish
            </Button>
        </Grid>
    )

    return (
        <Layout>
            {order ? (
                <Container>
                    <Grid container>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                onClick={() => history.goBack()}
                                style={{ margin: '20px 0' }}
                            >
                                Back
                            </Button>
                        </Grid>

                        {order.orderStatus === 1
                            ? confirmButton
                            : order.orderStatus < 5 &&
                              order.orderpayments.length === 0 &&
                              order.orderinvoices.length === 0
                            ? cancelConfirmButton
                            : ''}
                        {order.orderStatus === 1
                            ? cancelButton
                            : order.orderStatus === 8
                            ? refundButton
                            : ''}
                        {order.orderStatus >= 3 && order.orderStatus < 5
                            ? finishButton
                            : ''}
                    </Grid>

                    <div className="order-info-box">
                        <div className="order-info-detail-title">
                            Order Details
                        </div>
                        <Divider />
                        <div className="order-info-detail-box">
                            <div className="order-info-detail-left">
                                <div className="order-left-box">
                                    <div className="left-title">
                                        Order number:
                                    </div>
                                    <div className="left-value">
                                        {order.orderNo}
                                    </div>
                                </div>
                                <div className="order-left-box">
                                    <div className="left-title">
                                        Order email:
                                    </div>
                                    <div className="left-value">
                                        {order.email}
                                    </div>
                                </div>
                                <div className="order-left-box">
                                    <div className="left-title">
                                        Order phone:
                                    </div>
                                    <div className="left-value">
                                        {order.phone}
                                    </div>
                                </div>
                                <div className="order-left-box">
                                    <div className="left-title">Status:</div>
                                    <div className="left-value">
                                        <Chip
                                            label={orderStatusDisplay?.status}
                                            style={{
                                                background:
                                                    orderStatusDisplay?.color,
                                            }}
                                        />
                                    </div>
                                </div>
                                {order.client && (
                                    <div className="order-left-box-flex">
                                        <div className="left-title">
                                            Client Info:
                                        </div>
                                        <div className="left-value">
                                            {order.client.firstName}{' '}
                                            {order.client.lastName}
                                            <br />
                                            {order.client.email}
                                            <br />
                                            {order.client.phone}
                                            <br />
                                            {order.client.address}
                                            <br />
                                            {order.client.city},{' '}
                                            {order.client.province}{' '}
                                            {order.client.postCode}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="order-info-detail-right">
                                <div className="order-left-box-flex">
                                    <div className="left-title">Ship to:</div>
                                    <div className="left-value">
                                        {shippingAddress?.firstName}{' '}
                                        {shippingAddress?.lastName}
                                        <br />
                                        {shippingAddress?.address}
                                        <br />
                                        {shippingAddress?.city},{' '}
                                        {shippingAddress?.province}{' '}
                                        {shippingAddress?.postCode}
                                        <br />
                                        {shippingAddress?.phone}
                                    </div>
                                </div>
                                <div className="order-left-box-flex">
                                    <div className="left-title">Bill to:</div>
                                    <div className="left-value">
                                        {billingAddress?.firstName}{' '}
                                        {billingAddress?.lastName}
                                        <br />
                                        {billingAddress?.address}
                                        <br />
                                        {billingAddress?.city},{' '}
                                        {billingAddress?.province}{' '}
                                        {billingAddress?.postCode}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {order.ordershippinghistorys.length > 0 && (
                        <div className="order-shipping-box">
                            <div className="order-info-detail-title">
                                Order Actions
                            </div>
                            <Divider />
                            <div className="order-history-box">
                                {order.ordershippinghistorys.map((history) => (
                                    <div
                                        key={history.id}
                                        className="order-history-item"
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <div style={{ width: '200px' }}>
                                                {moment(
                                                    history.historyDate
                                                ).format('lll')}
                                            </div>
                                            <div style={{ textAlign: 'left' }}>
                                                {history.hsitoryAction}
                                            </div>
                                        </div>
                                        <div>
                                            User: {history.user.firstName}{' '}
                                            {history.user.lastName}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {order.orderStatus > 1 && (
                        <OrderInvoiceDisplay order={order} />
                    )}

                    <div className="order-shipping-box">
                        <div className="order-info-detail-title">Shipping</div>
                        <Divider />
                        <div className="order-left-box-flex">
                            <div className="left-title">Shipping Type:</div>
                            <div className="left-value">
                                {order.ordershipping.shippingType === 1
                                    ? 'Delivery'
                                    : 'Pick up'}
                            </div>
                        </div>
                        {order.ordershipping.shippingType === 1 ? (
                            <>
                                <div className="order-left-box-flex">
                                    <div className="left-title">
                                        Shipping Distance:
                                    </div>
                                    <div className="left-value">
                                        {order.ordershipping.shippingDistance}{' '}
                                        KM
                                    </div>
                                </div>
                                <div className="order-left-box-flex">
                                    <div className="left-title">
                                        Shipping Method:
                                    </div>
                                    <div className="left-value">
                                        {returnMethod(
                                            order.ordershipping.shippingMethod
                                        )}
                                    </div>
                                </div>
                                {(order.ordershipping.courier ||
                                    order.ordershipping.trackingNo) && (
                                    <>
                                        <>
                                            <div className="order-left-box-flex">
                                                <div className="left-title">
                                                    Courier:
                                                </div>
                                                <div className="left-value">
                                                    {
                                                        order.ordershipping
                                                            .courier
                                                    }
                                                </div>
                                            </div>
                                            <div className="order-left-box-flex">
                                                <div className="left-title">
                                                    Tracking Not:
                                                </div>
                                                <div className="left-value">
                                                    {
                                                        order.ordershipping
                                                            .trackingNo
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    </>
                                )}
                                {order.ordershipping.sentDate && (
                                    <>
                                        <>
                                            <div className="order-left-box-flex">
                                                <div className="left-title">
                                                    Sent Date:
                                                </div>
                                                <div className="left-value">
                                                    {moment(
                                                        order.ordershipping
                                                            .sentDate
                                                    ).format('lll')}
                                                </div>
                                            </div>
                                        </>
                                    </>
                                )}
                                {order.ordershipping.shippingMethod === 1 &&
                                order.orderStatus === 2 ? (
                                    <div className="order-left-box-flex">
                                        <div className="left-title">
                                            Delivery Date:
                                        </div>
                                        <div className="left-value">
                                            {order.ordershipping.deliveryDate.substr(
                                                0,
                                                1
                                            ) !== '0'
                                                ? order.ordershipping
                                                      .deliveryDate
                                                : ''}{' '}
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={() => handleEditOpen()}
                                            >
                                                {order.ordershipping.deliveryDate.substr(
                                                    0,
                                                    1
                                                ) === '0'
                                                    ? 'Set Date'
                                                    : 'Edit Date'}
                                            </Button>
                                        </div>

                                        <Dialog
                                            open={editOpen}
                                            // onClose={handleEditClose}
                                            aria-labelledby="form-dialog-edit"
                                        >
                                            <EditDeliveryDate
                                                handleDialogClose={
                                                    handleEditClose
                                                }
                                            />
                                        </Dialog>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {order.ordershipping.shippingMethod === 1 &&
                                order.ordershipping.deliveryDate.substr(
                                    0,
                                    1
                                ) !== '0' &&
                                order.orderStatus === 2 ? (
                                    <div>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                dispatch(
                                                    changeOrderStatus(id, 3)
                                                )
                                            }
                                        >
                                            Send Delivery
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {order.ordershipping.shippingMethod === 2 &&
                                order.orderStatus === 2 ? (
                                    <>
                                        <div>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    handleFreightOpen()
                                                }
                                            >
                                                Send Delivery
                                            </Button>
                                        </div>
                                        <Dialog
                                            open={freightOpen}
                                            // onClose={handleEditClose}
                                            aria-labelledby="form-dialog-edit"
                                        >
                                            <EditFreight
                                                handleDialogClose={
                                                    handleFreightClose
                                                }
                                            />
                                        </Dialog>
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            <>
                                <div className="order-left-box-flex">
                                    <div className="left-title">
                                        Pick Up Location:
                                    </div>
                                    <div className="left-value">
                                        {
                                            order.ordershipping.pickuplocation
                                                .address1
                                        }
                                        ,{' '}
                                        {
                                            order.ordershipping.pickuplocation
                                                .address2
                                        }
                                        <br />
                                        {
                                            order.ordershipping.pickuplocation
                                                .city
                                        }
                                        ,{' '}
                                        {
                                            order.ordershipping.pickuplocation
                                                .province
                                        }
                                        ,{' '}
                                        {
                                            order.ordershipping.pickuplocation
                                                .country
                                        }{' '}
                                        {
                                            order.ordershipping.pickuplocation
                                                .postCode
                                        }
                                    </div>
                                </div>
                                {order.orderStatus === 2 && (
                                    <>
                                        <div className="order-left-box-flex">
                                            <div className="left-title"></div>
                                            <div className="left-value">
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    onClick={() =>
                                                        handlePickUp()
                                                    }
                                                >
                                                    Change Pick Up Location
                                                </Button>
                                            </div>
                                        </div>
                                        <Dialog
                                            open={pickUp}
                                            // onClose={handleEditClose}
                                            aria-labelledby="form-dialog-edit"
                                        >
                                            <EditPickUpLocation
                                                handleDialogClose={
                                                    handlePickUpClose
                                                }
                                                pickUpLocationId={
                                                    order.ordershipping
                                                        .pickuplocation.id
                                                }
                                                orderId={order.id}
                                                orderShippingId={
                                                    order.ordershipping.id
                                                }
                                            />
                                        </Dialog>
                                        <div className="order-left-box-flex">
                                            <div className="left-title"></div>
                                            <div className="left-value">
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() =>
                                                        dispatch(
                                                            changeOrderStatus(
                                                                id,
                                                                4
                                                            )
                                                        )
                                                    }
                                                >
                                                    Ready to pick up
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="order-items-box">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center',
                                padding: '10px 0',
                            }}
                        >
                            <div className="order-items-title">
                                Items
                                {order.orderStatus === 1 && (
                                    <>
                                        <Button
                                            style={{
                                                marginLeft: '20px',
                                                marginBottom: '10px',
                                            }}
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleAddItemOpen()}
                                        >
                                            Add Item
                                        </Button>
                                        <Dialog
                                            open={addItemOpen}
                                            // onClose={handleEditClose}
                                            aria-labelledby="form-dialog-edit"
                                        >
                                            <AddItem
                                                handleDialogClose={
                                                    handleAddItemClose
                                                }
                                                orderId={order.id}
                                            />
                                        </Dialog>
                                    </>
                                )}
                            </div>
                            <PromoCodeInput
                                orderId={order.id}
                                promoCodeName={order.promoCodeName}
                                orderStatus={order.orderStatus}
                            />
                        </div>
                        <Divider />
                        <div className="order-items">
                            {order.orderitems.map((item) => (
                                <OrderInfoItem
                                    key={item.id}
                                    item={item}
                                    orderId={order.id}
                                    orderStatus={order.orderStatus}
                                    promoCodeName={order.promoCodeName}
                                    promoRate={order.promoRate}
                                />
                            ))}
                        </div>
                        <Divider />
                        <div className="item-price-box">
                            <div className="item-price-item">
                                Merchandise Subtotal
                            </div>
                            <div className="item-price-price">
                                ${subTotal.toFixed(2)}
                            </div>
                        </div>
                        {order?.promoCodeName ? (
                            <div className="item-price-box">
                                <div className="item-price-item">
                                    Promo Code {order.promoCodeName}{' '}
                                    {order.promoRate
                                        ? ` - ${order.promoRate}%`
                                        : ''}
                                </div>
                                <div className="item-price-price">
                                    {order.discount
                                        ? `- $${order.discount.toFixed(2)}`
                                        : '0'}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {order?.ordershipping?.shippingType === 1 ? (
                            <div className="item-price-box">
                                <div className="item-price-item">Shipping</div>
                                <div className="item-price-price">
                                    {shippingFee
                                        ? `$${shippingFee.toFixed(2)}`
                                        : 'Free'}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="item-price-box">
                            <div className="item-price-item">GST/HST</div>
                            <div className="item-price-price">
                                ${order.tax.toFixed(2)}
                            </div>
                        </div>
                        <Divider />
                        <div className="item-price-box">
                            <div className="item-price-item price-total">
                                Order Total
                            </div>
                            <div className="item-price-price price-total">
                                ${order.total.toFixed(2)}
                            </div>
                        </div>
                        <div className="item-price-box">
                            <div className="item-price-item">Paid</div>
                            <div className="item-price-price">
                                ${paymentAmount.toFixed(2)}
                            </div>
                        </div>
                        <Divider />
                        <div className="item-price-box">
                            <div className="item-price-item price-total">
                                Amount Due
                            </div>
                            <div className="item-price-price price-total">
                                ${(order.total - paymentAmount).toFixed(2)}
                            </div>
                        </div>
                    </div>
                    {order?.orderitemhistories?.length > 0 && (
                        <>
                            <Divider />
                            <div>
                                <div className="order-info-detail-title">
                                    Item History
                                </div>
                                <Divider />
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Sku No</TableCell>
                                            <TableCell>New</TableCell>
                                            <TableCell>Old</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.orderitemhistories.map(
                                            (item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell>
                                                        {modifyTypeName(
                                                            item.modifyType
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.skuNo}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.from}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.to}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.user.firstName}{' '}
                                                        {item.user.lastName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(
                                                            item.createdAt
                                                        ).format('lll')}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </>
                    )}
                </Container>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default OrderInfo
