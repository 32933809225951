import {
    CHANGE_PRODUCTCOLORS_PAGE,
    GET_ALL_PRODUCTCOLORS,
    GET_ALL_SKUNO,
    GET_ONE_PRODUCTCOLOR,
    SEARCH_PRODUCTCOLORS,
} from '../types'

const initialState = {
    productColors: [],
    productColor: {},
    allSkuNos: [],
    doneSkuNos: [],
    pages: '',
    page: '',
    count: '',
    keyword: '',
    searchOnline: '',
}

export const productColorReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRODUCTCOLORS:
            return {
                ...state,
                productColors: payload.productColors,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                searchOnline: payload.searchOnline,
                productColor: null,
            }
        case SEARCH_PRODUCTCOLORS:
            return {
                ...state,
                keyword: payload.keyword,
                searchOnline: payload.searchOnline,
                page: 1,
            }
        case CHANGE_PRODUCTCOLORS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_PRODUCTCOLOR:
            return {
                ...state,
                productColor: payload,
            }
        case GET_ALL_SKUNO:
            return {
                ...state,
                allSkuNos: payload.allSkuNos,
                doneSkuNos: payload.doneSkuNos,
            }
        default:
            return state
    }
}

export default productColorReducer
