import { GET_ALL_PICTUREPOINTS, GET_ONE_PICTUREPOINT } from '../types'

const initialState = {
    picturePoints: [],
    picturePoint: {},
}

export const picturePointReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PICTUREPOINTS:
            return {
                ...state,
                picturePoints: payload,
            }
        case GET_ONE_PICTUREPOINT:
            return {
                ...state,
                picturePoint: payload,
            }
        default:
            return state
    }
}

export default picturePointReducer
