import { Avatar, Button, Chip, TableCell, TableRow } from '@material-ui/core'
import { buildTypeLists, designStatus } from '../../constant/default'
import moment from 'moment'
import history from '../../history'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: '5px',
    },
}))

const chipColor = ['secondary', 'primary', 'default', 'primary', 'primary']

const RequestDesignList = ({ requestDesign }) => {
    const classes = useStyles()
    return (
        <>
            <TableRow>
                <TableCell>
                    <Chip
                        label={
                            designStatus.filter(
                                (item) => item.id === requestDesign?.status
                            )[0]?.name
                        }
                        color={chipColor[parseInt(requestDesign?.status) - 1]}
                    />
                </TableCell>
                <TableCell>{requestDesign?.projectNo}</TableCell>
                <TableCell>
                    {
                        buildTypeLists.filter(
                            (list) => list.id === requestDesign?.buildType
                        )[0].name
                    }
                </TableCell>
                <TableCell>
                    {requestDesign?.firstName} {requestDesign?.lastName}
                </TableCell>
                <TableCell>{requestDesign?.email}</TableCell>
                <TableCell>{requestDesign?.phone}</TableCell>
                <TableCell>
                    {requestDesign?.client ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        ''
                    )}
                </TableCell>
                <TableCell>
                    {moment(requestDesign?.createdAt).format('ll')}
                </TableCell>
                <TableCell>
                    <div>
                        {requestDesign.designers &&
                            requestDesign.designers.length > 0 &&
                            requestDesign.designers.map((designer) => (
                                <div key={designer.id}>
                                    <Chip
                                        className={classes.chip}
                                        avatar={
                                            <Avatar>
                                                {designer.firstName
                                                    .substr(0, 1)
                                                    .toUpperCase()}
                                            </Avatar>
                                        }
                                        label={`${designer.firstName} ${designer.lastName}`}
                                        color={
                                            designer.isActive &&
                                            !designer.blockUser
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        deleteIcon={<DoneIcon />}
                                        variant="outlined"
                                        onClick={() =>
                                            history.push(
                                                `/designer/${designer.id}`
                                            )
                                        }
                                    />
                                </div>
                            ))}
                    </div>
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={() =>
                            history.push(
                                `/requestdesignone/${requestDesign.id}`
                            )
                        }
                    >
                        info
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default RequestDesignList
