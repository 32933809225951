import { GET_ALL_QNACATEGORIES, GET_ONE_QNACATEGORY } from '../types'

const initialState = {
    qnaCategories: [],
    qnaCategory: null,
}

export const qnaCategoryReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_QNACATEGORIES:
            return {
                ...state,
                qnaCategories: payload,
            }
        case GET_ONE_QNACATEGORY:
            return {
                ...state,
                qnaCategory: payload,
            }
        default:
            return state
    }
}

export default qnaCategoryReducer
