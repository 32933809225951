import {
    Container,
    Grid,
    Typography,
    Button,
    Checkbox,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { GET_ONE_DESIGNER } from '../store/types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
    getOneDesigner,
    updateOneDesigner,
} from '../store/actions/designerActions'
import { green, red } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonStyle: {
        margin: '20px 0',
    },
    gridStyle: {
        margin: '10px 0',
    },
    gridStyle1: {
        margin: '10px 0',
        background: '#f0f0f0',
    },
    gridLeft: {
        padding: '10px',
    },
}))

const photoList = ['photo1', 'photo2', 'photo3', 'photo4', 'photo5']
const pdfList = ['pdf1', 'pdf2']
const levelList = [
    { level: 1, title: 'Designer' },
    { level: 2, title: 'Manager' },
]

const Designer = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()
    const designerState = useSelector((state) => state.designer)
    const { designer } = designerState

    const [blockUser, setBlockUser] = useState(false)
    const [level, setLevel] = useState(() => designer?.level || 1)
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (designer && designer.level) {
            setLevel(designer?.level)
        }
    }, [designer])

    useEffect(() => {
        if (designer && designer.blockUser) {
            setBlockUser(true)
        } else {
            setBlockUser(false)
        }
    }, [designer])

    useEffect(() => {
        if (designer && designer.isActive) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [designer])

    useEffect(() => {
        dispatch(getOneDesigner(id))
        return () => {
            dispatch({ type: GET_ONE_DESIGNER, payload: null })
        }
    }, [dispatch, id])

    const skills = useMemo(() => {
        return designer?.skills?.split('&')?.map((item) => {
            const a = item.split('||')
            return { skill: a[0], level: a[1] }
        })
    }, [designer])

    const certifications = useMemo(() => {
        return designer?.certifications?.split('&')?.map((item) => {
            const a = item.split('||')
            return { certification: a[0], certificationYear: a[1] }
        })
    }, [designer])

    const handleSubmit = () => {
        const values = { isActive, blockUser, level, id: designer.id }
        dispatch(updateOneDesigner(values))
    }

    const handleActiveConfirm = (e) => {
        setIsActive(!isActive)
    }

    const handleBlockConfirm = (e) => {
        setBlockUser(!blockUser)
    }

    const handleLevelChange = (e) => {
        setLevel(e.target.value)
    }

    return (
        <Layout>
            {designer ? (
                <>
                    <Container>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Email
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.email}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.avatar ? (
                                        <img
                                            src={designer.avatar}
                                            alt="avatar"
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                objectFit: 'contain',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    First Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.firstName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Last Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.lastName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <span style={{ whiteSpace: 'pre-line' }}>
                                    {designer.description}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Year of experience
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.experience}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Skills
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {skills.map((skill, index) => (
                                    <div key={index} style={{ padding: '5px' }}>
                                        {skill.skill} [{skill.level}]
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Education
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.education} (
                                    {designer.graduateYear})
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Certification
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {certifications.map((certification, index) => (
                                    <div key={index} style={{ padding: '5px' }}>
                                        {certification.certification} (
                                        {certification.certificationYear})
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Website
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.website}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Network
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.network}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Images
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    {photoList.map((photo, index) => (
                                        <Grid key={index} item xs={2}>
                                            {designer[photo] ? (
                                                <a
                                                    href={designer[photo]}
                                                    target="_black"
                                                >
                                                    <img
                                                        src={designer[photo]}
                                                        alt="pic"
                                                        width="100px"
                                                    />
                                                </a>
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    PDF
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    {pdfList.map((pdf, index) => (
                                        <Grid key={index} item xs={2}>
                                            {designer[pdf] ? (
                                                <a
                                                    href={designer[pdf]}
                                                    target="_black"
                                                >
                                                    <img
                                                        src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                                                        alt="pic"
                                                        width="100px"
                                                        style={{
                                                            padding: '10px',
                                                        }}
                                                    />
                                                </a>
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Delivery time for first draft
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.firstDraft}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Responsive time for changes
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.changeTime}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Email verify
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {designer.emailVerify ? (
                                        <CheckCircleOutlineIcon
                                            style={{ color: green[500] }}
                                        />
                                    ) : (
                                        <BlockIcon
                                            style={{ color: red[500] }}
                                        />
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Active
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Checkbox
                                    // defaultChecked={isActive}
                                    checked={isActive}
                                    onChange={handleActiveConfirm}
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Level
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Level
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={level}
                                        label="Level"
                                        onChange={handleLevelChange}
                                    >
                                        {levelList.map((level, index) => (
                                            <MenuItem
                                                key={index}
                                                value={level.level}
                                            >
                                                {level.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.gridStyle1}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    color="primary"
                                    className={classes.gridLeft}
                                >
                                    Block
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Checkbox
                                    checked={blockUser}
                                    onChange={handleBlockConfirm}
                                    color="secondary"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.buttonStyle}>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => handleSubmit()}
                                style={{ marginRight: '10px' }}
                            >
                                Update
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={() => history.go(-1)}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Container>
                </>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default Designer
