import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Typography } from '@material-ui/core'
import Layout from '../components/Layout'
import ReduxPagnation from '../components/ReduxPagnation'
import { CHANGE_ORDERS_PAGE } from '../store/types'
import { getAllOrders } from '../store/actions/orderActions'
import OrderInfos from '../components/order/OrderInfos'
import OrderSearch from '../components/order/OrderSearch'

const Orders = () => {
    const dispatch = useDispatch()
    const orderState = useSelector((state) => state.order)
    const {
        orders,
        pages,
        page,
        count,
        searchStatus,
        searchClient,
        searchOrderNo,
        searchFromDate,
        searchToDate,
    } = orderState

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(
            getAllOrders(
                searchStatus,
                searchClient,
                searchOrderNo,
                searchFromDate,
                searchToDate,
                pageNumber
            )
        )
    }, [
        dispatch,
        searchStatus,
        searchClient,
        searchOrderNo,
        searchFromDate,
        searchToDate,
        pageNumber,
    ])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_ORDERS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <OrderSearch
                    displayStatus={searchStatus}
                    displayClient={searchClient}
                    displayOrderNo={searchOrderNo}
                    displayFromDate={searchFromDate}
                    displayToDate={searchToDate}
                />
                <Typography variant="body2" gutterBottom>
                    Count: {count}
                </Typography>
                {orders && <OrderInfos orders={orders} />}
                <ReduxPagnation
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Orders
