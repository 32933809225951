import {
    GET_ALL_DESIGNERS,
    SEARCH_DESIGNERS,
    CHANGE_DESIGNERS_PAGE,
    GET_ONE_DESIGNER,
} from '../types'

const initialState = {
    designers: [],
    designer: '',
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export const designerReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_DESIGNERS:
            return {
                ...state,
                designers: payload.designers,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                designer: null,
            }
        case SEARCH_DESIGNERS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_DESIGNERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_DESIGNER:
            return {
                ...state,
                designer: payload,
            }
        default:
            return state
    }
}
