import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    Fab,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableRow,
    Tooltip,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiTableCell from '@material-ui/core/TableCell'
import { measureInchToMm } from '../../constant/formula'
import AddIcon from '@material-ui/icons/Add'
import AddProductSize from './AddProductSize'
import DoneIcon from '@material-ui/icons/Done'
import PackageSizeList from './PackageSizeList'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import history from '../../history'
import { deleteProduct } from '../../store/actions/productActions'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import {
    DELETE_CONFIRM,
    EMPTY_CHILDREN,
    ITEM_HEIGHT,
} from '../../constant/default'
import { yellow } from '@material-ui/core/colors'
import { green, red } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import AddGlassDoor from '../glassDoor/AddGlassDoor'
import GlassDoorList from '../glassDoor/GlassDoorList'
import CabinetDepthList from '../cabinetDepth/CabinetDepthList'
import AddCabinetDepth from '../cabinetDepth/AddCabinetDepth'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
    },
    sizeHeading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
    },
    table: {
        width: '100%',
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    menuPosition: {
        position: 'absolute',
        top: '50%',
        height: '50%',
    },
    chip: {
        margin: '5px',
    },
    gridStyle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}))

const ProductInfo = ({ product }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const TableCell = withStyles({
        root: {
            borderBottom: 'none',
        },
    })(MuiTableCell)

    const [sizeOpen, setSizeOpen] = useState(false)

    const handleSizeOpen = () => {
        setSizeOpen(true)
    }

    const handleSizeClose = () => {
        setSizeOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const [glassOpen, setGlassOpen] = useState(false)

    const handleGlassOpen = () => {
        setGlassOpen(true)
    }

    const handleGlassClose = () => {
        setGlassOpen(false)
    }

    const [depthOpen, setDepthOpen] = useState(false)

    const handleDepthOpen = () => {
        setDepthOpen(true)
    }

    const handleDepthClose = () => {
        setDepthOpen(false)
    }

    const productColorOnlineFilter = product?.productcolors?.filter(
        (productColor) => productColor.online === true
    )?.length
    const productColorOnline = productColorOnlineFilter > 0 ? true : false

    let productSizeListM = []
    let packageSizeListM = []
    if (product.productmeasures && product.productmeasures.length > 0) {
        productSizeListM = product.productmeasures.map((size) => {
            return { id: size.id, name: size.name, value: size.productSize }
        })
        packageSizeListM = product.productmeasures.map((size) => {
            return { id: size.id, name: size.name, value: size.packageSize }
        })
    }

    const productSizeListP = [
        { name: 'Width', value: product.productWidth },
        { name: 'Height', value: product.productHeight },
        { name: 'Depth', value: product.productDepth },
    ]

    const packageSizeListP = [
        { name: 'Width', value: product.packageWidth },
        { name: 'Height', value: product.packageHeight },
        { name: 'Depth', value: product.packageDepth },
    ]

    const productSizeList = [...productSizeListP, ...productSizeListM]
    const packageSizeList = [...packageSizeListP, ...packageSizeListM]

    return (
        <>
            <Grid container>
                <Grid item xs={11}>
                    <Typography variant="h6">{product.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {product.productWidth}" W x {product.productHeight}" H x{' '}
                        {product.productDepth}" D
                    </Typography>
                </Grid>
                {productColorOnline === false && (
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="more"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={() =>
                                    history.push(`/editproduct/${product.id}`)
                                }
                            >
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>
                            {product.productcolors &&
                                product.productcolors.length ===
                                    EMPTY_CHILDREN && (
                                    <MenuItem
                                        onClick={() => {
                                            if (
                                                window.confirm(DELETE_CONFIRM)
                                            ) {
                                                dispatch(
                                                    deleteProduct(product.id)
                                                )
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <DeleteForeverIcon
                                                className={classes.deleteIcon}
                                                fontSize="small"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Delete"
                                            className={classes.deleteIcon}
                                        />
                                    </MenuItem>
                                )}
                        </Menu>
                    </Grid>
                )}
            </Grid>
            <Grid container className={classes.gridStyle}>
                <Grid item xs={3}>
                    {' '}
                    <Typography className={classes.heading}>
                        Category:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body1">
                        {product?.style?.category?.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
                <Grid item xs={3}>
                    {' '}
                    <Typography className={classes.heading}>Style:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body1">
                        {product?.style?.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
                <Grid item xs={3}>
                    {' '}
                    <Typography className={classes.heading}>Size:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body1">
                        {product?.size?.sizeValue}
                    </Typography>
                </Grid>
            </Grid>
            {product?.style?.category?.productOption && (
                <Grid container className={classes.gridStyle}>
                    <Grid item xs={3}>
                        {' '}
                        <Typography className={classes.heading}>
                            Door Direction:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        {product?.doorDirection ? (
                            <CheckCircleOutlineIcon
                                style={{ color: green[500] }}
                            />
                        ) : (
                            <BlockIcon style={{ color: red[500] }} />
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid container className={classes.gridStyle}>
                <Grid item xs={3}>
                    {' '}
                    <Typography className={classes.heading}>Sample:</Typography>
                </Grid>
                <Grid item xs={9}>
                    {product?.sample ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </Grid>
            </Grid>
            {product.productfunctions && product.productfunctions.length > 0 && (
                <Grid
                    container
                    alignItems="center"
                    className={classes.gridStyle}
                >
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>
                            Functions:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        {product.productfunctions.map((pFunc) => (
                            <Box component="span" key={pFunc.id}>
                                <Chip
                                    className={classes.chip}
                                    label={pFunc.functionName}
                                    color="primary"
                                    deleteIcon={<DoneIcon />}
                                    variant="outlined"
                                />
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            )}
            {product.producttype && (
                <Grid container className={classes.gridStyle}>
                    <Grid item xs={3}>
                        {' '}
                        <Typography className={classes.heading}>
                            Type:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <img
                            src={product?.producttype?.picLink}
                            alt="product type"
                            width="200px"
                        />
                        <Typography variant="body2" color="textSecondary">
                            {product?.producttype?.name}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid>
                <div className={classes.root}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                Description
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="textSecondary">
                                {product.description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>
                                Materials And Care
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="textSecondary">
                                {product.materialsAndCare}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className={classes.heading}>
                                Product size
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes.heading}
                                            >
                                                Product Size:
                                            </Typography>
                                            <Table
                                                size="small"
                                                className={classes.table}
                                            >
                                                <TableBody>
                                                    {productSizeList.map(
                                                        (sizeList) => (
                                                            <TableRow
                                                                key={
                                                                    sizeList.name
                                                                }
                                                            >
                                                                <TableCell>
                                                                    <Typography variant="subtitle2">
                                                                        {
                                                                            sizeList.name
                                                                        }
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        sizeList.value
                                                                    }{' '}
                                                                    (
                                                                    {measureInchToMm(
                                                                        sizeList.value
                                                                    )}
                                                                    )
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes.heading}
                                            >
                                                Package Size:
                                            </Typography>
                                            <Table
                                                size="small"
                                                className={classes.table}
                                            >
                                                <TableBody>
                                                    {packageSizeList.map(
                                                        (pSizeList) => (
                                                            <PackageSizeList
                                                                pSizeList={
                                                                    pSizeList
                                                                }
                                                                key={
                                                                    pSizeList.name
                                                                }
                                                                online={
                                                                    productColorOnline
                                                                }
                                                                productId={
                                                                    product.id
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </div>
                                {productColorOnline === false && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Tooltip title="Add" aria-label="add">
                                            <Fab
                                                color="primary"
                                                size="small"
                                                className={classes.fab}
                                                onClick={handleSizeOpen}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>
                                Warranty
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="textSecondary">
                                {product.warranty}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>
                                What's included in package
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="textSecondary">
                                {product.whatInPackage}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                {product?.style?.category?.productOption && (
                    <>
                        <div style={{ marginTop: '20px' }}>
                            <Card>
                                <CardContent>
                                    <div className="product-color-box">
                                        <div className="product-color-container">
                                            <div>
                                                <Typography variant="h6">
                                                    Glass Doors
                                                </Typography>
                                            </div>
                                            <div>
                                                {productColorOnline ===
                                                    false && (
                                                    <Tooltip
                                                        title="Add Glass Doors"
                                                        aria-label="add"
                                                    >
                                                        <Fab
                                                            color="primary"
                                                            size="small"
                                                            className={
                                                                classes.fab
                                                            }
                                                            onClick={
                                                                handleGlassOpen
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </Fab>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            {product.glassdoors &&
                                                product.glassdoors.length > 0 &&
                                                product.glassdoors.map(
                                                    (glassDoor) => (
                                                        <GlassDoorList
                                                            glassDoor={
                                                                glassDoor
                                                            }
                                                            key={glassDoor.id}
                                                            productId={
                                                                product.id
                                                            }
                                                            productColorOnline={
                                                                productColorOnline
                                                            }
                                                        />
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Card>
                                <CardContent>
                                    <div className="product-color-box">
                                        <div className="product-color-container">
                                            <div>
                                                <Typography variant="h6">
                                                    Cabinet Depth
                                                </Typography>
                                            </div>
                                            <div>
                                                {productColorOnline ===
                                                    false && (
                                                    <Tooltip
                                                        title="Add Glass Doors"
                                                        aria-label="add"
                                                    >
                                                        <Fab
                                                            color="primary"
                                                            size="small"
                                                            className={
                                                                classes.fab
                                                            }
                                                            onClick={
                                                                handleDepthOpen
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </Fab>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            {product.cabinetdepths &&
                                                product.cabinetdepths.length >
                                                    0 &&
                                                product.cabinetdepths.map(
                                                    (cabinetDepth) => (
                                                        <CabinetDepthList
                                                            cabinetDepth={
                                                                cabinetDepth
                                                            }
                                                            key={
                                                                cabinetDepth.id
                                                            }
                                                            productId={
                                                                product.id
                                                            }
                                                            productColorOnline={
                                                                productColorOnline
                                                            }
                                                        />
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </>
                )}
                <div style={{ marginTop: '20px' }}>
                    {product.pdfLink && (
                        <a
                            href={`${product.pdfLink}`}
                            target="_black"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                startIcon={<PictureAsPdfIcon />}
                                variant="contained"
                                style={{ backgroundColor: yellow[200] }}
                            >
                                Download PDF Installation Manual
                            </Button>
                        </a>
                    )}
                </div>
                <div style={{ marginTop: '20px' }}>
                    {product.qrLink && (
                        <img src={product.qrLink} alt="qrCode" />
                    )}
                </div>
            </Grid>

            <Dialog open={sizeOpen} aria-labelledby="form-dialog-style">
                <AddProductSize
                    handleDialogClose={handleSizeClose}
                    productId={product.id}
                />
            </Dialog>

            <Dialog open={glassOpen} aria-labelledby="form-dialog-style">
                <AddGlassDoor
                    handleDialogClose={handleGlassClose}
                    productId={product.id}
                />
            </Dialog>

            <Dialog open={depthOpen} aria-labelledby="form-dialog-style">
                <AddCabinetDepth
                    handleDialogClose={handleDepthClose}
                    productId={product.id}
                />
            </Dialog>
        </>
    )
}

export default ProductInfo
