import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import StyleForm from './StyleForm'

const AddStyle = ({ handleDialogClose, categoryId }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Style</DialogTitle>
        <StyleForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
            categoryId={categoryId}
        />
    </>
)

export default AddStyle
