import { GET_ALL_CABINETOPTIONS, GET_ONE_CABINETOPTION } from '../types'

const initialState = {
    cabinetOptions: [],
    cabinetOption: {},
}

export const cabinetOptionReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_CABINETOPTIONS:
            return {
                ...state,
                cabinetOptions: payload,
            }
        case GET_ONE_CABINETOPTION:
            return {
                ...state,
                cabinetOption: payload,
            }
        default:
            return state
    }
}

export default cabinetOptionReducer
