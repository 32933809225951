import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import ProductTypeForm from './ProductTypeForm'

const EditProductType = ({ handleDialogClose }) => {
    const productTypeState = useSelector((state) => state.productType)
    const { productType } = productTypeState

    return productType ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Product Type</DialogTitle>
            <ProductTypeForm
                handleDialogClose={handleDialogClose}
                initialValues={productType}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditProductType
