import { GET_ONE_STYLECOLOR } from '../types'

const initialState = {
    styleColor: {},
}

export const styleColorReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ONE_STYLECOLOR:
            return {
                ...state,
                styleColor: payload,
            }
        default:
            return state
    }
}

export default styleColorReducer
