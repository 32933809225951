import { useState } from 'react'
import { Button, TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
// import ModalInfo from './ModalInfo'
import { green, red } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ClientInfo from './ClientInfo'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        minWidth: '500px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

const ClientList = ({ client }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <TableRow>
                <TableCell>{client.email}</TableCell>
                <TableCell>
                    {client.firstName} {client.lastName}
                </TableCell>
                <TableCell>{client.phone}</TableCell>
                <TableCell align="center">
                    {client.isActive ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell align="center">
                    {client.blockUser ? (
                        <BlockIcon style={{ color: red[500] }} />
                    ) : (
                        ''
                    )}
                </TableCell>
                <TableCell align="center">
                    {moment(client.createdAt).format('YYYY-MM-DD')}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleOpen}
                    >
                        Info
                    </Button>
                </TableCell>
            </TableRow>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <ClientInfo client={client} handleClose={handleClose} />
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default ClientList
