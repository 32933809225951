import Layout from '../components/Layout'
import { useSelector } from 'react-redux'
import ProfileForm from '../components/users/ProfileForm'

const Profile = () => {
    const userState = useSelector((state) => state.user)
    const { userInfo } = userState
    return (
        <Layout>
            <ProfileForm initialValues={userInfo} />
        </Layout>
    )
}

export default Profile
