import { errorsReturn } from '../errorsReturn'
import { GET_ONE_SELFASSEMBLEPROCESS } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { logout, renewUserToken } from './userActions'
import {
    SELFASSEMBLEPROCESS_ADD_SUCCESSFULLY,
    SELFASSEMBLEPROCESS_DELETE_SUCCESSFULLY,
    SELFASSEMBLEPROCESS_UPDATE_SUCCESSFULLY,
} from '../../constant/default'
import { getOneSelfAssemble } from './selfAssembleActions'

export const getOneSelfAssembleProcess = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`selfassembleprocess/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_SELFASSEMBLEPROCESS, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const createSelfAssembleProcess =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.post(`selfassembleprocess/create`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(createMessage(SELFASSEMBLEPROCESS_ADD_SUCCESSFULLY))
            dispatch(controlLoading(false))
            dispatch(getOneSelfAssemble(values.selfAssembleId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateSelfAssembleProcess =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.put(
                `selfassembleprocess/update/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(createMessage(SELFASSEMBLEPROCESS_UPDATE_SUCCESSFULLY))
            dispatch(controlLoading(false))
            dispatch(getOneSelfAssemble(values.selfAssembleId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const deleteSelfAssembleProcess =
    (id, selfAssembleId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.delete(`selfassembleprocess/delete/${id}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(createMessage(SELFASSEMBLEPROCESS_DELETE_SUCCESSFULLY))
            dispatch(controlLoading(false))
            dispatch(getOneSelfAssemble(selfAssembleId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
