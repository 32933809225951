import { Button, Chip, TableCell, TableRow } from '@material-ui/core'
import { getOrderStatus } from '../../constant/formula'
import history from '../../history'

const OrderList = ({ order }) => {
    const orderStatusDisplay = getOrderStatus(order.orderStatus)
    return (
        <>
            <TableRow>
                <TableCell>
                    <Chip
                        label={orderStatusDisplay.status}
                        style={{ background: orderStatusDisplay.color }}
                    />
                </TableCell>
                <TableCell>
                    {order?.ordershipping?.shippingType === 1
                        ? order?.ordershipping?.shippingMethod === 1
                            ? 'Package'
                            : 'Freight'
                        : 'Pick Up'}
                </TableCell>
                <TableCell>{order.orderNo}</TableCell>
                <TableCell>{order.email}</TableCell>
                <TableCell>{order.phone}</TableCell>
                <TableCell align="right">${order.total.toFixed(2)}</TableCell>
                <TableCell>{order.promoCodeName}</TableCell>
                <TableCell align="center">{order.orderDate}</TableCell>
                <TableCell>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => history.push(`/order/${order.id}`)}
                    >
                        Info
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default OrderList
