import { GET_ALL_SELFASSEMBLES, GET_ONE_SELFASSEMBLE } from '../types'

const initialState = {
    selfAssembles: [],
    selfAssemble: null,
}

export const selfAssembleReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SELFASSEMBLES:
            return {
                ...state,
                selfAssembles: payload,
            }
        case GET_ONE_SELFASSEMBLE:
            return {
                ...state,
                selfAssemble: payload,
            }
        default:
            return state
    }
}

export default selfAssembleReducer
