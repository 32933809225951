import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
    Avatar,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddInvoice from './AddInvoice'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { red, blue, green } from '@material-ui/core/colors'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DELETE_CONFIRM, EMPTY_CHILDREN } from '../../../constant/default'
import { deleteInvoice } from '../../../store/actions/orderActions'
import EditInvoice from './EditInvoice'
import PaymentDisplay from '../orderPayment/PaymentDisplay'
import { createMessage } from '../../../store/actions/messageActions'
import { unsecuredCopyToClipboard } from '../../../common/common'

const ITEM_HEIGHT = 48

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatarRed: {
        backgroundColor: red[500],
    },
    avatarBlue: {
        backgroundColor: blue[500],
    },
    avatarGreen: {
        backgroundColor: green[500],
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
}))

const InvoiceItem = ({ invoice, orderId }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { invoiceNo, amount, dueDate, note, id } = invoice
    const values = {
        orderId,
        id,
    }
    const avatarStyle = useMemo(() => {
        const paymentTotal =
            invoice?.orderpayments?.reduce((total, payment) => {
                if (payment.successful) return total + payment.amount
                return total
            }, 0) || 0
        if (paymentTotal === 0) return { word: 'N', style: classes.avatarRed }
        if (paymentTotal < invoice.amount)
            return { word: 'H', style: classes.avatarBlue }
        return { word: 'Y', style: classes.avatarGreen }
    }, [invoice, classes.avatarBlue, classes.avatarGreen, classes.avatarRed])

    const [anchorEl, setAnchorEl] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleEditOpen = () => {
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const totalPayment = useMemo(() => {
        return parseFloat(
            (
                invoice?.orderpayments?.reduce((acc, payment) => {
                    if (payment.successful) return acc + payment.amount
                    return acc
                }, 0) || 0
            ).toFixed(2)
        )
    }, [invoice])

    const handleCopy = () => {
        const linkUrl = `${process.env.REACT_APP_PAYMENT_URL}paymentcharge/${invoice.id}`
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(linkUrl)
        } else {
            unsecuredCopyToClipboard(linkUrl)
        }
        dispatch(createMessage('Link copy successful'))
    }

    return (
        <>
            <Card style={{ marginTop: '10px' }}>
                <CardHeader
                    avatar={
                        <Avatar
                            aria-label="recipe"
                            className={avatarStyle.style}
                        >
                            {avatarStyle.word}
                        </Avatar>
                    }
                    action={
                        <>
                            <IconButton
                                aria-label="settings"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem onClick={handleEditOpen}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                </MenuItem>
                                {invoice.orderpayments &&
                                    invoice.orderpayments.length ===
                                        EMPTY_CHILDREN && (
                                        <MenuItem
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        DELETE_CONFIRM
                                                    )
                                                ) {
                                                    dispatch(
                                                        deleteInvoice(values)
                                                    )
                                                }
                                            }}
                                        >
                                            <ListItemIcon>
                                                <DeleteForeverIcon
                                                    className={
                                                        classes.deleteIcon
                                                    }
                                                    fontSize="small"
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Delete"
                                                className={classes.deleteIcon}
                                            />
                                        </MenuItem>
                                    )}
                            </Menu>
                        </>
                    }
                    title={`${invoiceNo} - $${amount} (Total Paid $${totalPayment})`}
                    subheader={dueDate}
                />
                <CardContent>
                    <PaymentDisplay invoice={invoice} orderId={orderId} />
                </CardContent>
                <CardContent>{note}</CardContent>
                <CardActionArea style={{ padding: '20px' }}>
                    <div onClick={handleCopy}>
                        {process.env.REACT_APP_PAYMENT_URL}paymentcharge/
                        {invoice.id}
                    </div>
                </CardActionArea>
            </Card>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditInvoice
                    handleDialogClose={handleEditClose}
                    orderId={orderId}
                    invoice={invoice}
                />
            </Dialog>
        </>
    )
}

const OrderInvoiceDisplay = ({ order }) => {
    const [invoiceOpen, setInvoiceOpen] = useState(false)

    const handleInvoiceOpen = () => {
        setInvoiceOpen(true)
    }

    const handleInvoiceClose = () => {
        setInvoiceOpen(false)
    }

    return (
        <>
            <div className="order-shipping-box">
                <div className="order-info-detail-title">
                    <div>Invoice</div>
                    {order.totalInvoiceAmount < order.total && (
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => handleInvoiceOpen()}
                        >
                            Add Invoice
                        </Button>
                    )}
                </div>
            </div>
            {order?.orderinvoices?.map((invoice) => (
                <InvoiceItem
                    key={invoice.id}
                    invoice={invoice}
                    orderId={order.id}
                />
            ))}

            <Dialog
                open={invoiceOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <AddInvoice
                    handleDialogClose={handleInvoiceClose}
                    orderId={order.id}
                    amount={parseFloat(
                        (order.total - order.totalInvoiceAmount).toFixed(2)
                    )}
                />
            </Dialog>
        </>
    )
}

export default OrderInvoiceDisplay
