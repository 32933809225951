import {
    CHANGE_ORDERS_PAGE,
    GET_ALL_ORDERS,
    GET_ALL_PICKUPS,
    GET_ONE_ORDER,
    SEARCH_ORDERS,
} from '../types'

const initialState = {
    orders: null,
    order: null,
    pickUps: null,
    pages: '',
    page: '',
    count: '',
    searchStatus: '',
    searchFromDate: '',
    searchToDate: '',
    searchClient: '',
    searchOrderNo: '',
}

const orderReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: payload.orders,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                searchStatus: payload.searchStatus,
                searchFromDate: payload.searchFromDate,
                searchToDate: payload.searchToDate,
                searchClient: payload.searchClient,
                searchOrderNo: payload.searchOrderNo,
                order: null,
            }
        case SEARCH_ORDERS:
            return {
                ...state,
                searchStatus: payload.searchStatus,
                searchFromDate: payload.searchFromDate,
                searchToDate: payload.searchToDate,
                searchClient: payload.searchClient,
                searchOrderNo: payload.searchOrderNo,
                page: 1,
            }
        case CHANGE_ORDERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_ORDER:
            return {
                ...state,
                order: payload,
            }
        case GET_ALL_PICKUPS:
            return {
                ...state,
                pickUps: payload,
            }
        default:
            return state
    }
}

export default orderReducer
