import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    deleteProductMeasure,
    getOneProductMeasure,
} from '../../store/actions/productMeasureActions'
import { DELETE_CONFIRM, ITEM_HEIGHT } from '../../constant/default'
import EditProductSize from './EditProductSize'
import {
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { measureInchToMm } from '../../constant/formula'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
    },
    sizeHeading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
    },
    table: {
        width: '100%',
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
}))

const PackageSizeList = ({ pSizeList, productId, online }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [editOpen, setEditOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleEditOpen = (id) => {
        dispatch(getOneProductMeasure(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    return (
        <>
            <TableRow>
                <TableCell style={{ borderBottom: 'none' }}>
                    <Typography variant="subtitle2">
                        {pSizeList.name}
                    </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    {pSizeList.value} ({measureInchToMm(pSizeList.value)})
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    {pSizeList.id && online === false && (
                        <>
                            <IconButton
                                aria-label="more"
                                aria-controls={`customized-menu-${pSizeList.id}`}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id={`customized-menu-${pSizeList.id}`}
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() => handleEditOpen(pSizeList.id)}
                                >
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        if (window.confirm(DELETE_CONFIRM)) {
                                            dispatch(
                                                deleteProductMeasure(
                                                    pSizeList.id,
                                                    productId
                                                )
                                            )
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteForeverIcon
                                            className={classes.deleteIcon}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Delete"
                                        className={classes.deleteIcon}
                                    />
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </TableCell>
            </TableRow>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditProductSize
                    handleDialogClose={handleEditClose}
                    productId={productId}
                />
            </Dialog>
        </>
    )
}

export default PackageSizeList
