import {
    CHANGE_REQUEST_DESIGNS_PAGE,
    GET_ALL_DESIGNERS_NAME,
    GET_ALL_REQUEST_DESIGNS,
    GET_ONE_REQUEST_DESIGN,
    SEARCH_REQUEST_DESIGNS,
} from '../types'

const initialState = {
    requestDesigns: [],
    requestDesign: {},
    designers: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

const clientRequestDesignReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_REQUEST_DESIGNS:
            return {
                ...state,
                requestDesigns: payload.requestDesigns,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                requestDesign: null,
            }
        case SEARCH_REQUEST_DESIGNS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_REQUEST_DESIGNS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_REQUEST_DESIGN:
            return {
                ...state,
                requestDesign: payload,
            }
        case GET_ALL_DESIGNERS_NAME:
            return {
                ...state,
                designers: payload,
            }
        default:
            return state
    }
}

export default clientRequestDesignReducer
