import './pickUp.css'

const PickUpList = ({ location, pickUpAddress, setPickUpAddress }) => {
    return (
        <div className="pickup-list-box">
            <div className="pickup-list-radio">
                <input
                    type="radio"
                    name="pickUpId"
                    className="delivery-radio-input"
                    onClick={(e) => setPickUpAddress(e.target.value)}
                    value={location.id}
                    defaultChecked={pickUpAddress === location.id}
                />
            </div>
            <div className="pickup-list-address">
                <div>
                    {location.address1}{' '}
                    {location?.address2 && `, ${location?.address2}`}
                </div>
                <div className="pickup-address-city">
                    <div style={{ display: 'flex' }}>
                        <span>{location.city}</span>
                        <span className="mobile-display">, </span>
                    </div>
                    <div>
                        {location.province} {location.postCode}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickUpList
