import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import QnACategoryForm from './QnACategoryForm'

const AddQnACategory = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Q&A Category</DialogTitle>
        <QnACategoryForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddQnACategory
