import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import HeadOfficeForm from './HeadOfficeForm'

const AddHeadOffice = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Head Office</DialogTitle>
        <HeadOfficeForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddHeadOffice
