import {
    GET_ALL_USERS,
    GET_ONE_USER,
    SEARCH_USERS,
    USER_LOGOIN_SUCCESS,
    USER_LOGOUT,
    USER_UPDATE_INFO,
    USER_UPDATE_TOKEN,
    CHANGE_USERS_PAGE,
} from '../types'

const initialState = {
    userInfo: {},
    userToken: '',
    users: [],
    user: '',
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export const userReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case USER_LOGOIN_SUCCESS:
            return {
                ...state,
                userInfo: payload.userInfo,
                userToken: payload.userToken,
            }
        case USER_UPDATE_INFO:
            return { ...state, userInfo: payload }
        case USER_UPDATE_TOKEN:
            return { ...state, userToken: payload }
        case USER_LOGOUT:
            return {}
        case GET_ALL_USERS:
            return {
                ...state,
                users: payload.users,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                user: null,
            }
        case SEARCH_USERS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_USERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_USER:
            return {
                ...state,
                user: payload,
            }
        default:
            return state
    }
}
