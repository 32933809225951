import { DialogTitle } from '@material-ui/core'
import moment from 'moment'
import { FORM_TYPE_EDIT } from '../../../constant/default'
import InvoiceForm from './InvoiceForm'

const EditInvoice = ({ handleDialogClose, invoice, orderId }) => {
    invoice.dueDate = moment(invoice.dueDate).format('YYYY-MM-DD')
    return invoice ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Invoice</DialogTitle>
            <InvoiceForm
                handleDialogClose={handleDialogClose}
                initialValues={invoice}
                formType={FORM_TYPE_EDIT}
                orderId={orderId}
            />
        </>
    ) : null
}

export default EditInvoice
