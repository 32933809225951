import { GET_ALL_PRODUCTFUNCTIONS, GET_ONE_PRODUCTFUNCTION } from '../types'

const initialState = {
    productFunctions: [],
    productFunction: {},
}

export const productFunctionReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRODUCTFUNCTIONS:
            return {
                ...state,
                productFunctions: payload,
            }
        case GET_ONE_PRODUCTFUNCTION:
            return {
                ...state,
                productFunction: payload,
            }
        default:
            return state
    }
}

export default productFunctionReducer
