import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid } from '@material-ui/core'
import Layout from '../components/Layout'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { logout } from '../store/actions/userActions'
import request from '../config/request'
import { orderItemAnalysisData } from '../components/adminMain/orderItemAnalysis'

ChartJS.register(ArcElement, Tooltip, Legend)

const Main = () => {
    const dispatch = useDispatch()
    const [orderItemLabel, setOrderItemLabel] = useState([])
    const [orderItemData, setOrderItemData] = useState([])

    const userState = useSelector((state) => state.user)
    const { userToken } = userState

    useEffect(() => {
        const getData = async () => {
            try {
                let res = await request.get('adminmain/orderitems', {
                    headers: { Authorization: `Bearer ${userToken}` },
                })
                setOrderItemLabel(
                    res.data.data.map((item) => {
                        return `${item.name} - ${item.skuNo} (${item.quantity})`
                    })
                )
                setOrderItemData(res.data.data.map((item) => item.quantity))
            } catch (e) {
                const errors = {
                    msg: e?.response?.data?.msg,
                }
                if (errors?.msg === 'JWT Failed') {
                    dispatch(logout())
                }
            }
        }
        getData()
    }, [])

    const getOrderItemData = orderItemAnalysisData(
        orderItemLabel,
        orderItemData
    )
    return (
        <Layout>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Pie data={getOrderItemData} />
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Main
