import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Dialog,
    Container,
    Typography,
    Paper,
    Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
    DELETE_CONFIRM,
    ITEM_HEIGHT,
    OFFICE_POSTCODE,
} from '../../constant/default'
import EditPickUpLocation from './EditPickUpLocation'
import {
    deletePickUpLocation,
    getOnePickUpLocation,
} from '../../store/actions/pickUpLocationActions'
import axios from 'axios'
import DoneIcon from '@material-ui/icons/Done'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme) => ({
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    paper: {
        padding: '20px',
        margin: '10px 0',
    },
    chip: {
        margin: '5px',
    },
}))

const PickUpLocationList = ({ pickUpLocation }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)
    const [distanceOffice, setDistanceOffice] = useState(0)
    const handleEditOpen = () => {
        dispatch(getOnePickUpLocation(pickUpLocation.id))
        setEditOpen(true)
    }
    const location =
        pickUpLocation.address1 +
        ',' +
        pickUpLocation.city +
        ',' +
        pickUpLocation.province +
        ',' +
        pickUpLocation.country

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        const distantCal = async () => {
            try {
                const res = await axios.get(
                    `https://www.mapquestapi.com/directions/v2/route?key=${process.env.REACT_APP_MAPQUEST_KEY}&from=${OFFICE_POSTCODE}&to=${location}`
                )
                setDistanceOffice(res.data.route.distance)
            } catch (error) {
                console.log(error)
            }
        }
        distantCal()
    }, [location])

    return (
        <Container style={{ marginBottom: '2rem' }}>
            <Paper className={classes.paper}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        aria-label="more"
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem onClick={handleEditOpen}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                if (window.confirm(DELETE_CONFIRM)) {
                                    dispatch(
                                        deletePickUpLocation(pickUpLocation.id)
                                    )
                                }
                            }}
                        >
                            <ListItemIcon>
                                <DeleteForeverIcon
                                    className={classes.deleteIcon}
                                    fontSize="small"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Delete"
                                className={classes.deleteIcon}
                            />
                        </MenuItem>
                    </Menu>
                </div>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            {pickUpLocation.picLink && (
                                <img
                                    src={pickUpLocation.picLink}
                                    alt="pic"
                                    width="100%"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="subtitle2">
                                {pickUpLocation.address1},{' '}
                                {pickUpLocation.address2} <br />
                                {pickUpLocation.city}, <br />
                                {pickUpLocation.province},{' '}
                                {pickUpLocation.country}
                                <br /> {pickUpLocation.postCode}
                            </Typography>
                            <Typography variant="body2">
                                Distance From Head Office: {distanceOffice} KM
                            </Typography>
                            <div style={{ marginTop: '10px' }}>
                                {pickUpLocation.isOffice && (
                                    <Chip
                                        className={classes.chip}
                                        avatar={<Avatar>O</Avatar>}
                                        label="Office"
                                        color="primary"
                                        deleteIcon={<DoneIcon />}
                                        variant="outlined"
                                    />
                                )}
                                {pickUpLocation.isWarehouse && (
                                    <Chip
                                        className={classes.chip}
                                        avatar={<Avatar>W</Avatar>}
                                        label="Warehouse"
                                        color="primary"
                                        deleteIcon={<DoneIcon />}
                                        variant="outlined"
                                    />
                                )}
                                {pickUpLocation.isPickUpLocation && (
                                    <Chip
                                        className={classes.chip}
                                        avatar={<Avatar>P</Avatar>}
                                        label="Pick Up Location"
                                        color="primary"
                                        deleteIcon={<DoneIcon />}
                                        variant="outlined"
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img
                                src={`https://www.mapquestapi.com/staticmap/v5/map?key=${process.env.REACT_APP_MAPQUEST_KEY}&center=${location}&size=300,200@2x`}
                                alt="map"
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <Dialog open={editOpen} aria-labelledby="form-dialog-edit">
                <EditPickUpLocation handleDialogClose={handleEditClose} />
            </Dialog>
        </Container>
    )
}

export default PickUpLocationList
