import { useEffect } from 'react'
import { DialogTitle } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import ProductColorForm from './ProductColorForm'
import { getAllSkuNos } from '../../store/actions/productColorActions'

const EditProductColor = ({ handleDialogClose, productId }) => {
    const productColorState = useSelector((state) => state.productColor)
    const { productColor } = productColorState

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllSkuNos())
    }, [dispatch])

    return productColor ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Product Color</DialogTitle>
            <ProductColorForm
                handleDialogClose={handleDialogClose}
                initialValues={productColor}
                formType={FORM_TYPE_EDIT}
                productId={productId}
            />
        </>
    ) : null
}

export default EditProductColor
