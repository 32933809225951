import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import PicturePointForm from './PicturePointForm'
import { getAllPicturePositions } from '../../store/actions/picturePositionActions'

const AddPicturePoint = ({ handleDialogClose }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllPicturePositions())
    }, [dispatch])
    return (
        <>
            <DialogTitle id="form-dialog-title">
                Add New Picture Point
            </DialogTitle>
            <PicturePointForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
            />
        </>
    )
}

export default AddPicturePoint
