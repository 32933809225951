import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Typography } from '@material-ui/core'
import Layout from '../components/Layout'
import ReduxPagnation from '../components/ReduxPagnation'
import { CHANGE_CLIENTS_PAGE } from '../store/types'
import { getAllClients } from '../store/actions/clientActions'
import ClientSearch from '../components/client/ClientSearch'
import ClientInfos from '../components/client/ClientInfos'

const Clients = () => {
    const dispatch = useDispatch()
    const clientState = useSelector((state) => state.client)
    const { clients, pages, page, count, keyword } = clientState

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllClients(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_CLIENTS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <h2 style={{ textAlign: 'center' }}>Client List</h2>
                <ClientSearch displayKeyword={keyword} />
                <Typography variant="body2" gutterBottom>
                    Count: {count}
                </Typography>
                {clients && <ClientInfos clients={clients} />}
                <ReduxPagnation
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Clients
