import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import StyleForm from './StyleForm'

const EditStyle = ({ handleDialogClose }) => {
    const styleState = useSelector((state) => state.style)
    const { style } = styleState

    return style ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Style</DialogTitle>
            <StyleForm
                handleDialogClose={handleDialogClose}
                initialValues={style}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditStyle
