import { useEffect } from 'react'
import { Button, Grid, TextField } from '@material-ui/core'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { SEARCH_ORDERS } from '../../store/types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { orderStatusList } from '../../constant/formula'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

const OrderSearch = ({
    displayStatus,
    displayClient,
    displayOrderNo,
    displayFromDate,
    displayToDate,
}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [status, setStatus] = useState(0)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [client, setClient] = useState('')
    const [orderNo, setOrderNo] = useState('')

    useEffect(() => {
        setStatus(displayStatus ?? 0)
        setClient(displayClient ?? '')
        setOrderNo(displayOrderNo ?? '')
        setFromDate(displayFromDate ?? '')
        setToDate(displayToDate ?? '')
    }, [
        displayStatus,
        displayClient,
        displayOrderNo,
        displayFromDate,
        displayToDate,
    ])
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_ORDERS,
            payload: {
                searchStatus: status,
                searchClient: client,
                searchOrderNo: orderNo,
                searchFromDate: fromDate,
                searchToDate: toDate,
            },
        })
    }
    return (
        <div className={classes.margin}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={2}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Status
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value={0}>All status</MenuItem>
                                {orderStatusList.map((statusList) => (
                                    <MenuItem
                                        key={statusList.id}
                                        value={statusList.id}
                                    >
                                        {statusList.status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            id="orderNo"
                            label="Order No..."
                            value={orderNo}
                            onChange={(e) => setOrderNo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            id="client"
                            label="Client..."
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            id="fromDate"
                            label="From"
                            value={fromDate}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setFromDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            id="toDate"
                            label="To"
                            value={toDate}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setToDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.marginLeft}
                            type="submit"
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default OrderSearch
