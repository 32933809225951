import { useState } from 'react'
import {
    Dialog,
    TableCell,
    TableRow,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { DELETE_CONFIRM, PROMO_CATEGORY } from '../../constant/default'
import history from '../../history'
import moment from 'moment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import { useDispatch } from 'react-redux'
import {
    deletePromoCode,
    getOnePromoCode,
} from '../../store/actions/promoCodeActions'
import EditPromoCode from './EditPromoCode'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { makeStyles } from '@material-ui/core/styles'
import PageviewIcon from '@material-ui/icons/Pageview'
import { displayAmount } from '../../common/common'

const ITEM_HEIGHT = 48

const useStyles = makeStyles((theme) => ({
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    viewIcon: {
        color: theme.palette.primary.main,
    },
    paper: {
        padding: '20px',
        margin: '10px 0',
    },
}))

const PromoCodeList = ({ promoCode }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleEditOpen = (id) => {
        dispatch(getOnePromoCode(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const handleDelete = () => {
        if (window.confirm(DELETE_CONFIRM)) {
            dispatch(deletePromoCode(promoCode.id))
        }
        setAnchorEl(null)
    }

    return (
        <>
            <TableRow>
                <TableCell>{promoCode.promoName}</TableCell>
                <TableCell>{promoCode.promoCode}</TableCell>
                <TableCell>{PROMO_CATEGORY[promoCode.promoCategory]}</TableCell>
                <TableCell>
                    {displayAmount(promoCode.promoType, promoCode.typeMount)}
                </TableCell>
                <TableCell align="center">
                    {moment(promoCode.startDate).format('lll')}
                </TableCell>
                <TableCell align="center">
                    {moment(promoCode.endDate).format('lll')}
                </TableCell>
                <TableCell align="center">
                    {promoCode.used ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        ''
                    )}
                </TableCell>
                <TableCell align="center">
                    {promoCode.active ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>

                <TableCell>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem
                            selected={true}
                            onClick={() =>
                                history.push(`/promocode/${promoCode.id}`)
                            }
                        >
                            <ListItemIcon>
                                <PageviewIcon
                                    fontSize="small"
                                    className={classes.viewIcon}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="View"
                                className={classes.viewIcon}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleEditOpen(promoCode.id)}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </MenuItem>
                        {promoCode.orders.length === 0 && (
                            <MenuItem onClick={handleDelete}>
                                <ListItemIcon>
                                    <DeleteForeverIcon
                                        className={classes.deleteIcon}
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Delete"
                                    className={classes.deleteIcon}
                                />
                            </MenuItem>
                        )}
                    </Menu>
                </TableCell>
            </TableRow>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditPromoCode handleDialogClose={handleEditClose} />
            </Dialog>
        </>
    )
}

export default PromoCodeList
