import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import SelfAssembleForm from './SelfAssembleForm'

const EditSelfAssemble = ({ handleDialogClose }) => {
    const selfAssembleState = useSelector((state) => state.selfAssemble)
    const { selfAssemble } = selfAssembleState

    return selfAssemble ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Self Assemble</DialogTitle>
            <SelfAssembleForm
                handleDialogClose={handleDialogClose}
                initialValues={selfAssemble}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditSelfAssemble
