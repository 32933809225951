import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Select,
    TextField,
} from '@material-ui/core'

export const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
)

export const renderSelectField = ({
    input,
    name,
    label,
    meta: { touched, error },
    children,
    ...custom
}) => (
    <FormControl error={touched && error} style={{ width: '100%' }}>
        <InputLabel htmlFor={`${name}-native-simple`}>{label}</InputLabel>
        <Select
            native
            {...input}
            {...custom}
            inputProps={{
                name,
                id: `${name}-native-simple`,
            }}
        >
            {children}
        </Select>
        {renderFromHelper({ touched, error })}
    </FormControl>
)

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText>{touched && error}</FormHelperText>
    }
}

export const checkboxGroup = ({ label, required, options, input, meta }) =>
    options.map((option, index) => (
        <div key={index}>
            <FormControlLabel
                control={
                    <Checkbox
                        name={`${input.name}[${index}]`}
                        value={option.id}
                        color="primary"
                        checked={input.value.indexOf(option.id) !== -1}
                        onChange={(event) => {
                            const newValue = [...input.value]
                            if (event.target.checked) {
                                newValue.push(option.id)
                            } else {
                                newValue.splice(newValue.indexOf(option.id), 1)
                            }

                            return input.onChange(newValue)
                        }}
                    />
                }
                label={option.name}
            />
        </div>
    ))

export const designerGroup = ({ label, required, options, input, meta }) =>
    options.map((option, index) => (
        <div key={index}>
            <FormControlLabel
                control={
                    <Checkbox
                        name={`${input.name}[${index}]`}
                        value={option.id}
                        color="primary"
                        checked={input.value.indexOf(option.id) !== -1}
                        onChange={(event) => {
                            const newValue = [...input.value]
                            if (event.target.checked) {
                                newValue.push(option.id)
                            } else {
                                newValue.splice(newValue.indexOf(option.id), 1)
                            }

                            return input.onChange(newValue)
                        }}
                    />
                }
                label={`${option.firstName} ${option.lastName} (Procress: ${option.active}, Finished: ${option.finished})`}
            />
        </div>
    ))

export const renderCheckbox = ({ input, label }) => (
    <div>
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    checked={input.value ? true : false}
                    onChange={input.onChange}
                />
            }
            label={label}
        />
    </div>
)
