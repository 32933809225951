import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import ProductForm from '../components/product/ProductForm'
import { FORM_TYPE_EDIT } from '../constant/default'
import { getAllProductStyles } from '../store/actions/styleActions'
import { getAllProductTypes } from '../store/actions/productTypeActions'
import { getAllProductFunctions } from '../store/actions/productFunctionActions'
import { getOneProduct } from '../store/actions/productActions'

const EditProduct = ({ match }) => {
    const dispatch = useDispatch()
    const id = match.params.id
    useEffect(() => {
        dispatch(getAllProductStyles())
        dispatch(getAllProductTypes())
        dispatch(getAllProductFunctions())
        dispatch(getOneProduct(id))
    }, [dispatch, id])

    const productState = useSelector((state) => state.product)
    const { product } = productState

    const values = {
        ...product,
        productfunctions: product?.productfunctions?.map((func) => func.id),
        styleId: product?.style?.id,
        sizeId: product?.size?.id,
    }

    const preProductFunctions = product?.style?.category?.functionOption
    const preProductOption = product?.style?.category?.productOption
    const preProductType = product?.style?.category?.typeOption
    const preTypeId = product?.producttype?.id
    const preStyle = product?.style?.id
    const sizesList = product?.style?.category?.sizes

    return product ? (
        <Layout>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <ProductForm
                    initialValues={values}
                    formType={FORM_TYPE_EDIT}
                    preProductFunctions={preProductFunctions}
                    preProductType={preProductType}
                    preTypeId={preTypeId}
                    preStyle={preStyle}
                    sizesList={sizesList}
                    preProductOption={preProductOption}
                />
            </Container>
        </Layout>
    ) : null
}

export default EditProduct
