import { GET_ALL_PRODUCT_STYLES, GET_ALL_STYLES, GET_ONE_STYLE } from '../types'

const initialState = {
    styles: [],
    productStyles: [],
    style: {},
}

export const styleReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_STYLES:
            return {
                ...state,
                styles: payload,
            }
        case GET_ALL_PRODUCT_STYLES:
            return {
                ...state,
                productStyles: payload,
            }
        case GET_ONE_STYLE:
            return {
                ...state,
                style: payload,
            }
        default:
            return state
    }
}

export default styleReducer
