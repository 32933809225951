export const displayAmount = (type, amount) => {
    if (type === 1) return `$${amount}`
    if (type === 2) return `${amount}%`
    return null
}

export const modifyTypeName = (type) => {
    const typeList = {
        1: 'Add New',
        2: 'Edit',
        3: 'Delete',
    }
    return typeList[type]
}

export function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
        document.execCommand('copy')
    } catch (err) {
        console.error('Unable to copy to clipboard', err)
    }
    document.body.removeChild(textArea)
}

export const cartType = (card) => {
    if (card === 'VI') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672006019/cardLogo/visa_crfadw_u1blg4.svg"
                    alt="visa"
                    width="40px"
                />
            </span>
        )
    } else if (card === 'MC') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672006022/cardLogo/mastercard_xh3vxl_oqkca9.svg"
                    alt="mastercard"
                    width="40px"
                />
            </span>
        )
    } else if (card === 'AM') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672006026/cardLogo/am_amex_06_wfskku_xkwtvm.png"
                    alt="axcard"
                    width="40px"
                />
            </span>
        )
    } else if (card === 'NN') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672005983/cardLogo/181206_zfpotk.svg"
                    alt="discover"
                    width="40px"
                />
            </span>
        )
    } else {
        return card
    }
}
