import { errorsReturn } from '../errorsReturn'
import { GET_ALL_POLICIES, GET_ONE_POLICY } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { logout, renewUserToken } from './userActions'
import {
    POLICY_ADD_SUCCESSFULLY,
    POLICY_UPDATE_SUCCESSFULLY,
    POLICY_DELETE_SUCCESSFULLY,
} from '../../constant/default'

export const getAllPolicies = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`policy/listall`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ALL_POLICIES, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOnePolicy = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`policy/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_POLICY, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const createPolicy = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.post(`policy/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(POLICY_ADD_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllPolicies())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updatePolicy = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.put(`policy/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(POLICY_UPDATE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllPolicies())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deletePolicy = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.delete(`policy/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(POLICY_DELETE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllPolicies())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
