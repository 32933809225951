import React, { useState } from 'react'
import { Button, Dialog, Divider } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { green, red } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import AddPayment from '../AddPayment'
import EditPayment from '../EditPayment'
import moment from 'moment'
import { cartType } from '../../../common/common'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
})

const PaymentDisplay = ({ invoice, orderId }) => {
    const classes = useStyles()
    const userLogin = useSelector((state) => state.user)
    const { userInfo } = userLogin

    const [paymentOpen, setPaymentOpen] = useState(false)

    const handlePaymentOpen = () => {
        setPaymentOpen(true)
    }

    const handlePaymentClose = () => {
        setPaymentOpen(false)
    }

    const [paymentEditOpen, setPaymentEditOpen] = useState(false)
    const [editPayment, setEditPayment] = useState(null)

    const handlePaymentEditOpen = (payment) => {
        setPaymentEditOpen(true)
        setEditPayment(payment)
    }

    const handlePaymentEditClose = () => {
        setPaymentEditOpen(false)
    }

    return (
        <div className="order-shipping-box">
            <div className="order-info-detail-title">
                {/* <div>Payment</div> */}
                {invoice.amount >
                    parseFloat(
                        (
                            invoice?.orderpayments?.reduce((acc, payment) => {
                                if (payment.successful)
                                    return acc + payment.amount
                                return acc
                            }, 0) || 0
                        ).toFixed(2)
                    ) && (
                    <>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => handlePaymentOpen()}
                        >
                            Add Payment
                        </Button>
                        <Dialog
                            open={paymentOpen}
                            // onClose={handleEditClose}
                            aria-labelledby="form-dialog-edit"
                        >
                            <AddPayment
                                handleDialogClose={handlePaymentClose}
                                orderId={orderId}
                                invoiceId={invoice.id}
                            />
                        </Dialog>
                    </>
                )}
            </div>

            <Divider />
            {invoice?.orderpayments?.length > 0 && (
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        aria-label="customized table"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                <StyledTableCell align="center">
                                    Date
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Ref No
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{ width: '200px' }}
                                >
                                    Message
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Card Type
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Last 4 No
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Amount
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    User
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoice?.orderpayments?.map((payment) => (
                                <StyledTableRow
                                    key={payment.id}
                                    style={
                                        payment.successful
                                            ? { backgroundColor: '#cfd8dc' }
                                            : null
                                    }
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {payment.successful ? (
                                            <CheckCircleOutlineIcon
                                                style={{ color: green[500] }}
                                            />
                                        ) : (
                                            <BlockIcon
                                                style={{ color: red[500] }}
                                            />
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {payment.paymentType}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {moment(payment.paymentDate).format(
                                            'YYYY-MM-DD'
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {payment.refNo}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {payment.message}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {cartType(payment.cardType)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {payment.cardNo}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {payment.amount && `$${payment.amount}`}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {' '}
                                        {payment?.user?.firstName}{' '}
                                        {payment?.user?.lastName}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {' '}
                                        {payment?.user?.id === userInfo?.id && (
                                            <>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                        handlePaymentEditOpen(
                                                            payment
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Dialog
                                                    open={paymentEditOpen}
                                                    // onClose={handleEditClose}
                                                    aria-labelledby="form-dialog-edit"
                                                >
                                                    <EditPayment
                                                        handleDialogClose={
                                                            handlePaymentEditClose
                                                        }
                                                        orderId={orderId}
                                                        orderPayment={
                                                            editPayment
                                                        }
                                                        invoiceId={invoice.id}
                                                    />
                                                </Dialog>
                                            </>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    )
}

export default PaymentDisplay
