import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import { getAllCabinetDepthList } from '../../store/actions/cabinetDepthActions'
import CabinetDepthForm from './CabinetDepthForm'

const AddCabinetDepth = ({ handleDialogClose, productId }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllCabinetDepthList())
    }, [dispatch])

    return (
        <>
            <DialogTitle id="form-dialog-title">
                Add New Cabinet Depth
            </DialogTitle>
            <CabinetDepthForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
                productId={productId}
            />
        </>
    )
}

export default AddCabinetDepth
