import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import ProductPictureForm from './ProductPictureForm'

const AddProductPicture = ({
    handleDialogClose,
    productId,
    productColorId,
}) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Product Color</DialogTitle>
        <ProductPictureForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
            productId={productId}
            productColorId={productColorId}
        />
    </>
)

export default AddProductPicture
