import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import {
    createProductType,
    updateProductType,
} from '../../store/actions/productTypeActions'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const ProductTypeForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, formType } = props
    const submitForm = (formValues) => {
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updateProductType(formValues))
            : dispatch(createProductType(formValues))
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click{' '}
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}{' '}
                        button to finish.
                    </DialogContentText>
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Type Name"
                        name="name"
                        inputProps={{ maxLength: 50 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="picLink"
                        label="Pic Link"
                        name="picLink"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name', 'picLink']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'productTypeForm',
    validate,
    enableReinitialize: true,
})(ProductTypeForm)
