import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'
import { reduxForm, Field, change } from 'redux-form'
import { renderCheckbox, renderTextField } from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import {
    createProductColor,
    updateProductColor,
} from '../../store/actions/productColorActions'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const ProductColorForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const productColorState = useSelector((state) => state.productColor)
    const { allSkuNos, doneSkuNos } = productColorState
    const {
        handleSubmit,
        handleDialogClose,
        formType,
        productId,
        styleColorId,
    } = props
    const submitForm = (formValues) => {
        formValues['productId'] = productId
        formValues['styleColorId'] = styleColorId
        if (
            allSkuNos.filter((skuNo) => skuNo.product_sku === formValues.skuNo)
                .length > 0
        ) {
            formValues['apiStock'] = true
        } else {
            formValues['apiStock'] = false
        }
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updateProductColor(formValues))
            : dispatch(createProductColor(formValues))
    }

    const skuNos = allSkuNos
        ?.map((skuNo) => skuNo.product_sku)
        .filter((sNo) => doneSkuNos.indexOf(sNo) === -1)

    const handleChange = (e) => {
        const selectSkuNo = e.target.value
        const filterSku = allSkuNos?.filter(
            (skuNo) => skuNo.product_sku === selectSkuNo
        )
        if (filterSku?.length > 0) {
            dispatch(
                change(
                    'productColorForm',
                    'stock',
                    filterSku[0].product_inventory_count
                )
            )
        }
    }
    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click{' '}
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}{' '}
                        button to finish. {styleColorId}
                    </DialogContentText>
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="skuNo"
                        label="SKU No"
                        name="skuNo"
                        type="text"
                        onChange={(e) => handleChange(e)}
                        inputProps={{ maxLength: 90, list: 'sku_list' }}
                        component={renderTextField}
                    />

                    <datalist id="sku_list">
                        {skuNos &&
                            skuNos?.map((list) => (
                                <option key={list} value={list} />
                            ))}
                    </datalist>
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="price"
                        label="Price"
                        name="price"
                        type="number"
                        inputProps={{ maxLength: 20 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="stock"
                        label="Stock"
                        name="stock"
                        type="number"
                        inputProps={{ maxLength: 20 }}
                        component={renderTextField}
                    />
                    <Field
                        name="recommend"
                        component={renderCheckbox}
                        label="Recommend"
                    />
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['skuNo', 'price', 'stock']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'productColorForm',
    validate,
    enableReinitialize: true,
})(ProductColorForm)
