import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormGroup,
    FormLabel,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { checkboxGroup, renderTextField } from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import {
    createPicturePoint,
    updatePicturePoint,
} from '../../store/actions/picturePointActions'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const PicturePointForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, formType } = props
    const submitForm = (formValues) => {
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updatePicturePoint(formValues))
            : dispatch(createPicturePoint(formValues))
    }
    const picturePositionState = useSelector((state) => state.picturePosition)
    const { picturePositions } = picturePositionState

    const picturePositionsList = picturePositions?.map((picturePosition) => {
        return { id: picturePosition.id, name: picturePosition.position }
    })
    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click{' '}
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}{' '}
                        button to finish.
                    </DialogContentText>
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Pic Name"
                        name="name"
                        inputProps={{ maxLength: 50 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nameColor"
                        label="Pic Name Color (#000000)"
                        name="nameColor"
                        inputProps={{ maxLength: 10 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="picLink"
                        label="Pic Link"
                        name="picLink"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        fullWidth
                        id="remark"
                        label="Remark"
                        name="remark"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />

                    {picturePositionsList && picturePositionsList.length > 0 && (
                        <FormControl
                            component="fieldset"
                            className={classes.formControl}
                        >
                            <FormLabel component="legend">Positions</FormLabel>
                            <FormGroup row>
                                <Field
                                    required
                                    name="picturepositions"
                                    component={checkboxGroup}
                                    options={picturePositionsList}
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name', 'nameColor', 'picLink', 'picturepositions']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'picturePointForm',
    validate,
    enableReinitialize: true,
})(PicturePointForm)
