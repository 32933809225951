import { useEffect } from 'react'
import { DialogTitle } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import { getAllPicturePositions } from '../../store/actions/picturePositionActions'
import PicturePointForm from './PicturePointForm'

const EditPicturePoint = ({ handleDialogClose }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllPicturePositions())
    }, [dispatch])

    const picturePointState = useSelector((state) => state.picturePoint)
    const { picturePoint } = picturePointState
    const picturePointValues = {
        ...picturePoint,
        picturepositions: picturePoint?.picturepositions?.map(
            (picturePosition) => {
                return picturePosition.id
            }
        ),
    }
    return picturePoint ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Picture Point</DialogTitle>
            <PicturePointForm
                handleDialogClose={handleDialogClose}
                initialValues={picturePointValues}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditPicturePoint
