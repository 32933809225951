import { Container, Dialog, Grid, Paper } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { getOnePicturePoint } from '../store/actions/picturePointActions'
import { makeStyles } from '@material-ui/core/styles'
import AddPicturePointItem from '../components/picturePointItem/AddPicturePointItem'
import PicturePointItemList from '../components/picturePointItem/PicturePointItemList'
import PicturePointItemDisplay from '../components/picturePointItem/PicturePointItemDisplay'

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: '5px',
    },
    paper: {
        padding: theme.spacing(2),
    },
    box1: {
        marginTop: theme.spacing(2),
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
}))

const PicturePointItems = ({ match }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const id = match.params.id
    const [point, setPoint] = useState('')

    useEffect(() => {
        dispatch(getOnePicturePoint(id))
    }, [dispatch, id])

    const picturePointState = useSelector((state) => state.picturePoint)
    const { picturePoint } = picturePointState

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    const handlePicturePoint = (event) => {
        const pictureWidth = event.target.offsetWidth
        const pictureHeight = event.target.offsetHeight
        const pointX = event.nativeEvent.offsetX
        const pointY = event.nativeEvent.offsetY
        const xPresent = ((pointX / pictureWidth).toFixed(2) * 100).toFixed(2)
        const yPresent = ((pointY / pictureHeight).toFixed(2) * 100).toFixed(2)
        setPoint({ x: xPresent, y: yPresent })
        setDialogOpen(true)
    }

    return picturePoint ? (
        <Layout>
            <Container>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <div className="picture-point-area">
                                <div
                                    className="picture-point-name"
                                    style={{ color: picturePoint.nameColor }}
                                >
                                    <h3>{picturePoint.name}</h3>
                                </div>
                                {picturePoint.picturepointitems &&
                                    picturePoint.picturepointitems.length > 0 &&
                                    picturePoint.picturepointitems.map(
                                        (picturePointItem) => (
                                            <PicturePointItemDisplay
                                                key={picturePointItem.id}
                                                picturePointItem={
                                                    picturePointItem
                                                }
                                            />
                                        )
                                    )}

                                <img
                                    src={picturePoint.picLink}
                                    alt="picPoint"
                                    width="100%"
                                    onClick={(event) =>
                                        handlePicturePoint(event)
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {picturePoint.picturepointitems &&
                                picturePoint.picturepointitems.length > 0 &&
                                picturePoint.picturepointitems.map(
                                    (picturePointItem) => (
                                        <PicturePointItemList
                                            key={picturePointItem.id}
                                            picturePointItem={picturePointItem}
                                            picturePointId={picturePoint.id}
                                        />
                                    )
                                )}
                        </Grid>
                    </Grid>
                </Paper>
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddPicturePointItem
                        handleDialogClose={handleDialogClose}
                        point={point}
                        picturePointId={picturePoint.id}
                    />
                </Dialog>
            </Container>
        </Layout>
    ) : null
}

export default PicturePointItems
