import { TextField } from '@material-ui/core'

export const fildInput = (name, label, type, formik) => (
    <div style={{ paddingBottom: '10px' }}>
        <TextField
            fullWidth
            id={name}
            name={name}
            label={label}
            type={type}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
        />
    </div>
)
