import { useEffect } from 'react'
import { DialogTitle } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import GlassDoorForm from './GlassDoorForm'
import { getAllGlassDoorList } from '../../store/actions/glassDoorActions'

const EditGlassDoor = ({ handleDialogClose, productId }) => {
    const glassDoorState = useSelector((state) => state.glassDoor)
    const { glassDoor } = glassDoorState

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllGlassDoorList())
    }, [dispatch])

    return glassDoor ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Glass Door</DialogTitle>
            <GlassDoorForm
                handleDialogClose={handleDialogClose}
                initialValues={glassDoor}
                formType={FORM_TYPE_EDIT}
                productId={productId}
            />
        </>
    ) : null
}

export default EditGlassDoor
