import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import ProductSizeForm from './ProductSizeForm'

const EditProductSize = ({ handleDialogClose, productId }) => {
    const productMeasureState = useSelector((state) => state.productMeasure)
    const { productMeasure } = productMeasureState

    return productMeasure ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Product Size</DialogTitle>
            <ProductSizeForm
                handleDialogClose={handleDialogClose}
                initialValues={productMeasure}
                formType={FORM_TYPE_EDIT}
                productId={productId}
            />
        </>
    ) : null
}

export default EditProductSize
