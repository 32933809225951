import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const userInfoFromStorage = localStorage.getItem('HSAdminUserInfo')
    ? JSON.parse(localStorage.getItem('HSAdminUserInfo'))
    : null

const userTokenFromStorage = localStorage.getItem('HSAdminUserToken')
    ? JSON.parse(localStorage.getItem('HSAdminUserToken'))
    : null

const initialState = {
    user: {
        userInfo: userInfoFromStorage,
        userToken: userTokenFromStorage,
    },
}

const middleware = [thunk]

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store
