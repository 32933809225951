import { useEffect } from 'react'
import { DialogTitle } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import CabinetDepthForm from './CabinetDepthForm'
import { getAllCabinetDepthList } from '../../store/actions/cabinetDepthActions'

const EditCabinetDepth = ({ handleDialogClose, productId }) => {
    const cabinetDepthState = useSelector((state) => state.cabinetDepth)
    const { cabinetDepth } = cabinetDepthState

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllCabinetDepthList())
    }, [dispatch])

    return cabinetDepth ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Cabinet Depth</DialogTitle>
            <CabinetDepthForm
                handleDialogClose={handleDialogClose}
                initialValues={cabinetDepth}
                formType={FORM_TYPE_EDIT}
                productId={productId}
            />
        </>
    ) : null
}

export default EditCabinetDepth
