import { Button } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePickUp, getAllPickUps } from '../../store/actions/orderActions'
import PickUpList from './PickUpList'
import './pickUp.css'
import { getErrors } from '../../store/actions/errorActions'

const EditPickUpLocation = ({
    orderId,
    orderShippingId,
    pickUpLocationId,
    handleDialogClose,
}) => {
    const dispatch = useDispatch()

    const [pickUpAddress, setPickUpAddress] = useState(() => pickUpLocationId)
    const orderState = useSelector((state) => state.order)
    const { pickUps } = orderState

    useEffect(() => {
        if (!pickUps) {
            dispatch(getAllPickUps())
        }
    }, [dispatch, pickUps])

    const handleUpdate = () => {
        if (pickUpLocationId !== pickUpAddress) {
            const values = {
                orderId,
                id: orderShippingId,
                pickUpAddress,
            }
            dispatch(changePickUp(values))
            handleDialogClose()
        } else {
            dispatch(getErrors('Please choose a different location'))
        }
    }

    return (
        <div className="pickup-box">
            <div className="pickup-title">Choose a pick up location</div>

            <div className="pickup-address-box">
                {pickUps?.length > 0
                    ? pickUps.map((location) => (
                          <PickUpList
                              key={location.id}
                              location={location}
                              pickUpAddress={pickUpAddress}
                              setPickUpAddress={setPickUpAddress}
                          />
                      ))
                    : ''}
            </div>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleUpdate()}
                >
                    Update
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ marginLeft: '20px' }}
                    onClick={() => handleDialogClose()}
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default EditPickUpLocation
