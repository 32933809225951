import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormGroup,
    FormLabel,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import {
    checkboxGroup,
    renderCheckbox,
    renderSelectField,
    renderTextField,
} from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    createCategory,
    updateCategory,
} from '../../store/actions/categoryActions'
import { FORM_TYPE_EDIT, shippingMethodList } from '../../constant/default'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const CategoryForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, formType } = props
    const submitForm = (formValues) => {
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updateCategory(formValues))
            : dispatch(createCategory(formValues))
    }
    const sizeState = useSelector((state) => state.size)
    const { sizes } = sizeState

    const sizesList = sizes?.map((size) => {
        return { id: size.id, name: size.sizeValue }
    })
    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click{' '}
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}{' '}
                        button to finish.
                    </DialogContentText>
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="sequence"
                        label="Sequence"
                        name="sequence"
                        type="number"
                        inputProps={{ maxLength: 50 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        inputProps={{ maxLength: 50 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="displayName"
                        label="Display Name"
                        name="displayName"
                        inputProps={{ maxLength: 50 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        fullWidth
                        id="bigPicLink"
                        label="Large Pic Link"
                        name="bigPicLink"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="smallPicLink"
                        label="Small Pic Link"
                        name="smallPicLink"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        fullWidth
                        id="slogan"
                        label="Slogan"
                        name="slogan"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        fullWidth
                        id="url"
                        label="Url Link"
                        name="url"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        name="navDisplay"
                        component={renderCheckbox}
                        label="Display on Navbar"
                    />
                    <Field
                        name="homepageSmall"
                        component={renderCheckbox}
                        label="Homepage Small Pic"
                    />
                    <Field
                        name="productsPageBig"
                        component={renderCheckbox}
                        label="Products Page Big Pic"
                    />
                    <Field
                        name="productsPageSmall"
                        component={renderCheckbox}
                        label="Products Page Small Pic"
                    />
                    <Field
                        name="typeOption"
                        component={renderCheckbox}
                        label="Type Option"
                    />
                    <Field
                        name="functionOption"
                        component={renderCheckbox}
                        label="Function Option"
                    />
                    <Field
                        name="productCategory"
                        component={renderCheckbox}
                        label="Products Category"
                    />
                    <Field
                        name="picturePoint"
                        component={renderCheckbox}
                        label="Picture Point"
                    />
                    <Field
                        name="productOption"
                        component={renderCheckbox}
                        label="Product Option"
                    />
                    <Field
                        id="shippingMethod"
                        required
                        label="Shipping Method"
                        name="shippingMethod"
                        component={renderSelectField}
                    >
                        <option aria-label="None" />
                        {shippingMethodList?.map((method) => (
                            <option value={method.id} key={method.id}>
                                {method.name}
                            </option>
                        ))}
                    </Field>
                    {sizesList && sizesList.length > 0 && (
                        <FormControl
                            component="fieldset"
                            className={classes.formControl}
                        >
                            <FormLabel component="legend">Sizes</FormLabel>
                            <FormGroup row>
                                <Field
                                    name="sizes"
                                    component={checkboxGroup}
                                    options={sizesList}
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name', 'displayName', 'smallPicLink']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'categoryForm',
    validate,
    enableReinitialize: true,
})(CategoryForm)
