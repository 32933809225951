export const SHOW_LOADING = 'SHOW_LOADING'
export const GET_ERRORS = 'GET_ERRORS'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const USER_LOGOIN_SUCCESS = 'USER_LOGOIN_SUCCESS'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_UPDATE_INFO = 'USER_UPDATE_INFO'
export const USER_UPDATE_TOKEN = 'USER_UPDATE_TOKEN'
export const SHOW_USER_SIGN = 'SHOW_USER_SIGN'
export const RENEW_USER_TOKEN = 'RENEW_USER_TOKEN'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ONE_USER = 'GET_ONE_USER'
export const SEARCH_USERS = 'SEARCH_USERS'
export const CHANGE_USERS_PAGE = 'CHANGE_USERS_PAGE'

// Category
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_ONE_CATEGORY = 'GET_ONE_CATEGORY'

// Size
export const GET_ALL_SIZES = 'GET_ALL_SIZES'
export const GET_ONE_SIZE = 'GET_ONE_SIZE'

// Style
export const GET_ALL_STYLES = 'GET_ALL_STYLES'
export const GET_ALL_PRODUCT_STYLES = 'GET_ALL_PRODUCT_STYLES'
export const GET_ONE_STYLE = 'GET_ONE_STYLE'

// StyleColor
export const GET_ONE_STYLECOLOR = 'GET_ONE_STYLECOLOR'

// Product type
export const GET_ALL_PRODUCTTYPES = 'GET_ALL_PRODUCTTYPES'
export const GET_ONE_PRODUCTTYPE = 'GET_ONE_PRODUCTTYPE'

// Product function
export const GET_ALL_PRODUCTFUNCTIONS = 'GET_ALL_PRODUCTFUNCTIONS'
export const GET_ONE_PRODUCTFUNCTION = 'GET_ONE_PRODUCTFUNCTION'

// Product

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_ONE_PRODUCT = 'GET_ONE_PRODUCT'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const CHANGE_PRODUCTS_PAGE = 'CHANGE_PRODUCTS_PAGE'

// Produce measure

export const GET_ONE_PRODUCTMEASURE = 'GET_ONE_PRODUCTMEASURE'

// Product color
export const GET_ALL_PRODUCTCOLORS = 'GET_ALL_PRODUCTCOLORS'
export const GET_ONE_PRODUCTCOLOR = 'GET_ONE_PRODUCTCOLOR'
export const SEARCH_PRODUCTCOLORS = 'SEARCH_PRODUCTCOLORS'
export const CHANGE_PRODUCTCOLORS_PAGE = 'CHANGE_PRODUCTCOLORS_PAGE'
export const GET_ALL_SKUNO = 'GET_ALL_SKUNO'

// Product Picture
export const GET_ONE_PRODUCTPICTURE = 'GET_ONE_PRODUCTPICTURE'

// Picture position
export const GET_ALL_PICTUREPOSITIONS = 'GET_ALL_PICTUREPOSITIONS'
export const GET_ONE_PICTUREPOSITION = 'GET_ONE_PICTUREPOSITION'

// Picture point
export const GET_ALL_PICTUREPOINTS = 'GET_ALL_PICTUREPOINTS'
export const GET_ONE_PICTUREPOINT = 'GET_ONE_PICTUREPOINT'

// Picture pointItem
export const GET_ONE_PICTUREPOINTITEM = 'GET_ONE_PICTUREPOINTITEM'

// SelfAssemble
export const GET_ALL_SELFASSEMBLES = 'GET_ALL_SELFASSEMBLES'
export const GET_ONE_SELFASSEMBLE = 'GET_ONE_SELFASSEMBLE'

// SelfAssemblePorcess
export const GET_ONE_SELFASSEMBLEPROCESS = 'GET_ONE_SELFASSEMBLEPROCESS'

// Q&A Category
export const GET_ALL_QNACATEGORIES = 'GET_ALL_QNACATEGORIES'
export const GET_ONE_QNACATEGORY = 'GET_ONE_QNACATEGORY'

// Q&A Item
export const GET_ONE_QNAITEM = 'GET_ONE_QNAITEM'

// Warranty
export const GET_ALL_WARRANTIES = 'GET_ALL_WARRANTIES'
export const GET_ONE_WARRANTY = 'GET_ONE_WARRANTY'

// Pick up location
export const GET_ALL_PICKUPLOCATIONS = 'GET_ALL_PICKUPLOCATIONS'
export const GET_ONE_PICKUPLOCATION = 'GET_ONE_PICKUPLOCATION'

// Head Office
export const GET_ALL_HEADOFFICES = 'GET_ALL_HEADOFFICES'
export const GET_ONE_HEADOFFICE = 'GET_ONE_HEADOFFICE'

// Team Member
export const GET_ALL_TEAMMEMBERS = 'GET_ALL_TEAMMEMBERS'
export const GET_ONE_TEAMMEMBER = 'GET_ONE_TEAMMEMBER'

// Home Page
export const GET_ALL_HOMEPAGES = 'GET_ALL_HOMEPAGES'
export const GET_ONE_HOMEPAGE = 'GET_ONE_HOMEPAGE'

// Policy
export const GET_ALL_POLICIES = 'GET_ALL_POLICIES'
export const GET_ONE_POLICY = 'GET_ONE_POLICY'

// Client
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS'
export const GET_ONE_CLIENT = 'GET_ONE_CLIENT'
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS'
export const CHANGE_CLIENTS_PAGE = 'CHANGE_CLIENTS_PAGE'

// Designer
export const GET_ALL_DESIGNERS = 'GET_ALL_DESIGNERS'
export const GET_ONE_DESIGNER = 'GET_ONE_DESIGNER'
export const SEARCH_DESIGNERS = 'SEARCH_DESIGNERS'
export const CHANGE_DESIGNERS_PAGE = 'CHANGE_DESIGNERS_PAGE'

// Order
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ALL_PICKUPS = 'GET_ALL_PICKUPS'
export const GET_ONE_ORDER = 'GET_ONE_ORDER'
export const SEARCH_ORDERS = 'SEARCH_ORDERS'
export const CHANGE_ORDERS_PAGE = 'CHANGE_ORDERS_PAGE'

// Cabinet Option
export const GET_ALL_CABINETOPTIONS = 'GET_ALL_CABINETOPTIONS'
export const GET_ONE_CABINETOPTION = 'GET_ONE_CABINETOPTION'

// Glass Doors
export const GET_ONE_GLASSDOOR = 'GET_ONE_GLASSDOOR'
export const GET_ALL_GLASSDOORLIST = 'GET_ALL_GLASSDOORLIST'

// Cabinet Depth
export const GET_ONE_CABINETDEPTH = 'GET_ONE_CABINETDEPTH'
export const GET_ALL_CABINETDEPTHLIST = 'GET_ALL_CABINETDEPTHLIST'

// Client Request Design
export const GET_ALL_REQUEST_DESIGNS = 'GET_ALL_REQUEST_DESIGNS'
export const SEARCH_REQUEST_DESIGNS = 'SEARCH_REQUEST_DESIGNS'
export const CHANGE_REQUEST_DESIGNS_PAGE = 'CHANGE_REQUEST_DESIGNS_PAGE'
export const GET_ONE_REQUEST_DESIGN = 'GET_ONE_REQUEST_DESIGN'
export const GET_ALL_DESIGNERS_NAME = 'GET_ALL_DESIGNERS_NAME'

// Client design saved
export const GET_ALL_CLIENT_DESIGN_SAVE = 'GET_ALL_CLIENT_DESIGN_SAVE'
export const GET_ONE_CLIENT_DESIGN_SAVE = 'GET_ONE_CLIENT_DESIGN_SAVE'
export const SEARCH_CLIENT_DESIGN_SAVE = 'SEARCH_CLIENT_DESIGN_SAVE'
export const CHANGE_CLIENT_DESIGN_SAVE_PAGE = 'CHANGE_CLIENT_DESIGN_SAVE_PAGE'

// Price Match
export const GET_ALL_PRICE_MATCH = 'GET_ALL_PRICE_MATCH'
export const GET_ONE_PRICE_MATCH = 'GET_ONE_PRICE_MATCH'
export const SEARCH_PRICE_MATCH = 'SEARCH_PRICE_MATCH'
export const CHANGE_PRICE_MATCH_PAGE = 'CHANGE_PRICE_MATCH_PAGE'

// Promo Code
export const GET_ALL_PROMO_CODES = 'GET_ALL_PROMO_CODES'
export const GET_ONE_PROMO_CODE = 'GET_ONE_PROMO_CODE'
export const SEARCH_PROMO_CODE = 'SEARCH_PROMO_CODE'
export const CHANGE_PROMO_CODE_PAGE = 'CHANGE_PROMO_CODE_PAGE'
