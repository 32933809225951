import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Dialog, Grid, Paper } from '@material-ui/core'
import Layout from '../components/Layout'
import {
    getAllSelfAssembles,
    getOneSelfAssemble,
} from '../store/actions/selfAssembleActions'
import AddSelfAssemble from '../components/selfAssemble/AddSelfAssemble'
import AddIcon from '@material-ui/icons/Add'
import SelfAssembleList from '../components/selfAssemble/SelfAssembleList'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(3),
    },
}))

const SelfAssembles = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const selfAssembleState = useSelector((state) => state.selfAssemble)
    const { selfAssembles, selfAssemble } = selfAssembleState

    useEffect(() => {
        dispatch(getAllSelfAssembles())
    }, [dispatch])

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    return (
        <Layout>
            <Container component="main">
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Self Assemble
                    </Button>
                </Box>
                <Grid container spacing={2} className={classes.container}>
                    {selfAssembles &&
                        selfAssembles.map((selfAssemble1) => {
                            const selectedCss =
                                selfAssemble1.id === selfAssemble?.id
                                    ? 'product-type-box-selected'
                                    : null
                            return (
                                <Grid item xs={3} key={selfAssemble1.id}>
                                    <Paper>
                                        <div
                                            style={{
                                                padding: '20px',
                                                cursor: 'pointer',
                                            }}
                                            className={`product-type ${selectedCss}`}
                                            onClick={() =>
                                                dispatch(
                                                    getOneSelfAssemble(
                                                        selfAssemble1.id
                                                    )
                                                )
                                            }
                                        >
                                            <div className="div-flex-center">
                                                <img
                                                    src={selfAssemble1.picLink}
                                                    width="80%"
                                                    alt="pic link"
                                                />
                                            </div>
                                            <div
                                                style={{ textAlign: 'center' }}
                                            >
                                                Series {selfAssemble1.seriesNo}
                                            </div>
                                        </div>
                                    </Paper>
                                </Grid>
                            )
                        })}
                </Grid>
                {selfAssemble && <SelfAssembleList />}
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddSelfAssemble handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default SelfAssembles
