import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { renderCheckbox, renderTextField } from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import {
    createHomePage,
    updateHomePage,
} from '../../store/actions/homePageActions'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const HomePageForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, formType } = props
    const submitForm = (formValues) => {
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updateHomePage(formValues))
            : dispatch(createHomePage(formValues))
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click{' '}
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}{' '}
                        button to finish.
                    </DialogContentText>
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="serialNo"
                        label="Serial No"
                        name="serialNo"
                        type="number"
                        inputProps={{ maxLength: 5 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        type="text"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        type="text"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="button"
                        label="Button"
                        name="button"
                        inputProps={{ maxLength: 100 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        fullWidth
                        id="picLink"
                        label="Pic Link"
                        name="picLink"
                        inputProps={{ maxLength: 250 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        fullWidth
                        id="mobilePicLink"
                        label="Mobile Pic Link"
                        name="mobilePicLink"
                        inputProps={{ maxLength: 250 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        fullWidth
                        id="url"
                        label="Url"
                        name="url"
                        inputProps={{ maxLength: 200 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        fullWidth
                        id="bgcolor"
                        label="Background Color"
                        name="bgcolor"
                        inputProps={{ maxLength: 20 }}
                        component={renderTextField}
                    />
                    <Field
                        name="active"
                        component={renderCheckbox}
                        label="Active"
                    />
                    <Field
                        name="topAligned"
                        component={renderCheckbox}
                        label="Top Aligned"
                    />
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'serialNo',
        'title',
        'description',
        'button',
        'picLink',
        'url',
        'bgcolor',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'homePageForm',
    validate,
    enableReinitialize: true,
})(HomePageForm)
