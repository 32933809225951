import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import FreightForm from './FreightForm'

const EditFreight = ({ handleDialogClose }) => {
    const orderState = useSelector((state) => state.order)
    const { order } = orderState

    const values = {
        status: 3,
        courier: order.ordershipping.courier,
        trackingNo: order.ordershipping.trackingNo,
    }

    return order ? (
        <>
            <DialogTitle id="form-dialog-title">Set Freight</DialogTitle>
            <FreightForm
                handleDialogClose={handleDialogClose}
                initialValues={values}
                orderId={order.id}
            />
        </>
    ) : null
}

export default EditFreight
