import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import HomePageForm from './HomePageForm'

const EditHomePage = ({ handleDialogClose }) => {
    const homePageState = useSelector((state) => state.homePage)
    const { homePage } = homePageState

    return homePage ? (
        <>
            <DialogTitle id="form-dialog-title">Edit Home Page</DialogTitle>
            <HomePageForm
                handleDialogClose={handleDialogClose}
                initialValues={homePage}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditHomePage
