import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import GlassDoorForm from './GlassDoorForm'
import { getAllGlassDoorList } from '../../store/actions/glassDoorActions'

const AddGlassDoor = ({ handleDialogClose, productId }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllGlassDoorList())
    }, [dispatch])

    return (
        <>
            <DialogTitle id="form-dialog-title">Add New Glass Door</DialogTitle>
            <GlassDoorForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
                productId={productId}
            />
        </>
    )
}

export default AddGlassDoor
