import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Grid,
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DELETE_CONFIRM, ITEM_HEIGHT } from '../../constant/default'
import {
    deleteGlassDoor,
    getOneGlassDoor,
} from '../../store/actions/glassDoorActions'
import EditGlassDoor from './EditGlassDoor'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
        marginBottom: 12,
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    gridStyle: {
        marginTop: theme.spacing(2),
        borderTop: 'solid 1px #ccc',
    },
}))

const GlassDoorList = ({ glassDoor, productId, productColorOnline }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = (id) => {
        dispatch(getOneGlassDoor(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Grid container alignItems="center" className={classes.gridStyle}>
                <Grid item xs={8}>
                    {glassDoor.name}
                </Grid>
                <Grid
                    item
                    xs={2}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    + ${glassDoor.addPrice}
                </Grid>
                <Grid
                    item
                    xs={2}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    {productColorOnline === false && (
                        <>
                            <IconButton
                                aria-label="more"
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() => handleEditOpen(glassDoor.id)}
                                >
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        if (window.confirm(DELETE_CONFIRM)) {
                                            dispatch(
                                                deleteGlassDoor(
                                                    glassDoor.id,
                                                    productId
                                                )
                                            )
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteForeverIcon
                                            className={classes.deleteIcon}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Delete"
                                        className={classes.deleteIcon}
                                    />
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Grid>
            </Grid>
            <Dialog open={editOpen} aria-labelledby="form-dialog-edit">
                <EditGlassDoor
                    handleDialogClose={handleEditClose}
                    productId={productId}
                />
            </Dialog>
        </>
    )
}

export default GlassDoorList
