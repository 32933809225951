import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import {
    createCabinetDepth,
    updateCabinetDepth,
} from '../../store/actions/cabinetDepthActions'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const CabinetDepthForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, formType, productId } = props

    const cabinetDepthState = useSelector((state) => state.cabinetDepth)
    const { cabinetDepthList } = cabinetDepthState

    const submitForm = (formValues) => {
        formValues['productId'] = productId
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updateCabinetDepth(formValues))
            : dispatch(createCabinetDepth(formValues))
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click{' '}
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}{' '}
                        button to finish.
                    </DialogContentText>
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        inputProps={{
                            maxLength: 200,
                            list: 'cabinetDepth_list',
                        }}
                        component={renderTextField}
                    />
                    <datalist id="cabinetDepth_list">
                        {cabinetDepthList &&
                            cabinetDepthList?.map((list) => (
                                <option key={list.id} value={list.name} />
                            ))}
                    </datalist>
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="addPrice"
                        label="Add Price"
                        name="addPrice"
                        type="number"
                        inputProps={{ maxLength: 20 }}
                        component={renderTextField}
                    />
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name', 'addPrice']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'cabinetDepthForm',
    validate,
    enableReinitialize: true,
})(CabinetDepthForm)
