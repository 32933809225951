import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Dialog, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Layout from '../components/Layout'
import { getAllPicturePoints } from '../store/actions/picturePointActions'
import AddPicturePoint from '../components/picturePoint/AddPicturePoint'
import PicturePointList from '../components/picturePoint/PicturePointList'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}))

const PicturePoints = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const picturePointState = useSelector((state) => state.picturePoint)
    const { picturePoints } = picturePointState

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        dispatch(getAllPicturePoints())
    }, [dispatch])

    return (
        <Layout>
            <Container>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Picture Point
                    </Button>
                </Box>
                <Grid container spacing={2}>
                    {/* array of JSX items */}
                    {picturePoints &&
                        picturePoints.map((picturePoint) => (
                            <Grid xs={12} item key={picturePoint.id}>
                                <PicturePointList picturePoint={picturePoint} />
                            </Grid>
                        ))}
                </Grid>
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddPicturePoint handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default PicturePoints
