import { useDispatch } from 'react-redux'
import Layout from '../components/Layout'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { reduxForm, Field } from 'redux-form'
import {
    renderSelectField,
    renderTextField,
} from '../components/ReduxFormInputs'
import { addNewUser } from '../store/actions/userActions'
import { userRoleList } from '../constant/default'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))
const AddUser = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit } = props
    const classes = useStyles()

    const submitForm = (formValues) => {
        // console.log(formValues)
        dispatch(addNewUser(formValues))
    }
    return (
        <Layout>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <PersonAddIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Add New User
                    </Typography>
                    <form
                        onSubmit={handleSubmit(submitForm)}
                        className={classes.form}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    name="firstName"
                                    autoComplete="fname"
                                    inputProps={{ maxLength: 50 }}
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                    inputProps={{ maxLength: 50 }}
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    inputProps={{ maxLength: 100 }}
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    inputProps={{ maxLength: 100 }}
                                    autoComplete="current-password"
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="phone"
                                    label="Phone"
                                    name="phone"
                                    autoComplete="phone"
                                    inputProps={{ maxLength: 20 }}
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="address"
                                    label="Address"
                                    name="address"
                                    autoComplete="address"
                                    inputProps={{ maxLength: 200 }}
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    id="role"
                                    required
                                    label="Role"
                                    name="role"
                                    component={renderSelectField}
                                >
                                    <option aria-label="None" />
                                    {userRoleList.map((role) => (
                                        <option
                                            value={role.value}
                                            key={role.value}
                                        >
                                            {role.displayName}
                                        </option>
                                    ))}
                                </Field>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign Up
                        </Button>
                    </form>
                </div>
            </Container>
        </Layout>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'firstName',
        'lastName',
        'email',
        'password',
        'role',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

export default reduxForm({
    form: 'addUserForm',
    validate,
})(AddUser)
