import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import SizeForm from './SizeForm'

const AddSize = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Size</DialogTitle>
        <SizeForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddSize
