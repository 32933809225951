import { errorsReturn } from '../errorsReturn'
import { GET_ALL_PICTUREPOSITIONS, GET_ONE_PICTUREPOSITION } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { logout, renewUserToken } from './userActions'
import {
    PICTUREPOSITION_ADD_SUCCESSFULLY,
    PICTUREPOSITION_UPDATE_SUCCESSFULLY,
    PICTUREPOSITION_DELETE_SUCCESSFULLY,
} from '../../constant/default'

export const getAllPicturePositions = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`pictureposition/listall`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ALL_PICTUREPOSITIONS, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOnePicturePosition = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`pictureposition/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_PICTUREPOSITION, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const createPicturePosition = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.post(`pictureposition/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PICTUREPOSITION_ADD_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllPicturePositions())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updatePicturePosition = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.put(
            `pictureposition/update/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PICTUREPOSITION_UPDATE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllPicturePositions())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deletePicturePosition = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.delete(`pictureposition/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(createMessage(PICTUREPOSITION_DELETE_SUCCESSFULLY))
        dispatch(controlLoading(false))
        dispatch(getAllPicturePositions())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
