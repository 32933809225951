import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import PickUpLocationForm from './PickUpLocationForm'

const AddPickUpLocation = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Location</DialogTitle>
        <PickUpLocationForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddPickUpLocation
