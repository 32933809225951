import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Typography, Dialog, Box, Button } from '@material-ui/core'
import Layout from '../components/Layout'
import ReduxPagnation from '../components/ReduxPagnation'
import { CHANGE_PROMO_CODE_PAGE } from '../store/types'
import { getAllPromoCodes } from '../store/actions/promoCodeActions'
import PromoCodeSearch from '../components/promoCode/PromoCodeSearch'
import PromoCodeInfo from '../components/promoCode/PromoCodeInfo'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import AddPromoCode from '../components/promoCode/AddPromoCode'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}))

const PromoCodes = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const promoCodeState = useSelector((state) => state.promoCode)
    const { promoCodes, pages, page, count, keyword } = promoCodeState
    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllPromoCodes(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_PROMO_CODE_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <h2 style={{ textAlign: 'center' }}>Promo Code List</h2>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Promo Code
                    </Button>
                </Box>
                <PromoCodeSearch displayKeyword={keyword} />
                <Typography variant="body2" gutterBottom>
                    Count: {count}
                </Typography>
                {promoCodes && <PromoCodeInfo promoCodes={promoCodes} />}
                <ReduxPagnation
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddPromoCode handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default PromoCodes
