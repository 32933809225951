import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import ProductPictureForm from './ProductPictureForm'

const EditProductPicture = ({ handleDialogClose, productId }) => {
    const productPictureState = useSelector((state) => state.productPicture)
    const { productPicture } = productPictureState

    return productPicture ? (
        <>
            <DialogTitle id="form-dialog-title">
                Edit Product Picture
            </DialogTitle>
            <ProductPictureForm
                handleDialogClose={handleDialogClose}
                initialValues={productPicture}
                formType={FORM_TYPE_EDIT}
                productId={productId}
            />
        </>
    ) : null
}

export default EditProductPicture
