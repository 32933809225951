import {
    CHANGE_CLIENT_DESIGN_SAVE_PAGE,
    GET_ALL_CLIENT_DESIGN_SAVE,
    GET_ONE_CLIENT_DESIGN_SAVE,
    SEARCH_CLIENT_DESIGN_SAVE,
} from '../types'

const initialState = {
    clientDesignSaves: [],
    clientDesignSave: {},
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

const clientDesignSaveReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_CLIENT_DESIGN_SAVE:
            return {
                ...state,
                clientDesignSaves: payload.clientDesignSaves,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                clientDesignSave: null,
            }
        case GET_ONE_CLIENT_DESIGN_SAVE:
            return {
                ...state,
                clientDesignSave: payload,
            }
        case SEARCH_CLIENT_DESIGN_SAVE:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_CLIENT_DESIGN_SAVE_PAGE:
            return {
                ...state,
                page: payload,
            }
        default:
            return state
    }
}

export default clientDesignSaveReducer
