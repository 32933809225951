import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_USERS,
    USER_LOGOIN_SUCCESS,
    USER_LOGOUT,
    USER_UPDATE_TOKEN,
} from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import history from '../../history'
import { createMessage } from './messageActions'
import {
    USER_ADD_SUCCESSSFULLY,
    USER_UPDATE_SUCCESSSFULLY,
} from '../../constant/default'

export const login = (values) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const { data } = await request.post('auth/login', values, config)
        localStorage.setItem('HSAdminUserInfo', JSON.stringify(data.data.user))
        localStorage.setItem(
            'HSAdminUserToken',
            JSON.stringify(data.data.token)
        )
        dispatch({
            type: USER_LOGOIN_SUCCESS,
            payload: {
                userInfo: data.data.user,
                userToken: data.data.token,
            },
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const renewUserToken = (values) => (dispatch) => {
    const newTokenValue = values.headers.authorization
    dispatch({
        type: USER_UPDATE_TOKEN,
        payload: newTokenValue,
    })
}

export const getAllUsers =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.get(
                `user/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_USERS,
                payload: {
                    users: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const logout = () => (dispatch) => {
    localStorage.removeItem('HSAdminUserInfo')
    localStorage.removeItem('HSAdminUserToken')
    dispatch({ type: USER_LOGOUT })
}

export const updateOneUser = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken, page, keyword },
        } = getState()
        let res = await request.put(`user/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(createMessage(USER_UPDATE_SUCCESSSFULLY))
        dispatch(getAllUsers(keyword, page))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addNewUser = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.post(`user/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(createMessage(USER_ADD_SUCCESSSFULLY))
        history.push('/users')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const userUpdateProfile = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.put(`user/profileupdate/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        localStorage.setItem('HSAdminUserInfo', JSON.stringify(res.data.data))
        dispatch({
            type: USER_LOGOIN_SUCCESS,
            payload: {
                userInfo: res.data.data,
                userToken: res.headers.authorization,
            },
        })
        dispatch(controlLoading(false))
        dispatch(createMessage(USER_UPDATE_SUCCESSSFULLY))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
