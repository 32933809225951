import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import HomePageForm from './HomePageForm'

const AddHomePage = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Home Page</DialogTitle>
        <HomePageForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddHomePage
