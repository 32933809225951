import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import StyleColorForm from './StyleColorForm'

const AddStyleColor = ({ handleDialogClose, styleId }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Style Color</DialogTitle>
        <StyleColorForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
            styleId={styleId}
        />
    </>
)

export default AddStyleColor
