import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { getOnePriceMatch } from '../store/actions/priceMatchActions'
import { Container, Grid, Button } from '@material-ui/core'
import history from '../history'
import { GET_ONE_PRICE_MATCH } from '../store/types'

const PriceMatch = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()
    const priceMatchState = useSelector((state) => state.priceMatch)
    const { priceMatch } = priceMatchState

    useEffect(() => {
        dispatch(getOnePriceMatch(id))
        return () => {
            dispatch({ type: GET_ONE_PRICE_MATCH, payload: null })
        }
    }, [dispatch, id])
    return (
        <Layout>
            {priceMatch ? (
                <>
                    <Container>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                onClick={() => history.goBack()}
                                style={{ margin: '20px 0' }}
                            >
                                Back
                            </Button>
                        </Grid>
                        <h3>Price Match Info</h3>

                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                First Name:
                            </Grid>
                            <Grid item xs={8}>
                                {priceMatch.firstName}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                Last Name:
                            </Grid>
                            <Grid item xs={8}>
                                {priceMatch.lastName}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                Email:
                            </Grid>
                            <Grid item xs={8}>
                                {priceMatch.email}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                Phone:
                            </Grid>
                            <Grid item xs={8}>
                                {priceMatch.phone}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                Itemized Detailed Quote:
                            </Grid>
                            <Grid item xs={8}>
                                {priceMatch.quote}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                Brand of Cabinets:
                            </Grid>
                            <Grid item xs={8}>
                                {priceMatch.brand}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                Upload an image/screenshot:
                            </Grid>
                            <Grid item xs={8}>
                                <a
                                    href={priceMatch.picLink}
                                    target="_black"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={priceMatch.picLink}
                                        alt="pic"
                                        style={{
                                            width: '200px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </a>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                Description:
                            </Grid>
                            <Grid item xs={8}>
                                <p style={{ whiteSpace: 'pre-line' }}>
                                    {priceMatch.description}
                                </p>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default PriceMatch
