import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Dialog } from '@material-ui/core'
import Layout from '../components/Layout'
import AddIcon from '@material-ui/icons/Add'
import PickUpLocationList from '../components/pickUpLocation/PickUpLocationList'
import AddPickUpLocation from '../components/pickUpLocation/AddPickUpLocation'
import { getAllPickUpLocations } from '../store/actions/pickUpLocationActions'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(3),
    },
}))

const PickUpLocations = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const pickUpLocationState = useSelector((state) => state.pickUpLocation)
    const { pickUpLocations } = pickUpLocationState

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        dispatch(getAllPickUpLocations())
    }, [dispatch])
    return (
        <Layout>
            <Container>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<AddIcon />}
                        onClick={handleDialogOpen}
                    >
                        Add New Location
                    </Button>
                </Box>
                {pickUpLocations &&
                    pickUpLocations.map((pickUpLocation) => (
                        <PickUpLocationList
                            pickUpLocation={pickUpLocation}
                            key={pickUpLocation.id}
                        />
                    ))}
                <Dialog
                    open={dialogOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AddPickUpLocation handleDialogClose={handleDialogClose} />
                </Dialog>
            </Container>
        </Layout>
    )
}

export default PickUpLocations
