import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import SelfAssembleProcessForm from './SelfAssembleProcessForm'

const AddSelfAssembleProcess = ({ handleDialogClose, selfAssembleId }) => (
    <>
        <DialogTitle id="form-dialog-title">
            Add New Self Assemble Process
        </DialogTitle>
        <SelfAssembleProcessForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
            selfAssembleId={selfAssembleId}
        />
    </>
)

export default AddSelfAssembleProcess
