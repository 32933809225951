import { Dialog } from '@material-ui/core'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import request from '../../config/request'
import EditItem from './EditItem'
import EditIcon from '@material-ui/icons/Edit'
import { blue, red } from '@material-ui/core/colors'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DELETE_CONFIRM, promoCodeList } from '../../constant/default'
import { getErrors } from '../../store/actions/errorActions'
import { controlLoading } from '../../store/actions/loadingActions'
import { logout, renewUserToken } from '../../store/actions/userActions'
import { newToken } from '../../admin/newtoken'
import { errorsReturn } from '../../store/errorsReturn'
import { getOneOrder } from '../../store/actions/orderActions'
import { createMessage } from '../../store/actions/messageActions'

const OrderInfoItem = ({
    item,
    orderId,
    orderStatus,
    promoCodeName,
    promoRate,
}) => {
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)
    const [editItem, setEditItem] = useState(null)
    const { userToken } = useSelector((state) => state.user)

    const handleEditOpen = (item) => {
        setEditOpen(true)
        setEditItem(item)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const deleteOrderItem = async (id) => {
        try {
            const res = await request.delete(`/order/deleteorderitem/${id}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(orderId))
            dispatch(createMessage('Order Item Delete successful'))
        } catch (e) {
            console.log(e)
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

    const itemDisplay = (item) => {
        let result = (
            <div className="item-price-area">
                ${(item.price * item.quantity).toFixed(2)}
            </div>
        )
        if (promoCodeName === promoCodeList.BOGO) {
            result = (
                <div className="item-price-area">
                    <div>
                        $
                        {(
                            item.price * item.quantity -
                            item.price * item.promoActive
                        ).toFixed(2)}
                    </div>
                    {item.promoActive > 0 && (
                        <div className="item-promo-area">
                            - ${(item.price * item.promoActive).toFixed(2)}
                        </div>
                    )}
                </div>
            )
        }
        if (promoCodeName === promoCodeList.BMSM2022) {
            result = (
                <div className="item-price-area">
                    <div>
                        $
                        {(
                            item.quantity *
                            ((item.price * (100 - promoRate)) / 100)
                        ).toFixed(2)}
                    </div>
                    {item.promoActive > 0 && (
                        <div className="item-promo-area">
                            -$
                            {(
                                item.quantity *
                                ((item.price * promoRate) / 100)
                            ).toFixed(2)}
                        </div>
                    )}
                </div>
            )
        }
        return result
    }
    return (
        <div className="order-item-box">
            <div className="item-pic">
                <img
                    src={item.picLink}
                    height="50px"
                    style={{ objectFit: 'contain' }}
                    alt="pic"
                />
            </div>
            <div className="item-name-area">
                <div className="item-name-left">
                    <div className="item-name-name">{item.name}</div>
                    <div className="item-name-sku">Sku NO: {item.skuNo}</div>

                    {item.doorDirection && (
                        <div className="cart-list-option-title">
                            Door Hinge Location:{' '}
                            <span className="cart-list-option-detail">
                                {item.doorDirection}
                            </span>
                        </div>
                    )}

                    {item.glassOption && (
                        <>
                            <div className="cart-list-option-title">
                                Glass Doors:{' '}
                                <span className="cart-list-option-detail">
                                    {item.glassOption.split('&')[2]}
                                </span>
                            </div>
                        </>
                    )}

                    {item.depthOption && (
                        <>
                            <div className="cart-list-option-title">
                                Change Cabinet Depth:{' '}
                                <span className="cart-list-option-detail">
                                    {item.depthOption.split('&')[2]}
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <div className="item-name-right">
                    <div>Unit Price: ${item.price}</div>
                </div>
                <div className="item-name-right">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Qty: {item.quantity}{' '}
                        {orderStatus === 1 && (
                            <>
                                <EditIcon
                                    style={{
                                        color: blue[500],
                                        cursor: 'pointer',
                                        margin: '0 10px',
                                    }}
                                    onClick={() => handleEditOpen(item)}
                                />
                                <DeleteForeverIcon
                                    style={{
                                        color: red[500],
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        if (window.confirm(DELETE_CONFIRM)) {
                                            deleteOrderItem(item.id)
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            {itemDisplay(item)}
            <Dialog open={editOpen} aria-labelledby="form-dialog-edit">
                <EditItem
                    handleDialogClose={handleEditClose}
                    orderId={orderId}
                    item={editItem}
                />
            </Dialog>
        </div>
    )
}

export default OrderInfoItem
