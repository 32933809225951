import { useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { getOneProduct } from '../store/actions/productActions'
import { Button, Container, Grid } from '@material-ui/core'
import ProductInfo from '../components/product/ProductInfo'
import ProductColors from '../components/product/ProductColors'
import history from '../history'
import { PRODUCT_COLOR_FORWARD } from '../constant/default'

const Product = ({ match, location }) => {
    const dispatch = useDispatch()
    const id = match.params.id
    const productColorForward = location?.state?.productColorForward ?? ''
    useEffect(() => {
        dispatch(getOneProduct(id))
    }, [dispatch, id])

    const productState = useSelector((state) => state.product)
    const { product } = productState

    return (
        <Layout>
            <Container component="main" maxWidth="lg">
                <Grid>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: '15px 0' }}
                        onClick={() =>
                            productColorForward === PRODUCT_COLOR_FORWARD
                                ? history.goBack()
                                : history.push('/products')
                        }
                    >
                        Back
                    </Button>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {product && <ProductInfo product={product} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {product && <ProductColors product={product} />}
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Product
