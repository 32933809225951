import { Card, CardContent, CardHeader } from '@material-ui/core'
import React from 'react'

const RequestDesignDetailInfo = ({ requestDesign }) => {
    const {
        ceilingHeight,
        bulkhead,
        bulkheadDepth,
        bulkheadHeight,
        range,
        rangeSize,
        rangeSizeOther,
        rangeType,
        rangeHood,
        rangeHoodOther,
        refrigeratorWidth,
        refrigeratorDepth,
        refrigeratorHeight,
        microwave,
        microwaveWidth,
        microwaveDepth,
        microwaveHeight,
        dishwasherSize,
        dishwasherSizeOther,
        dishwasherLocation,
        dishwasherLocationOther,
        sinkWidth,
        sinkDepth,
        sink,
        sinkOther,
        handles,
        cabinetStyle,
        upperWallCabinetHeight,
        upperWallCabinetHeightOther,
        cabinetsStorage,
        baseStorage,
        wallCabinet,
        wallCabinetOther,
        trim,
        note,
    } = requestDesign.requestdesigndetail

    return (
        <>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Project Address" />
                <CardContent>
                    <div>Street Address: {requestDesign.street}</div>
                    <div>Apartment. suite. etc: {requestDesign.suit}</div>
                    <div>City: {requestDesign.city}</div>
                    <div>Province: {requestDesign.province}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Existing Construction" />
                <CardContent>
                    <div>Ceiling Height: {ceilingHeight}</div>
                    <div>Bulkhead Height: {bulkhead}</div>
                    {bulkhead === 'Yes' && (
                        <>
                            <div>Bulkhead Size Depth: {bulkheadDepth}</div>
                            <div>Bulkhead Size Height: {bulkheadHeight}</div>
                        </>
                    )}
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Range / Oven" />
                <CardContent>
                    <div>{range}</div>
                    <div>
                        {rangeSize === 'Other' ? rangeSizeOther : rangeSize}
                    </div>
                    <div>{rangeType}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Range Hood / Ventilation " />
                <CardContent>
                    <div>
                        {rangeHood === 'Other' ? rangeHoodOther : rangeHood}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Refrigerator" />
                <CardContent>
                    <div>Width: {refrigeratorWidth}</div>
                    <div>Depth: {refrigeratorDepth}</div>
                    <div>Height: {refrigeratorHeight}</div>
                </CardContent>
            </Card>

            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Microwave" />
                <CardContent>
                    <div>{microwave}</div>
                    <div>Width: {microwaveWidth}</div>
                    <div>Depth: {microwaveDepth}</div>
                    <div>Height: {microwaveHeight}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Dishwasher" />
                <CardContent>
                    <div>
                        {dishwasherSize === 'Other'
                            ? dishwasherSizeOther
                            : dishwasherSize}
                    </div>
                    <div>
                        {dishwasherLocation === 'Other'
                            ? dishwasherLocationOther
                            : dishwasherLocation}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Sink" />
                <CardContent>
                    <div>Width: {sinkWidth}</div>
                    <div>Depth: {sinkDepth}</div>
                    <div>{sink === 'Other' ? sinkOther : sink}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Handles / Knobs" />
                <CardContent>
                    <div>{handles}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Cabinet Style & Height" />
                <CardContent>
                    <div>{cabinetStyle}</div>
                    <div>
                        Upper Wall Cabinet Height:{' '}
                        {upperWallCabinetHeight === 'Other'
                            ? upperWallCabinetHeightOther
                            : upperWallCabinetHeight}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Cabinets & Storage Options" />
                <CardContent>
                    <div>{cabinetsStorage}</div>
                    <div>Preference for base storage: {baseStorage}</div>

                    <div>
                        Preference for wall cabinet:{' '}
                        {wallCabinet === 'Other'
                            ? wallCabinetOther
                            : wallCabinet}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Trim & Moldings" />
                <CardContent>
                    <div>{trim}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Extra Notes" />
                <CardContent>
                    <div style={{ whiteSpace: 'pre-line' }}>{note}</div>
                </CardContent>
            </Card>
        </>
    )
}

export default RequestDesignDetailInfo
