import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import WarrantyForm from './WarrantyForm'

const AddWarranty = ({ handleDialogClose }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Warranty</DialogTitle>
        <WarrantyForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
        />
    </>
)

export default AddWarranty
