import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../../constant/default'
import InvoiceForm from './InvoiceForm'

const AddInvoice = ({ handleDialogClose, orderId, amount }) => {
    const values = {
        amount,
    }
    return (
        <>
            <DialogTitle id="form-dialog-title">Add New Invoice</DialogTitle>
            <InvoiceForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
                orderId={orderId}
                initialValues={values}
            />
        </>
    )
}

export default AddInvoice
