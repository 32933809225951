import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Typography } from '@material-ui/core'
import Layout from '../components/Layout'
import ReduxPagnation from '../components/ReduxPagnation'
import { CHANGE_CLIENT_DESIGN_SAVE_PAGE } from '../store/types'
import { getAllClientDesignSaves } from '../store/actions/clientDesignSaveActions'
import ClientDesignSaveSearch from '../components/clientDesignSave/ClientDesignSaveSearch'
import ClientDesignSaveInfos from '../components/clientDesignSave/ClientDesignSaveInfos'

const ClientDesignSaves = () => {
    const dispatch = useDispatch()
    const clientDesignSaveState = useSelector((state) => state.clientDesignSave)
    const { clientDesignSaves, pages, page, count, keyword } =
        clientDesignSaveState

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllClientDesignSaves(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_CLIENT_DESIGN_SAVE_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <h2 style={{ textAlign: 'center' }}>Client 2020 Designs</h2>
                <ClientDesignSaveSearch displayKeyword={keyword} />
                <Typography variant="body2" gutterBottom>
                    Count: {count}
                </Typography>
                {clientDesignSaves && (
                    <ClientDesignSaveInfos
                        clientDesignSaves={clientDesignSaves}
                    />
                )}
                <ReduxPagnation
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default ClientDesignSaves
