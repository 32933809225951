import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Dialog,
    Container,
    Typography,
    Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DELETE_CONFIRM, ITEM_HEIGHT } from '../../constant/default'
import EditHomePage from './EditHomePage'

import {
    deleteHomePage,
    getOneHomePage,
} from '../../store/actions/homePageActions'
import { green, red } from '@material-ui/core/colors'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const useStyles = makeStyles((theme) => ({
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    paper: {
        padding: '20px',
        margin: '10px 0',
    },
}))

const HomePageList = ({ homePage }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = () => {
        dispatch(getOneHomePage(homePage.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Container style={{ marginBottom: '2rem' }}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Grid container spacing={2}>
                            <Grid container xs={12} md={6}>
                                <Grid item xs={6}>
                                    <img
                                        src={homePage.picLink}
                                        width="100%"
                                        alt="pic"
                                    />
                                </Grid>
                                {homePage.mobilePicLink && (
                                    <Grid item xs={6}>
                                        <img
                                            src={homePage.mobilePicLink}
                                            width="100%"
                                            alt="pic"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    {homePage.serialNo}
                                </Typography>
                                <Typography variant="h6">
                                    {homePage.title}
                                </Typography>
                                <Typography variant="body1">
                                    {homePage.description}
                                </Typography>
                                <Typography variant="body1">
                                    {homePage.button}
                                </Typography>
                                <Typography variant="body1">
                                    <a
                                        href={`https://hs-online.com${homePage.url}`}
                                        target="_black"
                                    >
                                        https://hs-online.com{homePage.url}
                                    </a>
                                </Typography>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        background: `${homePage.bgcolor}`,
                                    }}
                                ></div>{' '}
                                <Typography variant="body1">
                                    {homePage.bgcolor}
                                </Typography>
                                <Typography variant="body1">
                                    Active:
                                    {homePage.active ? (
                                        <CheckCircleOutlineIcon
                                            style={{ color: green[500] }}
                                        />
                                    ) : (
                                        <BlockIcon
                                            style={{ color: red[500] }}
                                        />
                                    )}
                                </Typography>
                                <Typography variant="body1">
                                    Top Aligned:
                                    {homePage.topAligned ? (
                                        <CheckCircleOutlineIcon
                                            style={{ color: green[500] }}
                                        />
                                    ) : (
                                        <BlockIcon
                                            style={{ color: red[500] }}
                                        />
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            aria-label="more"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem onClick={handleEditOpen}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    if (window.confirm(DELETE_CONFIRM)) {
                                        dispatch(deleteHomePage(homePage.id))
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteForeverIcon
                                        className={classes.deleteIcon}
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Delete"
                                    className={classes.deleteIcon}
                                />
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                <Divider />
            </div>

            <Dialog open={editOpen} aria-labelledby="form-dialog-edit">
                <EditHomePage handleDialogClose={handleEditClose} />
            </Dialog>
        </Container>
    )
}

export default HomePageList
