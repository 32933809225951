import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import ProductSizeForm from './ProductSizeForm'

const AddProductSize = ({ handleDialogClose, productId }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Product Size</DialogTitle>
        <ProductSizeForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
            productId={productId}
        />
    </>
)

export default AddProductSize
