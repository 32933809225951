import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { editRequestDesignDesigners } from '../../store/actions/clientRequestDesignActions'
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormGroup,
    FormLabel,
} from '@material-ui/core'
import { designerGroup } from '../ReduxFormInputs'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const EditDesignerForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, designers, requestDesignerId } =
        props
    const submitForm = (formValues) => {
        handleDialogClose()
        formValues['id'] = requestDesignerId
        handleDialogClose(false)
        // console.log(formValues)
        dispatch(editRequestDesignDesigners(formValues))
    }
    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <FormControl
                        component="fieldset"
                        className={classes.formControl}
                    >
                        <FormLabel component="legend">Designers</FormLabel>
                        <FormGroup>
                            <Field
                                name="designerLists"
                                component={designerGroup}
                                options={designers}
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

export default reduxForm({
    form: 'designersForm',
    enableReinitialize: true,
})(EditDesignerForm)
