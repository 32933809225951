import { GET_ALL_WARRANTIES, GET_ONE_WARRANTY } from '../types'

const initialState = {
    warranties: [],
    warranty: {},
}

export const warrantyReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_WARRANTIES:
            return {
                ...state,
                warranties: payload,
            }
        case GET_ONE_WARRANTY:
            return {
                ...state,
                warranty: payload,
            }
        default:
            return state
    }
}

export default warrantyReducer
