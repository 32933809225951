import { GET_ALL_HEADOFFICES, GET_ONE_HEADOFFICE } from '../types'

const initialState = {
    headOffices: null,
    headOffice: null,
}

export const headOfficeReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_HEADOFFICES:
            return {
                ...state,
                headOffices: payload,
            }
        case GET_ONE_HEADOFFICE:
            return {
                ...state,
                headOffice: payload,
            }
        default:
            return state
    }
}

export default headOfficeReducer
