import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import SelfAssembleProcessForm from './SelfAssembleProcessForm'

const EditSelfAssembleProcess = ({ handleDialogClose, selfAssembleId }) => {
    const selfAssembleProcessState = useSelector(
        (state) => state.selfAssembleProcess
    )
    const { selfAssembleProcess } = selfAssembleProcessState

    return selfAssembleProcess ? (
        <>
            <DialogTitle id="form-dialog-title">
                Edit Self Assemble Process
            </DialogTitle>
            <SelfAssembleProcessForm
                handleDialogClose={handleDialogClose}
                initialValues={selfAssembleProcess}
                formType={FORM_TYPE_EDIT}
                selfAssembleId={selfAssembleId}
            />
        </>
    ) : null
}

export default EditSelfAssembleProcess
