import { GET_ALL_GLASSDOORLIST, GET_ONE_GLASSDOOR } from '../types'

const initialState = {
    glassDoor: {},
    glassDoorList: [],
}

const glassDoorReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ONE_GLASSDOOR:
            return {
                ...state,
                glassDoor: payload,
            }
        case GET_ALL_GLASSDOORLIST:
            return {
                ...state,
                glassDoorList: payload,
            }
        default:
            return state
    }
}

export default glassDoorReducer
