import { useState } from 'react'
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
} from '@material-ui/core'
import EditDesigner from './EditDesigner'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import history from '../../history'

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: '5px',
    },
}))

const RequestDesignDesigners = ({ designers, requestDesignerId }) => {
    const classes = useStyles()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = () => {
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }
    return (
        <>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Designer" />

                <CardContent>
                    <Button
                        onClick={handleEditOpen}
                        color="primary"
                        variant="outlined"
                    >
                        Edit Designer
                    </Button>
                </CardContent>
                <CardContent>
                    <div>
                        {designers &&
                            designers.length > 0 &&
                            designers.map((designer) => (
                                <Chip
                                    key={designer.id}
                                    className={classes.chip}
                                    avatar={
                                        <Avatar>
                                            {designer.firstName
                                                .substr(0, 1)
                                                .toUpperCase()}
                                        </Avatar>
                                    }
                                    label={`${designer.firstName} ${designer.lastName}`}
                                    color={
                                        designer.isActive && !designer.blockUser
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                    deleteIcon={<DoneIcon />}
                                    variant="outlined"
                                    onClick={() =>
                                        history.push(`/designer/${designer.id}`)
                                    }
                                />
                            ))}
                    </div>
                </CardContent>
            </Card>
            <div>
                <Dialog
                    open={editOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-style"
                >
                    <EditDesigner
                        handleDialogClose={handleEditClose}
                        designerList={designers}
                        requestDesignerId={requestDesignerId}
                    />
                </Dialog>
            </div>
        </>
    )
}

export default RequestDesignDesigners
