export const userRole = {
    USER_ADMIN: 'admin',
    USER_MANAGER: 'manager',
    USER_STAFF: 'staff',
    USER_USER: 'user',
}

export const userRoleList = [
    { displayName: 'Admin', value: 'admin' },
    { displayName: 'Manager', value: 'manager' },
    { displayName: 'Staff', value: 'staff' },
    { displayName: 'User', value: 'user' },
]

export const shippingMethodList = [
    { id: 0, name: '' },
    { id: 1, name: 'Package' },
    { id: 2, name: 'Freight' },
    { id: 3, name: 'Free' },
]

export const optionTypeList = [
    { id: 1, name: 'Glass Doors' },
    { id: 2, name: 'Cabinet Depth' },
]

export const buildTypeLists = [
    { id: 1, name: 'Kitchen' },
    { id: 2, name: 'Bathroom' },
    { id: 3, name: 'Other' },
]

export const designStatus = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Detail' },
    { id: 3, name: 'Designer' },
    { id: 4, name: 'Submit' },
    { id: 5, name: 'Finished' },
]

export const clientDesignSaveType = [
    { id: 1, name: 'Saved' },
    { id: 2, name: 'Shared' },
    { id: 3, name: 'Submit to store' },
]

export const doors = [
    {
        id: 1,
        direction: 'Left',
        icon: 'https://res.cloudinary.com/homespace/image/upload/v1631328853/Icons/left_zn971m.svg',
    },
    {
        id: 2,
        direction: 'Right',
        icon: 'https://res.cloudinary.com/homespace/image/upload/v1631328853/Icons/right_cx23vr.svg',
    },
]

export const DESIGN_NEW = 1
export const DESIGN_DETAIL = 2
export const DESIGN_ACTIVE = 3
export const DESIGN_SUBMIT = 4
export const DESIGN_FINISHED = 5

export const returnMethod = (method) => {
    return shippingMethodList.filter((list) => list.id === method)[0].name
}

export const USER_ADD_SUCCESSSFULLY = 'User Added Successfully'
export const USER_UPDATE_SUCCESSSFULLY = 'User Updated Successfully'
export const DESIGNER_UPDATE_SUCCESSSFULLY = 'Designer Updated Successfully'

export const CATEGORY_ADD_SUCCESSFULLY = 'Category added successfully'
export const CATEGORY_UPDATE_SUCCESSFULLY = 'Category updated successfully'
export const CATEGORY_DELETE_SUCCESSFULLY = 'Category deleted successfully'

export const SIZE_ADD_SUCCESSFULLY = 'Size added successfully'
export const SIZE_UPDATE_SUCCESSFULLY = 'Size updated successfully'
export const SIZE_DELETE_SUCCESSFULLY = 'Size deleted successfully'

export const STYLE_ADD_SUCCESSFULLY = 'Style added successfully'
export const STYLE_UPDATE_SUCCESSFULLY = 'Style updated successfully'
export const STYLE_DELETE_SUCCESSFULLY = 'Style deleted successfully'

export const STYLECOLOR_ADD_SUCCESSFULLY = 'Style color added successfully'
export const STYLECOLOR_UPDATE_SUCCESSFULLY = 'Style color updated successfully'
export const STYLECOLOR_DELETE_SUCCESSFULLY = 'Style color deleted successfully'

export const PRODUCTTYPE_ADD_SUCCESSFULLY = 'Product type added successfully'
export const PRODUCTTYPE_UPDATE_SUCCESSFULLY =
    'Product type updated successfully'
export const PRODUCTTYPE_DELETE_SUCCESSFULLY =
    'Product type deleted successfully'

export const PRODUCTFUNCTION_ADD_SUCCESSFULLY =
    'Product function added successfully'
export const PRODUCTFUNCTION_UPDATE_SUCCESSFULLY =
    'Product function updated successfully'
export const PRODUCTFUNCTION_DELETE_SUCCESSFULLY =
    'Product function deleted successfully'

export const PRODUCTMEASURE_ADD_SUCCESSFULLY =
    'Product measure added successfully'
export const PRODUCTMEASURE_UPDATE_SUCCESSFULLY =
    'Product measure updated successfully'
export const PRODUCTMEASURE_DELETE_SUCCESSFULLY =
    'Product measure deleted successfully'

export const PRODUCTCOLOR_ADD_SUCCESSFULLY = 'Product color added successfully'
export const PRODUCTCOLOR_UPDATE_SUCCESSFULLY =
    'Product color updated successfully'
export const PRODUCTCOLOR_DELETE_SUCCESSFULLY =
    'Product color deleted successfully'
export const PRODUCTCOLOR_UPDATESTOCKAPI_SUCCESSFULLY =
    'Product color updated stock api successfully'

export const PRODUCTPICTURE_ADD_SUCCESSFULLY =
    'Product picture added successfully'
export const PRODUCTPICTURE_UPDATE_SUCCESSFULLY =
    'Product picture updated successfully'
export const PRODUCTPICTURE_DELETE_SUCCESSFULLY =
    'Product picture deleted successfully'

export const PICTUREPOSITION_ADD_SUCCESSFULLY =
    'Picture position added successfully'
export const PICTUREPOSITION_UPDATE_SUCCESSFULLY =
    'Picture position updated successfully'
export const PICTUREPOSITION_DELETE_SUCCESSFULLY =
    'Picture position deleted successfully'

export const PICTUREPOINT_ADD_SUCCESSFULLY = 'Picture point added successfully'
export const PICTUREPOINT_UPDATE_SUCCESSFULLY =
    'Picture point updated successfully'
export const PICTUREPOINT_DELETE_SUCCESSFULLY =
    'Picture point deleted successfully'

export const PICTUREPOINTITEM_ADD_SUCCESSFULLY =
    'Picture point item added successfully'
export const PICTUREPOINTITEM_UPDATE_SUCCESSFULLY =
    'Picture point item updated successfully'
export const PICTUREPOINTITEM_DELETE_SUCCESSFULLY =
    'Picture point item deleted successfully'

export const PRODUCT_ADD_SUCCESSFULLY = 'Product added successfully'
export const PRODUCT_UPDATE_SUCCESSFULLY = 'Product updated successfully'
export const PRODUCT_DELETE_SUCCESSFULLY = 'Product deleted successfully'

export const SELFASSEMBLE_ADD_SUCCESSFULLY = 'Self assemble added successfully'
export const SELFASSEMBLE_UPDATE_SUCCESSFULLY =
    'Self assemble updated successfully'
export const SELFASSEMBLE_DELETE_SUCCESSFULLY =
    'Self assemble deleted successfully'

export const SELFASSEMBLEPROCESS_ADD_SUCCESSFULLY =
    'Self assemble process added successfully'
export const SELFASSEMBLEPROCESS_UPDATE_SUCCESSFULLY =
    'Self assemble process updated successfully'
export const SELFASSEMBLEPROCESS_DELETE_SUCCESSFULLY =
    'Self assemble process deleted successfully'

export const QNACATEGORY_ADD_SUCCESSFULLY = 'Q&A category added successfully'
export const QNACATEGORY_UPDATE_SUCCESSFULLY =
    'Q&A category updated successfully'
export const QNACATEGORY_DELETE_SUCCESSFULLY =
    'Q&A category deleted successfully'

export const QNAITEM_ADD_SUCCESSFULLY = 'Q&A item added successfully'
export const QNAITEM_UPDATE_SUCCESSFULLY = 'Q&A item updated successfully'
export const QNAITEM_DELETE_SUCCESSFULLY = 'Q&A item deleted successfully'

export const WARRANTY_ADD_SUCCESSFULLY = 'Warranty added successfully'
export const WARRANTY_UPDATE_SUCCESSFULLY = 'Warranty updated successfully'
export const WARRANTY_DELETE_SUCCESSFULLY = 'Warranty deleted successfully'

export const POLICY_ADD_SUCCESSFULLY = 'Policy added successfully'
export const POLICY_UPDATE_SUCCESSFULLY = 'Policy updated successfully'
export const POLICY_DELETE_SUCCESSFULLY = 'Policy deleted successfully'

export const PICKUPLOCATION_ADD_SUCCESSFULLY =
    'Pick up location added successfully'
export const PICKUPLOCATION_UPDATE_SUCCESSFULLY =
    'Pick up location updated successfully'
export const PICKUPLOCATION_DELETE_SUCCESSFULLY =
    'Pick up location deleted successfully'

export const HEADOFFICE_ADD_SUCCESSFULLY = 'Head Office added successfully'
export const HEADOFFICE_UPDATE_SUCCESSFULLY = 'Head Office updated successfully'
export const HEADOFFICE_DELETE_SUCCESSFULLY = 'Head Office deleted successfully'

export const TEAMMEMBER_ADD_SUCCESSFULLY = 'Team member added successfully'
export const TEAMMEMBER_UPDATE_SUCCESSFULLY = 'Team member updated successfully'
export const TEAMMEMBER_DELETE_SUCCESSFULLY = 'Team member deleted successfully'

export const HOMEPAGE_ADD_SUCCESSFULLY = 'Home page added successfully'
export const HOMEPAGE_UPDATE_SUCCESSFULLY = 'Home page updated successfully'
export const HOMEPAGE_DELETE_SUCCESSFULLY = 'Home page deleted successfully'

export const CABINETOPTION_ADD_SUCCESSFULLY =
    'Cabinet Option added successfully'
export const CABINETOPTION_UPDATE_SUCCESSFULLY =
    'Cabinet Option updated successfully'
export const CABINETOPTION_DELETE_SUCCESSFULLY =
    'Cabinet Option deleted successfully'

export const GLASSDOOR_ADD_SUCCESSFULLY = 'Glass door added successfully'
export const GLASSDOOR_UPDATE_SUCCESSFULLY = 'Glass door updated successfully'
export const GLASSDOOR_DELETE_SUCCESSFULLY = 'Glass door deleted successfully'

export const CABINETDEPTH_ADD_SUCCESSFULLY = 'Cabinet depth added successfully'
export const CABINETDEPTH_UPDATE_SUCCESSFULLY =
    'Cabinet depth updated successfully'
export const CABINETDEPTH_DELETE_SUCCESSFULLY =
    'Cabinet depth deleted successfully'

export const PROMO_CODE_ADD_SUCCESSFULLY = 'Promo code added successfully'
export const PROMO_CODE_UPDATE_SUCCESSFULLY = 'Promo code updated successfully'
export const PROMO_CODE_DELETE_SUCCESSFULLY = 'Promo code deleted successfully'

export const FORM_TYPE_NEW = 'new'
export const FORM_TYPE_EDIT = 'edit'

export const EMPTY_CHILDREN = 0

export const ITEM_HEIGHT = 48

export const DELETE_CONFIRM = 'Are you sure?'

export const EMPTY_SELECT = 'E'

export const PRODUCT_COLOR_FORWARD = 'pcf'

export const POSITION_LEFT = 1

export const OFFICE_POSTCODE = 'L5T1N5'

export const PROMO_CATEGORY = {
    1: 'Public',
    2: 'Private',
}

export const promoCodeList = {
    BOGO: 'BOGO',
    BMSM2022: 'BMSM2022',
}
