import { Router, Route } from 'react-router-dom'
import Alert from './components/Alert'
import LoadingSet from './components/LoadingSet'
import history from './history'
import AddUser from './pages/AddUser'
import Login from './pages/Login'
import Main from './pages/Main'
import Product from './pages/Product'
import Products from './pages/Products'
import Profile from './pages/Profile'
import SignUp from './pages/SignUp'
import Test from './pages/Test'
import Users from './pages/Users'
import Categories from './pages/Categories'
import Sizes from './pages/Sizes'
import AddProduct from './pages/AddProduct'
import ProductTypes from './pages/ProductTypes'
import ProductFunctions from './pages/ProductFunctions'
import EditProduct from './pages/EditProduct'
import ProductSelect from './pages/ProductSelect'
import PicturePositions from './pages/PicturePositions'
import PicturePoints from './pages/PicturePoints'
import PicturePointItems from './pages/PicturePointItems'
import SelfAssembles from './pages/SelfAssembles'
import QnAs from './pages/QnAs'
import Warranties from './pages/Warranties'
import PickUpLocations from './pages/PickUpLocations'
import HeadOffices from './pages/HeadOffices'
import TeamMembers from './pages/TeamMembers'
import HomePages from './pages/HomePages'
import Policies from './pages/Policies'
import Clients from './pages/Clients'
import Orders from './pages/Orders'
import OrderInfo from './pages/OrderInfo'
import CabinetOptions from './pages/CabinetOptions'
import ClientRequestDesigns from './pages/ClientRequestDesigns'
import ClientRequestDesignInfo from './pages/ClientRequestDesignInfo'
import Designers from './pages/Designers'
import Designer from './pages/Designer'
import ClientRequestDesignDetail from './pages/ClientRequestDesignDetail'
import ClientDesignSaves from './pages/ClientDesignSaves'
import PriceMatchs from './pages/PriceMatchs'
import PriceMatch from './pages/PriceMatch'
import PromoCodes from './pages/PromoCodes'
import PromoCode from './pages/PromoCode'

function App() {
    return (
        <Router history={history}>
            <Alert />
            <LoadingSet />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/test" component={Test} />
            <Route path="/users" component={Users} />
            <Route path="/clients" component={Clients} />
            <Route path="/clientdesignsave" component={ClientDesignSaves} />
            <Route path="/designers" component={Designers} />
            <Route path="/designer/:id" component={Designer} />
            <Route path="/orders" component={Orders} />
            <Route path="/order/:id" component={OrderInfo} />
            <Route path="/profile" component={Profile} />
            <Route path="/categories" component={Categories} />
            <Route path="/sizes" component={Sizes} />
            <Route path="/adduser" component={AddUser} />
            <Route path="/" exact component={Main} />
            <Route path="/products" component={Products} />
            <Route path="/addproduct" component={AddProduct} />
            <Route path="/editproduct/:id" component={EditProduct} />
            <Route path="/product/:id" component={Product} />
            <Route path="/producttype" component={ProductTypes} />
            <Route path="/productfunction" component={ProductFunctions} />
            <Route path="/productselect" component={ProductSelect} />
            <Route path="/pictureposition" component={PicturePositions} />
            <Route path="/picturepoint" component={PicturePoints} />
            <Route path="/picturepointitem/:id" component={PicturePointItems} />
            <Route path="/selfassemble" component={SelfAssembles} />
            <Route path="/qnas" component={QnAs} />
            <Route path="/warranty" component={Warranties} />
            <Route path="/policy" component={Policies} />
            <Route path="/headoffice" component={HeadOffices} />
            <Route path="/teammember" component={TeamMembers} />
            <Route path="/homepage" component={HomePages} />
            <Route path="/pickuplocation" component={PickUpLocations} />
            <Route path="/cabinetoption" component={CabinetOptions} />
            <Route path="/requestdesign" component={ClientRequestDesigns} />
            <Route
                path="/requestdesignone/:id"
                component={ClientRequestDesignInfo}
            />
            <Route
                path="/requestdesigndetail/:id"
                component={ClientRequestDesignDetail}
            />
            <Route path="/pricematch" component={PriceMatchs} />
            <Route path="/promocodes" component={PromoCodes} />
            <Route path="/promocode/:id" component={PromoCode} />
            <Route path="/pricematchinfo/:id" component={PriceMatch} />
        </Router>
    )
}

export default App
