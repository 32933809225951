import { errorsReturn } from '../errorsReturn'
import { GET_ALL_PRICE_MATCH, GET_ONE_PRICE_MATCH } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'

export const getAllPriceMatchs =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.get(
                `client/pricematch/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_PRICE_MATCH,
                payload: {
                    priceMatchs: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const getOnePriceMatch = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`client/pricematch/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_PRICE_MATCH, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
