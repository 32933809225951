import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DialogTitle } from '@material-ui/core'
import CategoryForm from './CategoryForm'
import { getAllSizes } from '../../store/actions/sizeActions'
import { FORM_TYPE_NEW } from '../../constant/default'

const AddCategory = ({ handleDialogClose }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllSizes())
    }, [dispatch])
    return (
        <>
            <DialogTitle id="form-dialog-title">Add New Category</DialogTitle>
            <CategoryForm
                handleDialogClose={handleDialogClose}
                formType={FORM_TYPE_NEW}
            />
        </>
    )
}

export default AddCategory
