import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Avatar,
    Chip,
    Grid,
    Paper,
    Dialog,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
    DELETE_CONFIRM,
    EMPTY_CHILDREN,
    ITEM_HEIGHT,
} from '../../constant/default'
import {
    deletePicturePoint,
    getOnePicturePoint,
} from '../../store/actions/picturePointActions'
import EditPicturePoint from './EditPicturePoint'
import AdjustIcon from '@material-ui/icons/Adjust'
import history from '../../history'
import PicturePointItemDisplay from '../picturePointItem/PicturePointItemDisplay'

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: '5px',
    },
    paper: {
        padding: theme.spacing(2),
    },
    box1: {
        marginTop: theme.spacing(2),
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
}))

const PicturePointList = ({ picturePoint }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = (id) => {
        dispatch(getOnePicturePoint(id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Paper className={classes.paper}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        aria-label="more"
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => handleEditOpen(picturePoint.id)}
                        >
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </MenuItem>

                        <MenuItem
                            onClick={() =>
                                history.push(
                                    `/picturepointitem/${picturePoint.id}`
                                )
                            }
                        >
                            <ListItemIcon>
                                <AdjustIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Edit Points" />
                        </MenuItem>
                        {picturePoint.picturePointItem &&
                            picturePoint.picturePointItem.length ===
                                EMPTY_CHILDREN && (
                                <MenuItem
                                    onClick={() => {
                                        if (window.confirm(DELETE_CONFIRM)) {
                                            dispatch(
                                                deletePicturePoint(
                                                    picturePoint.id
                                                )
                                            )
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteForeverIcon
                                            className={classes.deleteIcon}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Delete"
                                        className={classes.deleteIcon}
                                    />
                                </MenuItem>
                            )}
                    </Menu>
                </div>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <div className="picture-point-area">
                            <div
                                className="picture-point-name"
                                style={{ color: picturePoint.nameColor }}
                            >
                                <h3>{picturePoint.name}</h3>
                            </div>
                            {picturePoint.picturepointitems &&
                                picturePoint.picturepointitems.length > 0 &&
                                picturePoint.picturepointitems.map(
                                    (picturePointItem) => (
                                        <PicturePointItemDisplay
                                            key={picturePointItem.id}
                                            picturePointItem={picturePointItem}
                                        />
                                    )
                                )}
                            <img
                                src={picturePoint.picLink}
                                alt="picPoint"
                                width="100%"
                            />
                        </div>
                        <p>{picturePoint.remark}</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.box1}>
                            {picturePoint.picturepositions &&
                                picturePoint.picturepositions.map(
                                    (picturePosition) => (
                                        <Chip
                                            key={picturePosition.id}
                                            className={classes.chip}
                                            avatar={
                                                <Avatar>
                                                    {picturePosition.position
                                                        .substr(0, 1)
                                                        .toUpperCase()}
                                                </Avatar>
                                            }
                                            label={picturePosition.position}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    )
                                )}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditPicturePoint handleDialogClose={handleEditClose} />
            </Dialog>
        </>
    )
}

export default PicturePointList
