import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Fab,
    Grid,
    Tooltip,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Dialog,
    Container,
    Paper,
} from '@material-ui/core'
import YouTube from 'react-youtube'
import SelfAssembleProcessList from '../selfAssembleProcess/SelfAssembleProcessList'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import AddSelfAssembleProcess from '../selfAssembleProcess/AddSelfAssembleProcess'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
    DELETE_CONFIRM,
    EMPTY_CHILDREN,
    ITEM_HEIGHT,
} from '../../constant/default'
import {
    deleteSelfAssemble,
    getOneSelfAssemble,
} from '../../store/actions/selfAssembleActions'
import EditSelfAssemble from './EditSelfAssemble'

const useStyles = makeStyles((theme) => ({
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    paper: {
        padding: '20px',
    },
}))

const SelfAssembleList = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [processOpen, setProcessOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const handleProcessOpen = () => {
        setProcessOpen(true)
    }

    const handleProcessClose = () => {
        setProcessOpen(false)
    }

    const handleEditOpen = () => {
        dispatch(getOneSelfAssemble(selfAssemble.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const selfAssembleState = useSelector((state) => state.selfAssemble)
    const { selfAssemble } = selfAssembleState
    return (
        <Container maxWidth="md">
            <Paper className={classes.paper}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                    }}
                >
                    <IconButton
                        aria-label="more"
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem onClick={handleEditOpen}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </MenuItem>
                        {selfAssemble.selfassembleprocesses &&
                            selfAssemble.selfassembleprocesses.length ===
                                EMPTY_CHILDREN && (
                                <MenuItem
                                    onClick={() => {
                                        if (window.confirm(DELETE_CONFIRM)) {
                                            dispatch(
                                                deleteSelfAssemble(
                                                    selfAssemble.id
                                                )
                                            )
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteForeverIcon
                                            className={classes.deleteIcon}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Delete"
                                        className={classes.deleteIcon}
                                    />
                                </MenuItem>
                            )}
                    </Menu>
                </div>
                {selfAssemble.videoLink && (
                    <>
                        <h2 style={{ textAlign: 'center' }}>
                            Self assemble Video
                        </h2>
                        <div className="div-flex-center">
                            <YouTube videoId={selfAssemble.videoLink} />
                        </div>
                    </>
                )}
                <Grid container>
                    <Grid item xs={10}>
                        <h2 style={{ textAlign: 'center' }}>
                            Self assemble process
                        </h2>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '10px',
                        }}
                    >
                        <Tooltip title="Add" aria-label="add">
                            <Fab
                                color="primary"
                                size="small"
                                className={classes.fab}
                                onClick={handleProcessOpen}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
                <div>
                    <Grid container spacing={2}>
                        {selfAssemble.selfassembleprocesses &&
                            selfAssemble.selfassembleprocesses.length > 0 &&
                            selfAssemble.selfassembleprocesses.map(
                                (selfAssembleProcess) => (
                                    <SelfAssembleProcessList
                                        key={selfAssembleProcess.id}
                                        selfAssembleProcess={
                                            selfAssembleProcess
                                        }
                                        selfAssembleId={selfAssemble.id}
                                    />
                                )
                            )}
                    </Grid>
                </div>
                <Dialog
                    open={processOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="form-dialog-style"
                >
                    <AddSelfAssembleProcess
                        handleDialogClose={handleProcessClose}
                        selfAssembleId={selfAssemble.id}
                    />
                </Dialog>
                <Dialog
                    open={editOpen}
                    // onClose={handleEditClose}
                    aria-labelledby="form-dialog-edit"
                >
                    <EditSelfAssemble handleDialogClose={handleEditClose} />
                </Dialog>
            </Paper>
        </Container>
    )
}

export default SelfAssembleList
