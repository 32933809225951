import { Button, DialogTitle, Grid, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import request from '../../config/request'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { getErrors } from '../../store/actions/errorActions'
import { controlLoading } from '../../store/actions/loadingActions'
import { logout, renewUserToken } from '../../store/actions/userActions'
import { newToken } from '../../admin/newtoken'
import { errorsReturn } from '../../store/errorsReturn'
import { getOneOrder } from '../../store/actions/orderActions'
import { createMessage } from '../../store/actions/messageActions'
import { doors } from '../../constant/default'

export const styleArr = ['transition', 'modern', 'classic']

const AddItem = ({ handleDialogClose, orderId }) => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [result, setResult] = useState(null)
    const [qty, setQty] = useState('')
    const [door, setDoor] = useState(null)
    const [error, setError] = useState(false)
    const errorCss = error ? 'product-option-door-select-error' : null

    const { userToken } = useSelector((state) => state.user)

    useEffect(() => {
        if (door) {
            setError(false)
        }
    }, [door])

    const handleChange = async (e) => {
        const searchValue = e.target.value
        setSearch(searchValue)
        const values = {
            searchSkuNo: searchValue,
        }
        try {
            const res = await request.post('/order/searchitem', values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            setResult(res.data.data)
            setQty('')
            setDoor(null)
            setError(false)
        } catch (e) {
            console.log(e)
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

    const handleSubmit = async () => {
        const product = result.productColor.product
        if (!qty) {
            dispatch(getErrors('Please select quantity'))
            return
        }
        if (result.productColor.product.doorDirection && !door) {
            dispatch(getErrors('Please select door direction'))
            setError(true)
            return
        }
        let promoGroup = 0
        if (
            product?.producttype?.id === process.env.REACT_APP_WALL_ID &&
            styleArr.includes(product?.style?.slug)
        ) {
            promoGroup = 1
        }
        if (
            product?.producttype?.id === process.env.REACT_APP_BASE_ID &&
            styleArr.includes(product?.style?.slug)
        ) {
            promoGroup = 2
        }

        let doorDirection = ''
        if (door) {
            door === 1 ? (doorDirection = 'Left') : (doorDirection = 'Right')
        }
        const values = {
            name: product.name,
            skuNo: result.productColor.skuNo,
            picLink:
                result?.productColor?.productpictures[0]?.picLink ||
                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
            quantity: qty,
            price: result.productColor.price,
            doorDirection,
            promoGroupId: promoGroup,
            orderId,
            productColorId: result.productColor.id,
            productCategory: result.productColor.product.style.category.slug,
        }
        try {
            const res = await request.post('/order/addorderitem', values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            setQty('')
            setDoor(null)
            setError(false)
            dispatch(getOneOrder(orderId))
            dispatch(createMessage('Order Item added successful'))
            handleDialogClose(false)
        } catch (e) {
            console.log(e)
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
    return (
        <div
            style={{
                width: '600px',
                minHeight: '400px',
                padding: '20px',
                position: 'relative',
            }}
        >
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
                Add New Item
            </DialogTitle>
            <div
                style={{ position: 'absolute', right: '10px', top: '10px' }}
                onClick={() => handleDialogClose(false)}
            >
                <HighlightOffIcon
                    style={{ fontSize: '30px', cursor: 'pointer' }}
                />
            </div>
            <TextField
                fullWidth
                value={search}
                onChange={(e) => handleChange(e)}
                id="search"
                label="Search"
            />
            {result ? (
                result.find === true ? (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '20px',
                            }}
                        >
                            <img
                                src={
                                    result?.productColor?.productpictures[0]
                                        ?.picLink ||
                                    'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                }
                                alt="pic"
                                style={{
                                    width: '50%',
                                    objectFit: 'contain',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {result.productColor.product.name}
                        </Grid>
                        <Grid item xs={12}>
                            ${result.productColor.price}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Quantity
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                    label="Quantity"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {Array.from(
                                        Array(result.productColor.stock),
                                        (e, i) => (
                                            <MenuItem key={i} value={i + 1}>
                                                {i + 1}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {result.productColor.product.doorDirection && (
                            <div className="product-option-door-box">
                                <div className="product-option-door-title">
                                    Door Hinge Location{' '}
                                    <span className="product-option-door-star">
                                        * {error && 'This is a required field.'}
                                    </span>
                                </div>
                                <div
                                    className={`product-option-door-select ${errorCss}`}
                                >
                                    {doors.map((d) => {
                                        const selectedCss =
                                            door === d.id
                                                ? 'product-option-door-selected'
                                                : null
                                        return (
                                            <div
                                                key={d.direction}
                                                className={`product-option-door-item ${selectedCss}`}
                                                onClick={() => setDoor(d.id)}
                                            >
                                                <img
                                                    src={d.icon}
                                                    className="product-option-door-icon"
                                                    alt="icon"
                                                />
                                                <div>{d.direction}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <div className="search_bar_result">
                        {result.productColors.map((product) => (
                            <div key={product.id} className="search_bar_list">
                                <div className="search_bar_list_img">
                                    <img
                                        src={
                                            product?.productpictures[0]?.picLink
                                        }
                                        alt="pic"
                                        className="search_bar_img"
                                    />
                                </div>
                                <div
                                    className="search_bar_list_name"
                                    onClick={() => {
                                        const e = { target: { value: '' } }
                                        e.target.value = product?.skuNo
                                        handleChange(e)
                                    }}
                                >
                                    {product?.product?.name} {product?.skuNo}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            ) : null}
        </div>
    )
}

export default AddItem
