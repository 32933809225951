import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import {
    createInvoice,
    updateInvoice,
} from '../../../store/actions/orderActions'
import { FORM_TYPE_EDIT } from '../../../constant/default'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const InvoiceForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, formType, orderId } = props
    const submitForm = (formValues) => {
        formValues['orderId'] = orderId
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updateInvoice(formValues))
            : dispatch(createInvoice(formValues))
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click Set button to
                        finish.
                    </DialogContentText>

                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="amount"
                        label="Amount"
                        name="amount"
                        type="number"
                        inputProps={{ maxLength: 10 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="dueDate"
                        label="Payment Due Date"
                        name="dueDate"
                        type="date"
                        inputProps={{ maxLength: 20 }}
                        InputLabelProps={{ shrink: true }}
                        component={renderTextField}
                    />
                    <Field
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="note"
                        label="Note"
                        name="note"
                        multiline
                        rows={4}
                        component={renderTextField}
                    />
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['amount', 'dueDate']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'orderInvoiceForm',
    validate,
    enableReinitialize: true,
})(InvoiceForm)
