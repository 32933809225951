import React from 'react'
import { Button, TableCell, TableRow } from '@material-ui/core'
import history from '../../history'
import moment from 'moment'

const PriceMatchList = ({ priceMatch }) => {
    return (
        <>
            <TableRow>
                <TableCell>
                    {priceMatch?.firstName} {priceMatch?.lastName}
                </TableCell>
                <TableCell>{priceMatch?.email}</TableCell>
                <TableCell>{priceMatch?.phone}</TableCell>
                <TableCell>{priceMatch?.quote}</TableCell>
                <TableCell>{priceMatch?.brand}</TableCell>
                <TableCell>
                    {moment(priceMatch?.createdAt).format('ll')}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={() =>
                            history.push(`/pricematchinfo/${priceMatch?.id}`)
                        }
                    >
                        info
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default PriceMatchList
