import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import categoryReducer from './categoryReducer'
import { errorReducer } from './errorReducer'
import loadingReducer from './loadingReducer'
import { messageReducer } from './messageReducer'
import sizeReducer from './sizeReducer'
import styleReducer from './styleReducer'
import styleColorReducer from './styleColorReducer'
import { userReducer } from './userReducer'
import productTypeReducer from './productTypeReducer'
import productFunctionReducer from './productFunctionReducer'
import productReducer from './productReducer'
import productMeasureReducer from './productMeasureReducer'
import productColorReducer from './productColorReducer'
import productPictureReducer from './productPictureReducer'
import picturePositionReducer from './picturePositionReducer'
import picturePointReducer from './picturePointReducer'
import picturePointItemReducer from './picturePointItemReducer'
import selfAssembleReducer from './selfAssembleReducer'
import selfAssembleProcessReducer from './selfAssembleProcessReducer'
import qnaCategoryReducer from './qnaCategoryReducer'
import qnaItemReducer from './qnaItemReducer'
import warrantyReducer from './warrantyReducer'
import pickUpLocationReducer from './pickUpLocationReducer'
import headOfficeReducer from './headOfficeReducer'
import teamMemberReducer from './teamMemberReducer'
import homePageReducer from './homePageReducer'
import policyReducer from './policyReducer'
import { clientReducer } from './clientReducer'
import orderReducer from './orderReducer'
import cabinetOptionReducer from './cabinetOptionReducer'
import glassDoorReducer from './glassDoorReducer'
import cabinetDepthReducer from './cabinetDepthReducer'
import clientRequestDesignReducer from './clientRequestDesignReducer'
import { designerReducer } from './designerReducer'
import clientDesignSaveReducer from './clientDesignSaveReducer'
import priceMatchReducer from './priceMatchReducer'
import { promoCodeReducer } from './promoCodeReducer'

export default combineReducers({
    form: formReducer,
    user: userReducer,
    loading: loadingReducer,
    errors: errorReducer,
    messages: messageReducer,
    category: categoryReducer,
    size: sizeReducer,
    style: styleReducer,
    styleColor: styleColorReducer,
    productType: productTypeReducer,
    productFunction: productFunctionReducer,
    product: productReducer,
    productMeasure: productMeasureReducer,
    productColor: productColorReducer,
    productPicture: productPictureReducer,
    picturePosition: picturePositionReducer,
    picturePoint: picturePointReducer,
    picturePointItem: picturePointItemReducer,
    selfAssemble: selfAssembleReducer,
    selfAssembleProcess: selfAssembleProcessReducer,
    qnaCategory: qnaCategoryReducer,
    qnaItem: qnaItemReducer,
    warranty: warrantyReducer,
    pickUpLocation: pickUpLocationReducer,
    headOffice: headOfficeReducer,
    teamMember: teamMemberReducer,
    homePage: homePageReducer,
    policy: policyReducer,
    client: clientReducer,
    order: orderReducer,
    cabinetOption: cabinetOptionReducer,
    glassDoor: glassDoorReducer,
    cabinetDepth: cabinetDepthReducer,
    clientRequestDesign: clientRequestDesignReducer,
    designer: designerReducer,
    clientDesignSave: clientDesignSaveReducer,
    priceMatch: priceMatchReducer,
    promoCode: promoCodeReducer,
})
