import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ClientList from './ClientList'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    tableHeaderCell: {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}))

const ClientInfos = ({ clients }) => {
    const classes = useStyles()
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeaderCell}>
                            Email
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell}>
                            Name
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell}>
                            Phone
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                        >
                            Active
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                        >
                            Block
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                        >
                            Register Date
                        </TableCell>
                        <TableCell
                            className={classes.tableHeaderCell}
                            style={{ width: '50px' }}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.map((client) => (
                        <ClientList client={client} key={client.id} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ClientInfos
