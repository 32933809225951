import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Layout from '../components/Layout'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import ProductForm from '../components/product/ProductForm'
import { FORM_TYPE_NEW } from '../constant/default'
import { getAllProductStyles } from '../store/actions/styleActions'
import { getAllProductTypes } from '../store/actions/productTypeActions'
import { getAllProductFunctions } from '../store/actions/productFunctionActions'

const AddProduct = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllProductStyles())
        dispatch(getAllProductTypes())
        dispatch(getAllProductFunctions())
    }, [dispatch])

    const values = { styleId: '', sizeId: '' }

    return (
        <Layout>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <ProductForm
                    initialValues={values}
                    formType={FORM_TYPE_NEW}
                    preProductFunctions={false}
                    preProductType={false}
                    preTypeId=""
                    preStyle=""
                    sizesList=""
                    preProductOption={false}
                />
            </Container>
        </Layout>
    )
}

export default AddProduct
