import { errorsReturn } from '../errorsReturn'
import { GET_ALL_DESIGNERS, GET_ONE_DESIGNER } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { createMessage } from './messageActions'
import { DESIGNER_UPDATE_SUCCESSSFULLY } from '../../constant/default'
import { logout, renewUserToken } from './userActions'

export const getAllDesigners =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                user: { userToken },
            } = getState()
            let res = await request.get(
                `designer/designeruser/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_DESIGNERS,
                payload: {
                    designers: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const getOneDesigner = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.get(`designer/designeruser/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_DESIGNER, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateOneDesigner = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            user: { userToken },
        } = getState()
        let res = await request.put(
            `designer/designeruser/adminupdatedesigner/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(createMessage(DESIGNER_UPDATE_SUCCESSSFULLY))
        dispatch(getOneDesigner(values.id))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
