import { GET_ALL_SIZES, GET_ONE_SIZE } from '../types'

const initialState = {
    sizes: [],
    size: {},
}

export const sizeReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SIZES:
            return {
                ...state,
                sizes: payload,
            }
        case GET_ONE_SIZE:
            return {
                ...state,
                size: payload,
            }
        default:
            return state
    }
}

export default sizeReducer
