import { useState } from 'react'
import { Button, TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
// import ModalInfo from './ModalInfo'

import ClientDesignSaveInfo from './ClientDesignSaveInfo'
import { clientDesignSaveType } from '../../constant/default'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        minWidth: '500px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

const ClientDesignSaveList = ({ clientDesignSave }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <TableRow>
                <TableCell>
                    {
                        clientDesignSaveType.filter(
                            (list) => list.id === clientDesignSave.type
                        )[0].name
                    }
                </TableCell>
                <TableCell>
                    <img
                        src={clientDesignSave.imgURL}
                        alt="pic"
                        style={{
                            width: '200px',
                            height: '100px',
                            objectFit: 'contain',
                        }}
                        onError={(event) => {
                            event.target.src =
                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                            event.onerror = null
                        }}
                    />
                </TableCell>
                <TableCell>
                    <span>
                        <a
                            href={clientDesignSave.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            URL
                        </a>
                    </span>
                </TableCell>
                <TableCell>{clientDesignSave.email}</TableCell>
                <TableCell>
                    {clientDesignSave.firstName} {clientDesignSave.lastName}
                </TableCell>
                <TableCell>{clientDesignSave.phone}</TableCell>
                <TableCell align="center">
                    {clientDesignSave.appointment}
                </TableCell>
                <TableCell align="center">
                    {moment(clientDesignSave.createdAt).format('ll')}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleOpen}
                    >
                        Info
                    </Button>
                </TableCell>
            </TableRow>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <ClientDesignSaveInfo
                            clientDesignSave={clientDesignSave}
                            handleClose={handleClose}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default ClientDesignSaveList
