import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Dialog,
    Container,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DELETE_CONFIRM, ITEM_HEIGHT } from '../../constant/default'

import EditWarranty from './EditWarranty'
import {
    deleteWarranty,
    getOneWarranty,
} from '../../store/actions/warrantyActions'

const useStyles = makeStyles((theme) => ({
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    paper: {
        padding: '20px',
        margin: '10px 0',
    },
}))

const WarrantyList = ({ warranty }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)

    const handleEditOpen = () => {
        dispatch(getOneWarranty(warranty.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Container style={{ marginBottom: '2rem' }}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography
                            variant="h6"
                            style={{ textAlign: 'center' }}
                        >
                            {warranty.step}. {warranty.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            aria-label="more"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem onClick={handleEditOpen}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    if (window.confirm(DELETE_CONFIRM)) {
                                        dispatch(deleteWarranty(warranty.id))
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteForeverIcon
                                        className={classes.deleteIcon}
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Delete"
                                    className={classes.deleteIcon}
                                />
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </div>
            <div>{warranty.detail}</div>

            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditWarranty handleDialogClose={handleEditClose} />
            </Dialog>
        </Container>
    )
}

export default WarrantyList
