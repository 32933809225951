import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import DeliveryDateForm from './DeliveryDateForm'

const EditDeliveryDate = ({ handleDialogClose }) => {
    const orderState = useSelector((state) => state.order)
    const { order } = orderState

    const values = {
        id: order.ordershipping.id,
        deliveryDate: order.ordershipping.deliveryDate,
    }

    return order ? (
        <>
            <DialogTitle id="form-dialog-title">Set Delivery Date</DialogTitle>
            <DeliveryDateForm
                handleDialogClose={handleDialogClose}
                initialValues={values}
                orderId={order.id}
            />
        </>
    ) : null
}

export default EditDeliveryDate
