import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DoneIcon from '@material-ui/icons/Done'
import {
    Box,
    Chip,
    Divider,
    Grid,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Dialog from '@material-ui/core/Dialog'
import AddStyle from '../categoryStyle/AddStyle'
import EditCategory from './EditCategory'
import { useDispatch } from 'react-redux'
import {
    deleteCategory,
    getOneCategory,
} from '../../store/actions/categoryActions'
import StyleList from '../categoryStyle/StyleList'
import {
    DELETE_CONFIRM,
    EMPTY_CHILDREN,
    shippingMethodList,
} from '../../constant/default'

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 600,
    },
    fab: {
        margin: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    editIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    deleteIcon: {
        color: theme.palette.secondary.main,
    },
    chip: {
        margin: '5px',
    },
    box: {
        margin: '10px 0',
    },
}))

const ITEM_HEIGHT = 48

export default function CategoryList({ category }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)

    const [styleOpen, setStyleOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const handleStyleOpen = () => {
        setStyleOpen(true)
    }

    const handleStyleClose = () => {
        setStyleOpen(false)
    }

    const handleEditOpen = () => {
        dispatch(getOneCategory(category.id))
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        {category.displayName.substr(0, 1).toUpperCase()}
                    </Avatar>
                }
                action={
                    <>
                        <IconButton
                            aria-label="more"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem onClick={handleEditOpen}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>
                            {category.styles &&
                                category.styles.length === EMPTY_CHILDREN && (
                                    <MenuItem
                                        onClick={() => {
                                            if (
                                                window.confirm(DELETE_CONFIRM)
                                            ) {
                                                dispatch(
                                                    deleteCategory(category.id)
                                                )
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <DeleteForeverIcon
                                                className={classes.deleteIcon}
                                                fontSize="small"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Delete"
                                            className={classes.deleteIcon}
                                        />
                                    </MenuItem>
                                )}
                        </Menu>
                    </>
                }
                titleTypographyProps={{ variant: 'h6' }}
                title={category.displayName}
                // subheader={moment(category.createdAt).format('ll')}
            />
            <CardMedia
                className={classes.media}
                image={category.smallPicLink}
                title="small pic"
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {category.sequence}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {category.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {category.slogan}
                </Typography>
            </CardContent>
            <CardContent>
                <Box component="span">
                    {category.navDisplay && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>N</Avatar>}
                            label="Navbar"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.homepageSmall && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>H</Avatar>}
                            label="Homepage Small Pic"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.productsPageBig && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>P</Avatar>}
                            label="Products Big Pic"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.productsPageSmall && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>S</Avatar>}
                            label="Products Small Pic"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.typeOption && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>T</Avatar>}
                            label="Type Option"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.functionOption && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>F</Avatar>}
                            label="Function Option"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.productCategory && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>C</Avatar>}
                            label="Products Category"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.picturePoint && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>P</Avatar>}
                            label="Picture Point"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                    {category.productOption && (
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar>O</Avatar>}
                            label="Product Options"
                            color="primary"
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                        />
                    )}
                </Box>
                {category.shippingMethod ? (
                    <>
                        <Divider className={classes.box} />
                        <Box className={classes.box}>
                            Shipping Method:{' '}
                            {
                                shippingMethodList.filter(
                                    (method) =>
                                        method.id === category.shippingMethod
                                )[0]?.name
                            }
                        </Box>
                    </>
                ) : (
                    ''
                )}
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <img src={category.bigPicLink} alt="big pic" width="100%" />

                    <Divider />
                    <Typography variant="h6" component="h6">
                        Size:
                    </Typography>
                    {category.sizes &&
                        category.sizes.map((size) => (
                            // <Typography paragraph key={size.id}>
                            //     {size.sizeValue}
                            // </Typography>
                            <Chip
                                key={size.id}
                                variant="outlined"
                                className={classes.chip}
                                label={size.sizeValue}
                                component="a"
                            />
                        ))}
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" component="h6">
                            Styles:
                        </Typography>
                        <Tooltip title="Add" aria-label="add">
                            <Fab
                                color="primary"
                                size="small"
                                className={classes.fab}
                                onClick={handleStyleOpen}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                    <div style={{ width: '100%' }}>
                        {category.styles &&
                            category.styles.map((style) => (
                                <StyleList key={style.id} style={style} />
                            ))}
                    </div>
                </CardContent>
            </Collapse>
            <Dialog
                open={styleOpen}
                // onClose={handleDialogClose}
                aria-labelledby="form-dialog-style"
            >
                <AddStyle
                    handleDialogClose={handleStyleClose}
                    categoryId={category.id}
                />
            </Dialog>
            <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-edit"
            >
                <EditCategory handleDialogClose={handleEditClose} />
            </Dialog>
        </Card>
    )
}
