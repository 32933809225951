import React, { useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Home from '@material-ui/icons/Home'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ShopTwoIcon from '@material-ui/icons/ShopTwo'
import PanoramaIcon from '@material-ui/icons/Panorama'
import CategoryIcon from '@material-ui/icons/Category'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import history from '../history'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/actions/userActions'
import { userRole } from '../constant/default'
import { Avatar } from '@material-ui/core'
import { deepOrange, green, pink } from '@material-ui/core/colors'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import FunctionsIcon from '@material-ui/icons/Functions'
import TocIcon from '@material-ui/icons/Toc'
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import PinDropIcon from '@material-ui/icons/PinDrop'
import BusinessIcon from '@material-ui/icons/Business'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import WebIcon from '@material-ui/icons/Web'
import PolicyIcon from '@material-ui/icons/Policy'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import KitchenIcon from '@material-ui/icons/Kitchen'
import RateReviewIcon from '@material-ui/icons/RateReview'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import EditIcon from '@material-ui/icons/Edit'
import MoneyIcon from '@material-ui/icons/Money'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },

    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    avatarColor: {
        color: '#000',
        backgroundColor: '#32E2A0',
    },
}))

export default function Layout({ children }) {
    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.user)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        }
    }, [userInfo])
    const classes = useStyles()
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return userInfo ? (
        <div className={classes.root}>
            <CssBaseline />

            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img
                        src="https://res.cloudinary.com/homespace/image/upload/v1622233552/logo/WHITE_YELLOW_LOGO_ohgati.png"
                        height="30px"
                        alt="logo"
                    />
                    <Typography variant="h6" noWrap style={{ flex: 1 }}>
                        {/* HS-Online.com Admin */}
                    </Typography>

                    <Avatar className={classes.avatarColor}>
                        {userInfo.firstName.substr(0, 1).toUpperCase()}
                        {userInfo.lastName.substr(0, 1).toUpperCase()}
                    </Avatar>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button onClick={() => history.push('/')}>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => history.push('/products')}>
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Product" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => history.push('/productselect')}
                    >
                        <ListItemIcon>
                            <TocIcon />
                        </ListItemIcon>
                        <ListItemText primary="Product Colors" />
                    </ListItem>
                    <ListItem button onClick={() => history.push('/orders')}>
                        <ListItemIcon>
                            <ShopTwoIcon />
                        </ListItemIcon>
                        <ListItemText primary="Order" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => history.push('/requestdesign')}
                    >
                        <ListItemIcon>
                            <RateReviewIcon />
                        </ListItemIcon>
                        <ListItemText primary="Request Designs" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => history.push('/clientdesignsave')}
                    >
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Client Designs" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => history.push('/pricematch')}
                    >
                        <ListItemIcon>
                            <MoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Price Match" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => history.push('/picturepoint')}
                    >
                        <ListItemIcon>
                            <PanoramaIcon />
                        </ListItemIcon>
                        <ListItemText primary="Picture Point" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => history.push('/profile')}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                    </ListItem>
                    {userInfo.role === userRole.USER_ADMIN && (
                        <>
                            <ListItem
                                button
                                onClick={() => history.push('/users')}
                            >
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/adduser')}
                            >
                                <ListItemIcon>
                                    <PersonAddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Add New User" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/categories')}
                            >
                                <ListItemIcon>
                                    <CategoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Category" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/sizes')}
                            >
                                <ListItemIcon>
                                    <AspectRatioIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sizes" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/producttype')}
                            >
                                <ListItemIcon>
                                    <MergeTypeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Types" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/productfunction')}
                            >
                                <ListItemIcon>
                                    <FunctionsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Functions" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/pictureposition')}
                            >
                                <ListItemIcon>
                                    <AddToHomeScreenIcon />
                                </ListItemIcon>
                                <ListItemText primary="Positions" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/selfassemble')}
                            >
                                <ListItemIcon>
                                    <VideoLibraryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Self Assemble" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/qnas')}
                            >
                                <ListItemIcon>
                                    <QuestionAnswerIcon />
                                </ListItemIcon>
                                <ListItemText primary="Q&A" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/warranty')}
                            >
                                <ListItemIcon>
                                    <VerifiedUserIcon />
                                </ListItemIcon>
                                <ListItemText primary="Warranty" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/policy')}
                            >
                                <ListItemIcon>
                                    <PolicyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Policy" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/pickuplocation')}
                            >
                                <ListItemIcon>
                                    <PinDropIcon />
                                </ListItemIcon>
                                <ListItemText primary="Location" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/headoffice')}
                            >
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>
                                <ListItemText primary="Head Office" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/teammember')}
                            >
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Team Member" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/homepage')}
                            >
                                <ListItemIcon>
                                    <WebIcon />
                                </ListItemIcon>
                                <ListItemText primary="Carousel" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/cabinetoption')}
                            >
                                <ListItemIcon>
                                    <KitchenIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cabinet Option" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/clients')}
                            >
                                <ListItemIcon>
                                    <PeopleOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Clients" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/designers')}
                            >
                                <ListItemIcon>
                                    <PeopleAltIcon />
                                </ListItemIcon>
                                <ListItemText primary="Designers" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => history.push('/promocodes')}
                            >
                                <ListItemIcon>
                                    <LocalOfferIcon />
                                </ListItemIcon>
                                <ListItemText primary="Promo Codes" />
                            </ListItem>
                        </>
                    )}
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => dispatch(logout())}>
                        <ListItemIcon>
                            <ExitToAppIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    ) : null
}
