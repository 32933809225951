import { POSITION_LEFT } from '../../constant/default'
import history from '../../history'

const PicturePointItemDisplay = ({ picturePointItem }) => {
    return (
        <div
            className="picture-point"
            style={{
                left: picturePointItem.xCoordinate + '%',
                top: picturePointItem.yCoordinate + '%',
            }}
            onClick={() => history.push(picturePointItem.style.url)}
        >
            <div
                className={`picture-point-detail ${
                    picturePointItem.itemPosition === POSITION_LEFT
                        ? 'picture-point-detail-left'
                        : 'picture-point-detail-right'
                }`}
            >
                <div>
                    <div>
                        <strong>{picturePointItem.pointName}</strong>
                    </div>
                    <div
                        style={{
                            fontSize: '10px',
                        }}
                    >
                        {picturePointItem.pointDes}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PicturePointItemDisplay
