import { DialogTitle } from '@material-ui/core'
import { FORM_TYPE_NEW } from '../../constant/default'
import PaymentForm from './PaymentForm'

const AddPayment = ({ handleDialogClose, orderId, invoiceId }) => (
    <>
        <DialogTitle id="form-dialog-title">Add New Payment</DialogTitle>
        <PaymentForm
            handleDialogClose={handleDialogClose}
            formType={FORM_TYPE_NEW}
            orderId={orderId}
            invoiceId={invoiceId}
        />
    </>
)

export default AddPayment
