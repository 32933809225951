import { useState, useEffect } from 'react'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { renderCheckbox, renderTextField } from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import {
    createPromoCode,
    updatePromoCode,
} from '../../store/actions/promoCodeActions'
import MomentUtils from '@date-io/moment' // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const radioButton = ({ input, ...rest }) => (
    <FormControl>
        <RadioGroup {...input} {...rest}>
            <FormControlLabel
                checked={parseInt(input.value) === 1}
                value={1}
                control={<Radio />}
                label="Public"
            />
            <FormControlLabel
                checked={parseInt(input.value) === 2}
                value={2}
                control={<Radio />}
                label="Private"
            />
        </RadioGroup>
    </FormControl>
)

const typeRadioButton = ({ input, ...rest }) => (
    <FormControl>
        <RadioGroup {...input} {...rest}>
            <FormControlLabel
                checked={parseInt(input.value) === 1}
                value="1"
                control={<Radio />}
                label="Amount"
            />
            <FormControlLabel
                checked={parseInt(input.value) === 2}
                value="2"
                control={<Radio />}
                label="Percentage"
            />
            <FormControlLabel
                checked={parseInt(input.value) === 3}
                value="3"
                control={<Radio />}
                label="Customize"
            />
        </RadioGroup>
    </FormControl>
)

const PromoCodeForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {
        handleSubmit,
        handleDialogClose,
        storeStartDate,
        storeEndDate,
        formType,
    } = props
    const [startDate, setStartDate] = useState(storeStartDate)
    const [endDate, setEndDate] = useState(storeEndDate)

    useEffect(() => {
        setStartDate(storeStartDate)
    }, [storeStartDate])

    useEffect(() => {
        setEndDate(storeEndDate)
    }, [storeEndDate])

    const submitForm = (formValues) => {
        formValues['startDate'] = startDate
        formValues['endDate'] = endDate
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updatePromoCode(formValues))
            : dispatch(createPromoCode(formValues))
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click{' '}
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}{' '}
                        button to finish.
                    </DialogContentText>
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="promoName"
                        label="Promo Code Name"
                        name="promoName"
                        inputProps={{ maxLength: 100 }}
                        component={renderTextField}
                    />
                    <Field
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="promoDescription"
                        label="Promo Description"
                        name="promoDescription"
                        multiline
                        rows={4}
                        component={renderTextField}
                    />
                    <Field
                        // variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="promoCode"
                        label="Promo Code"
                        name="promoCode"
                        inputProps={{ maxLength: 100 }}
                        component={renderTextField}
                    />
                    <div style={{ padding: '20px 0' }}>
                        <div>Promo Category *</div>
                        <Field
                            name="promoCategory"
                            style={{ display: 'flex', flexDirection: 'row' }}
                            component={radioButton}
                        >
                            <Radio value={1} label="Public" />
                            <Radio value={2} label="Private" />
                        </Field>
                    </div>
                    <div>
                        <div>Promo Type *</div>
                        <Field
                            name="promoType"
                            style={{ display: 'flex', flexDirection: 'row' }}
                            component={typeRadioButton}
                            defaultChecked={2}
                        >
                            <Radio value="1" label="Amount" />
                            <Radio value="2" label="Percentage" />
                            <Radio value="3" label="customize" />
                        </Field>
                    </div>
                    <Field
                        // variant="outlined"
                        margin="normal"
                        fullWidth
                        id="typeMount"
                        label="Amount"
                        name="typeMount"
                        type="number"
                        inputProps={{ maxLength: 10 }}
                        component={renderTextField}
                    />
                    <Field
                        name="used"
                        component={renderCheckbox}
                        label="Used"
                    />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div>
                            <DateTimePicker
                                label="Start Date"
                                margin="normal"
                                fullWidth
                                required
                                value={startDate}
                                disablePast={
                                    formType === FORM_TYPE_EDIT ? false : true
                                }
                                onChange={setStartDate}
                            />
                        </div>
                        <DateTimePicker
                            label="End Date"
                            margin="normal"
                            fullWidth
                            required
                            value={endDate}
                            minDate={startDate}
                            disablePast={
                                formType === FORM_TYPE_EDIT ? false : true
                            }
                            onChange={setEndDate}
                        />
                    </MuiPickersUtilsProvider>
                    <Field
                        name="active"
                        component={renderCheckbox}
                        label="Active"
                    />
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'promoName',
        'promoCategory',
        'promoType',
        'promoCode',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'promoCodeForm',
    validate,
    enableReinitialize: true,
})(PromoCodeForm)
