import { DialogTitle } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_TYPE_EDIT } from '../../constant/default'
import PicturePositionForm from './PicturePositionForm'

const EditPicturePosition = ({ handleDialogClose }) => {
    const picturePositionState = useSelector((state) => state.picturePosition)
    const { picturePosition } = picturePositionState

    return picturePosition ? (
        <>
            <DialogTitle id="form-dialog-title">
                Edit Picture Position
            </DialogTitle>
            <PicturePositionForm
                handleDialogClose={handleDialogClose}
                initialValues={picturePosition}
                formType={FORM_TYPE_EDIT}
            />
        </>
    ) : null
}

export default EditPicturePosition
