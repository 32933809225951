import { GET_ALL_CABINETDEPTHLIST, GET_ONE_CABINETDEPTH } from '../types'

const initialState = {
    cabinetDepth: {},
    cabinetDepthList: [],
}

const cabinetDepthReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ONE_CABINETDEPTH:
            return {
                ...state,
                cabinetDepth: payload,
            }
        case GET_ALL_CABINETDEPTHLIST:
            return {
                ...state,
                cabinetDepthList: payload,
            }
        default:
            return state
    }
}

export default cabinetDepthReducer
