import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../ReduxFormInputs'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { createPayment, updatePayment } from '../../store/actions/orderActions'
import { FORM_TYPE_EDIT } from '../../constant/default'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}))

const PaymentForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { handleSubmit, handleDialogClose, formType, orderId, invoiceId } =
        props
    const submitForm = (formValues) => {
        formValues['orderId'] = orderId
        formValues['invoiceId'] = invoiceId
        handleDialogClose(false)
        formType === FORM_TYPE_EDIT
            ? dispatch(updatePayment(formValues))
            : dispatch(createPayment(formValues))
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <DialogContent>
                    <DialogContentText>
                        Please enter all informations below, click Set button to
                        finish.
                    </DialogContentText>
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="paymentType"
                        label="Payment Type"
                        name="paymentType"
                        type="text"
                        inputProps={{ maxLength: 50 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="amount"
                        label="Amount"
                        name="amount"
                        type="number"
                        inputProps={{ maxLength: 10 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="paymentDate"
                        label="Payment Date"
                        name="paymentDate"
                        type="date"
                        inputProps={{ maxLength: 20 }}
                        InputLabelProps={{ shrink: true }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="refNo"
                        label="Ref No"
                        name="refNo"
                        type="text"
                        inputProps={{ maxLength: 50 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        fullWidth
                        id="cardType"
                        label="Card Type"
                        name="cardType"
                        type="text"
                        inputProps={{ maxLength: 20 }}
                        component={renderTextField}
                    />
                    <Field
                        margin="normal"
                        fullWidth
                        id="cardNo"
                        label="Card Last 4 No"
                        name="cardNo"
                        type="text"
                        inputProps={{ maxLength: 20 }}
                        component={renderTextField}
                    />
                </DialogContent>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="outlined" color="primary">
                        {formType === FORM_TYPE_EDIT ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['paymentType', 'amount', 'paymentDate', 'refNo']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'orderPaymentForm',
    validate,
    enableReinitialize: true,
})(PaymentForm)
